import { Card1GesagtD } from "./Card1GesagtD";
import { Card1GetanD } from "./Card1GetanD";
import { Card1GesagtM } from "./Card1GesagtM";
import { Card1GetanM } from "./Card1GetanM";

export const cardListDe = [
  [
    {
      thumb: { src: "/data/gallery-1-gesagt-thumb.png" },
      images: [Card1GesagtM, Card1GesagtD],
    },
    {
      thumb: { src: "/data/gallery-1-getan-thumb.png" },
      images: [Card1GetanM, Card1GetanD],
    },
  ],
  [
    {
      thumb: { src: "/data/gallery-1-gesagt-thumb.png" },
      images: [Card1GesagtM, Card1GesagtD],
    },
    {
      thumb: { src: "/data/gallery-1-getan-thumb.png" },
      images: [Card1GetanM, Card1GetanD],
    },
  ],
  [
    {
      thumb: { src: "/data/gallery-1-gesagt-thumb.png" },
      images: [Card1GesagtM, Card1GesagtD],
    },
    {
      thumb: { src: "/data/gallery-1-getan-thumb.png" },
      images: [Card1GetanM, Card1GetanD],
    },
  ],
];
