import { bp } from "#shared/theme";
import styled, { css } from "styled-components/macro";

export const Spacer = styled.div<{ size?: number }>`
  ${({ size = 100 }) => css`
    height: ${size}px;
    @media ${bp.m} {
      height: ${size * 2.5}px;
    }
    @media ${bp.t} {
      height: ${size * 3.5}px;
    }
    @media ${bp.d} {
      height: ${size * 4}px;
    }
  `}
`;
