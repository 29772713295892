import {
  ContractsChart,
  DeliveriesChart,
  EBITMarginChart,
  ElectricChart,
  EmissionsChart,
  GlobalMarketsChart,
  KeyFiguresChart,
  MotoChart,
  ResearchChart,
  ShareEarningsChart,
  WomenChart,
} from "#components";
import { usePopover, useTranslation } from "#hooks";
import { SlideshowItemProps, TourComponents } from "#interfaces";
import { TourSlideContext, useTour } from "#providers";
import { clearBtn, Color, expander, fadeIn, fadeOut, IconKeys } from "#shared/theme";
import React, { TransitionEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { ButtonCircle, Icon, IconSize } from "#ui";
import { useLocation } from "react-router-dom";

export type TourSlidesType = {
  [k: string]: React.ReactChild;
};

export const tourSlides: TourSlidesType = {
  [TourComponents.EbitMargin]: <EBITMarginChart />,
  [TourComponents.ElectricVehicles]: <ElectricChart />,
  [TourComponents.Markets]: <GlobalMarketsChart />,
  [TourComponents.Keyfigures]: <KeyFiguresChart />,
  [TourComponents.Contracts]: <ContractsChart />,
  [TourComponents.Deliveries]: <DeliveriesChart />,
  [TourComponents.Emissions]: <EmissionsChart />,
  [TourComponents.Research]: <ResearchChart />,
  [TourComponents.Shares]: <ShareEarningsChart />,
  [TourComponents.Moto]: <MotoChart />,
  [TourComponents.Women]: <WomenChart />,
};

interface ItemProps {
  isActive: boolean;
  isOpen: boolean;
}

const Item = styled.div<ItemProps>`
  ${expander}
  ${({ isActive }) => (isActive ? fadeIn : fadeOut)}
  z-index: ${({ isOpen }) => (isOpen ? 1 : 0)};
`;
const ItemContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TourShowItem: React.FC<Pick<ItemProps, "isActive">> = ({ isActive, children }) => {
  const itemRef = useRef(null);
  const { isOpen, open, close, reset } = usePopover();
  const handleTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
    if (e.target !== itemRef.current) return;
    reset();
  };
  useEffect(() => {
    if (isOpen) {
      if (!isActive) {
        close();
      }
    } else {
      if (isActive) {
        open();
      }
    }
  }, [isOpen, isActive, open, close]);
  return (
    <Item onTransitionEnd={handleTransitionEnd} isActive={isActive} isOpen={isOpen}>
      <ItemContainer>{children}</ItemContainer>
    </Item>
  );
};

const Container = styled.div`
  ${expander}
  z-index: 1;
  top: 0;
  background-color: ${Color.BlueSaturated};
`;

const ControlsButtonContainer = styled.div`
  position: absolute;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

const Button = styled.button`
  ${clearBtn}
  flex: 0 0 34px;
  height: 34px;
  margin-left: 32px;
  fill: ${Color.Green};
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
`;

export const Tour: React.FC = () => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [muted, setMuted] = useState<boolean>(false);
  const { set, index, isPlaying, play, pause, progress } = useTour();
  const { pathname, hash } = useLocation();
  const { t } = useTranslation("menu");
  const { list: tourMenuList, sound } = t("tour");
  const tourList = tourMenuList[0].list;
  const isAutoplay = isPlaying || progress !== 0;
  const handleClickMute = () => {
    setMuted(!muted);
  };
  const handleAutoplay = () => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  };
  const playMusic = () => {
    audioRef.current?.play();
  };
  const stopMusic = () => {
    audioRef.current?.pause();
  };
  const renderSlides = (item: SlideshowItemProps, key: number) => {
    return (
      <TourShowItem key={key} isActive={key === index} {...item}>
        <TourSlideContext.Provider value={{ key }}>{tourSlides[item.slide]}</TourSlideContext.Provider>
      </TourShowItem>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Navigate to slide on browser history change
  useEffect(() => {
    const foundIndex = tourList.findIndex((item: SlideshowItemProps) => item.to === `${pathname}${hash}`);
    console.log(foundIndex);
    if (foundIndex === index) return;
    set(foundIndex);
  }, [index, pathname, hash, tourList, set]);

  useEffect(() => {
    if (!audioRef.current) return;
    audioRef.current.muted = muted;
  }, [muted]);

  useEffect(() => {
    if (isAutoplay) {
      playMusic();
    } else {
      stopMusic();
    }
  }, [isAutoplay]);
  return (
    <Container>
      {tourList.map(renderSlides)}
      {isAutoplay && (
        <ControlsButtonContainer>
          <ButtonCircle onClick={handleAutoplay} progress={progress}>
            <Icon iconKey={isPlaying ? IconKeys.SvgPause : IconKeys.SvgPlay} />
          </ButtonCircle>
          <Button onClick={handleClickMute}>
            <Icon size={IconSize.Middle} iconKey={muted ? IconKeys.SvgSoundOffCircle : IconKeys.SvgSoundOnCircle} />
          </Button>
          <audio ref={audioRef} src={sound} preload="auto" />
        </ControlsButtonContainer>
      )}
    </Container>
  );
};
