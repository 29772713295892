import { useResize } from "#hooks";
import { ControlledLineIconTypes, ControlledLineTargetRef } from "#interfaces";
import { bp, Color, IconKeys } from "#shared/theme";
import htmr from "htmr";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "#ui";
import styled, { css } from "styled-components/macro";
import { ButtonModal } from "../ButtonModal/ButtonModal";
import { InfoItemProps } from "../Info/Info";

const Container = styled.div`
  position: relative;
`;
const Hook = styled.span`
  display: inline-block;
  position: relative;
  vertical-align: text-bottom;
  width: 0;
  height: 0;
`;

const SvgLine = styled.svg`
  display: none;
  position: absolute;
  stroke: ${Color.Green};
  stroke-width: 1px;
  pointer-events: none;
  @media ${bp.m} {
    display: block;
  }
`;
const StyledALink = styled.span`
  cursor: pointer;
`;
const StyledLinkText = styled.strong`
  padding-right: 1em;
  margin-right: -0.5em;
  @media ${bp.m} {
    padding-right: 0;
    margin-right: 0;
  }
`;
const iconStyles = css`
  position: relative;
  bottom: -5px;
  fill: ${Color.Green};
  @media ${bp.m} {
    display: none;
  }
`;
const IconLinkExternal = styled(Icon)`
  ${iconStyles}
  span {
    width: 27px;
    height: 24px;
  }
`;
const IconPdf = styled(Icon)`
  ${iconStyles}
  span {
    width: 66px;
    height: 24px;
  }
`;

const spanLineIcons: { [k: string]: JSX.Element } = {
  [ControlledLineIconTypes.external]: <IconLinkExternal iconKey={IconKeys.SvgExternalLinkMobile} />,
  [ControlledLineIconTypes.pdf]: <IconPdf iconKey={IconKeys.SvgPdfText} />,
};
interface ControlledLineReturnedProps {
  targetRef: ControlledLineTargetRef;
  render: React.ReactNode;
  targetType?: ControlledLineIconTypes;
}
interface ControlledLineProps {
  source: string;
  children: (args: ControlledLineReturnedProps) => React.ReactElement;
  modal?: InfoItemProps[];
}
interface LineSizeProps {
  left: number;
  top: number;
  width: number;
  height: number;
  y1: number;
  y2: number;
}
interface ALinkProps {
  iconType?: string;
  hookRef: RefObject<HTMLDivElement>;
  onClick?: () => void;
}

const ALink: React.FC<ALinkProps> = ({ children, iconType, hookRef, onClick }) => (
  <StyledALink onClick={onClick} data-line={iconType}>
    <StyledLinkText>{children}</StyledLinkText>
    {iconType && spanLineIcons[iconType]}
    <Hook ref={hookRef} />
  </StyledALink>
);

const getA = (hookRef: RefObject<HTMLDivElement>, modal?: InfoItemProps[]) => ({
  children: _children,
  ...args
}: {
  children?: React.ReactNode;
  ["data-line"]?: string;
  href?: string;
}) => {
  return !modal ? (
    <Link to={args.href || ""}>
      <ALink hookRef={hookRef} iconType={args["data-line"]}>
        {_children}
      </ALink>
    </Link>
  ) : (
    <ButtonModal list={modal}>
      {({ onClick }) => (
        <ALink hookRef={hookRef} iconType={args["data-line"]} onClick={onClick}>
          {_children}
        </ALink>
      )}
    </ButtonModal>
  );
};

export const ControlledLine: React.FC<ControlledLineProps> = ({ source, children, modal }) => {
  const [lineCoords, setLineCoords] = useState<LineSizeProps>({ left: 0, top: 0, width: 0, height: 0, y1: 0, y2: 0 });
  const parentRef = useRef<HTMLDivElement>(null);
  const hookRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const transform = {
    a: getA(hookRef, modal),
  };
  const render = htmr(source, { transform });
  const { y1, y2, ...lineSize } = lineCoords;
  const size = useResize();

  useEffect(() => {
    if (!hookRef?.current || !parentRef?.current || !targetRef?.current) return;
    const hookSize = hookRef.current.getBoundingClientRect();
    const parentSize = parentRef.current.getBoundingClientRect();
    const targetSize = targetRef.current.getBoundingClientRect();

    const hookLeft = hookSize.left - parentSize.left;
    const hookTop = hookSize.top - parentSize.top;
    const targetLeft = targetSize.left - parentSize.left;
    const targetTop = targetSize.top + targetSize.height / 2 - parentSize.top - 1;
    const width = Math.abs(hookLeft - targetLeft) + 2;
    const height = Math.abs(hookTop - targetTop) + 2;
    const y1 = targetSize.top < hookSize.top ? height - 1.5 : 0.5;
    const y2 = targetSize.top < hookSize.top ? 0.5 : height - 1.5;
    setLineCoords({
      left: hookLeft - 1,
      top: Math.min(hookTop, targetTop),
      width,
      height,
      y1,
      y2,
    });
  }, [parentRef, hookRef, targetRef, size]);

  return (
    <Container ref={parentRef}>
      {children({ targetRef: targetRef as any, render })}
      <SvgLine style={{ ...lineSize }} viewBox={`0 0 ${lineCoords.width} ${lineCoords.height}`}>
        <line x1={0.5} y1={lineCoords.y1} x2={lineCoords.width - 0.5} y2={lineCoords.y2} />
      </SvgLine>
    </Container>
  );
};
