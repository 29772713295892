import React from "react";
import styled from "styled-components/macro";

const Path = styled.path`
  stroke-width: 1;
`;

interface BarsProps {
  maskId: string;
  color: string;
}
export const Bars: React.FC<BarsProps> = ({ maskId, color }) => {
  const mask = `url(#${maskId})`;
  return (
    <g mask={mask} stroke={color} fill="none">
      <Path d="M2.85231505 11.35231505v79" />
      <Path d="M10.85231505 2.35231505v88" />
      <Path d="M18.85231505 10.35231505v80" />
      <Path d="M26.85231505 21.35231505v69" />
      <Path d="M34.85231505 23.35231505v67" />
      <Path d="M42.85231505 65.35231505v25" />
      <Path d="M50.85231505 31.35231505v59" />
      <Path d="M58.85231505 17.35231505v73" />
      <Path d="M66.85231505 81.35231505v9" />
      <Path d="M74.85231505 21.35231505v69" />
      <Path d="M82.85231505 81.35231505v9" />
      <Path d="M90.85231505 23.35231505v67" />
      <Path d="M98.85231505 32.35231505v58" />
      <Path d="M106.85231505 13.35231505v77" />
      <Path d="M114.85231505 81.35231505v9" />
      <Path d="M122.85231505 42.35231505v48" />
      <Path d="M130.85231505 25.35231505v65" />
      <Path d="M138.85231505 13.35231505v77" />
      <Path d="M146.85231505 19.35231505v71" />
      <Path d="M154.85231505 50.35231505v40" />
      <Path d="M162.85231505 13.35231505v77" />
      <Path d="M170.85231505 43.35231505v47" />
      <Path d="M178.85231505 25.35231505v65" />
      <Path d="M186.85231505 64.35231505v26" />
      <Path d="M194.85231505 29.35231505v61" />
      <Path d="M202.85231505 40.35231505v50" />
      <Path d="M210.85231505 13.35231505v77" />
      <Path d="M218.85231505 13.35231505v77" />
      <Path d="M226.85231505 43.35231505v47" />
      <Path d="M234.85231505 29.35231505v61" />
      <Path d="M242.85231505 16.35231505v74" />
      <Path d="M250.85231505 23.35231505v67" />
      <Path d="M258.85231505 13.35231505v77" />
      <Path d="M266.85231505 13.35231505v77" />
      <Path d="M274.85231505 81.35231505v9" />
      <Path d="M282.85231505 13.35231505v77" />
      <Path d="M290.85231505 13.35231505v77" />
      <Path d="M298.85231505 25.35231505v65" />
      <Path d="M306.85231505 32.35231505v58" />
      <Path d="M314.85231505 73.35231505v17" />
      <Path d="M322.85231505 31.35231505v59" />
      <Path d="M330.85231505 25.35231505v65" />
      <Path d="M338.85231505 81.35231505v9" />
      <Path d="M346.85231505 53.35231505v37" />
      <Path d="M354.85231505 29.35231505v61" />
      <Path d="M362.85231505 13.35231505v77" />
      <Path d="M370.85231505 23.35231505v67" />
      <Path d="M378.85231505 33.35231505v57" />
      <Path d="M386.85231505 13.35231505v77" />
      <Path d="M394.85231505 81.35231505v9" />
      <Path d="M402.85231505 26.35231505v64" />
      <Path d="M410.85231505 47.35231505v43" />
      <Path d="M418.85231505 65.35231505v25" />
      <Path d="M426.85231505 20.35231505v70" />
      <Path d="M434.85231505 81.35231505v9" />
      <Path d="M442.85231505 13.35231505v77" />
      <Path d="M450.85231505 36.35231505v54" />
      <Path d="M458.85231505 23.35231505v67" />
      <Path d="M466.85231505 20.35231505v70" />
      <Path d="M474.85231505 75.35231505v15" />
      <Path d="M482.85231505 13.35231505v77" />
      <Path d="M490.85231505 31.35231505v59" />
      <Path d="M498.85231505 13.35231505v77" />
      <Path d="M506.85231505 22.35231505v68" />
      <Path d="M514.85231505 13.35231505v77" />
      <Path d="M522.85231505 75.35231505v15" />
      <Path d="M530.85231505 13.35231505v77" />
      <Path d="M538.85231505 73.35231505v17" />
      <Path d="M546.85231505 13.35231505v77" />
      <Path d="M554.85231505 28.35231505v62" />
      <Path d="M562.85231505 43.35231505v47" />
      <Path d="M570.85231505 32.35231505v58" />
      <Path d="M578.85231505 71.35231505v19" />
    </g>
  );
};
