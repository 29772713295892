import * as THREE from "three";
import gsap from "gsap";

// import { MaterialEditor } from "@three-material-editor/vanilla";

import "./index.css";

import Core from "./Core";
import Mountains from "./Mountains";
import Road from "./Road";
import Lights from "./Lights";
import { CameraDolly } from "./CameraDolly";

import config from "./config.js";
// import GUI from "./gui";
// let config = {
// 	mountWidth: 20,
// 	mountLength: 100,
// 	roadWidth: 5,
// 	travelLength: 100,
// };
class App {
  constructor(container, canvas) {
    this.onConfigChange = this.onConfigChange.bind(this);
    // test

    this.container = container;
    this.canvas = canvas;
    // document.body.style.height = "900vh";
    this.core = new Core();
    this.progress = 0;
    this.init = this.init.bind(this);
    this.isInitiated = false;
    this.shouldPlayIntro = false;
    this.pathname = null;

    this.didFadeIn = false;
  }
  //   setupGuiConfig() {
  //     let folder = GUI.addFolder("config");
  //     folder.add(config, "mountWidth").onFinishChange(this.onConfigChange);
  //     folder.add(config, "mountLength").onFinishChange(this.onConfigChange);
  //     folder.add(config, "roadWidth").onFinishChange(this.onConfigChange);
  //   }
  onConfigChange() {
    this.mountainLeft.scale.set(config.mountWidth, config.mountLength, 1);
    this.mountainLeft.position.x = config.mountWidth / 2 + config.roadWidth / 2;
    // this.mountainLeft.position.z = -config.mountLength * 0.3;

    this.mountainRight.scale.set(config.mountWidth, config.mountLength, 1);
    this.mountainRight.position.x = -config.mountWidth / 2 - config.roadWidth / 2;
    // this.mountainRight.position.z = -config.mountLength * 0.3;

    this.road.scale.set(config.roadWidth + 0.01, config.mountLength, 1);
    // this.road.position.z = -config.mountLength * 0.3;

    this.lights.position.z += config.mountLength / 2;

    this.world.position.z += -config.mountLength * 0.5;

    this.cameraDolly.updateOptions({
      length: config.travelLength,
      totalPoints: 50,
    });
  }
  loadAssets() {
    return this.core.loadAssets();
  }
  init(cb) {
    let Core = this.core;
    let canvas = this.canvas;
    let wrapper = this.container;
    // this.setupGuiConfig();

    // let gui = new GUI();

    Core.mount(wrapper, canvas);
    Core.init();

    let dolly = new CameraDolly(Core.regularCamera, {
      length: config.travelLength,
      totalPoints: 10,
    });
    this.cameraDolly = dolly;
    // Core.scene.add(dolly.createHelper());

    let mountainLeft = new Mountains(Core);
    mountainLeft.rotation.x = -Math.PI / 2;

    this.mountainLeft = mountainLeft;

    // gui.add(mountainLeft.material.uniforms.uOffset0, "value", -0.5, 0.5, 0.0001).name("z offset");
    // gui.add(mountainLeft.material.uniforms.uOffset1, "value", -0.8, 0.8, 0.001).name("height variation");
    // gui.add(mountainLeft.material.uniforms.uOffset2, "value", -3, 3, 0.01).name("x offset");
    let mountainRight = new Mountains(Core);
    mountainRight.rotation.x = -Math.PI / 2;
    this.mountainRight = mountainRight;

    let road = new Road(Core);

    road.rotation.x = -Math.PI / 2;
    this.road = road;

    let lights = new Lights(10, Core);
    this.lights = lights;

    let world = new THREE.Object3D();
    world.add(mountainLeft);
    world.add(mountainRight);
    world.add(road);
    world.add(lights);
    world.name = "world";

    this.world = world;
    Core.scene.add(world);

    Core.updates.push(this.update.bind(this));
    this.onConfigChange();
    // Core.scene = new THREE.Scene();
    // Core.scene.add(cubeMesh);

    setTimeout(() => {
      if (this.isDisposed) return;
      this.fadeIn();
      cb();
    }, 200);

    // this.createScrollTrigger();

    Core.tick();
  }
  update() {
    // Core.time = -this.world.position.z / config.mountLength;
  }
  startAnimation() {
    if (this.isDisposed) return;
    let t1 = gsap.timeline({});
    this.introAnimation = t1;
    console.log("start", this.core.camera.position);

    t1.to(
      this.cameraDolly.position,
      {
        y: 5,
        duration: 0.5,
        ease: "power2.out",
        // onUpdate: () => {
        // 	this.onProgressUpdate();
        // },
      },
      0
    );
    t1.to(
      [this.mountainLeft.material.uniforms.uOpacity, this.mountainRight.material.uniforms.uOpacity],
      { value: 1, duration: 0.2, ease: "power2.inOut" },
      0
    );
    t1.fromTo(
      this,
      { progress: 0.075 },
      {
        progress: 0.15,
        duration: 1,
        ease: "power1.inOut",
        onUpdate: () => {
          this.onProgressUpdate();
        },
        onComplete: () => {
          this.createScrollTrigger();
        },
      },
      0
    );
  }
  onProgressUpdate() {
    if (this.isDisposed) return;
    // if (gui.isInitiated) gui.progressGUI.updateDisplay();
    // console.log("setting", this.progress);
    this.cameraDolly.setProgress(this.progress);
    this.world.position.z = -config.mountLength * 0.5 - config.travelLength * this.progress;
    this.lights.material.uniforms.uPosZ.value = this.world.position.z;
    // this.mountainLeft.material.uniforms
    // Core.time = -this.world.position.z / config.mountLength;
    let worldZInUV = -this.world.position.z / config.mountLength;
    this.mountainLeft.material.uniforms.uWorldZInUV.value = worldZInUV;
    this.mountainRight.material.uniforms.uWorldZInUV.value = worldZInUV;
  }
  fadeIn(onlyIfAlreadyFadedIn) {
    if (onlyIfAlreadyFadedIn && !this.didFadeIn) return;
    if (this.isDisposed) return;

    let t1 = gsap.timeline({
      onUpdate: () => {
        this.onProgressUpdate();
      },
      onComplete: () => {
        this.didFadeIn = true;
        console.log("finish", this.core.camera.position);
      },
    });
    this.fadeInAnimation = t1;

    this.core.camera.position.set(0, 7, 0);
    this.progress = 0;
    this.onProgressUpdate();
    console.log(this.core.camera.position);
    console.log(this.cameraDolly.position);
    console.log(this.cameraDolly);

    t1.fromTo(
      this.cameraDolly.position,
      { y: 200.0 },
      {
        y: 5,
        duration: 2,
        ease: "power2.out",
        onUpdate: () => {
          //   this.onProgressUpdate();
        },
      },
      0
    );
    t1.fromTo(
      [this.mountainLeft.material.uniforms.uOpacity, this.mountainRight.material.uniforms.uOpacity],
      { value: 0 },
      { value: 1, duration: 0.5, ease: "power2.inOut" },
      0
    );
    t1.play();
    t1.fromTo(
      this,
      { progress: 0.0 },
      {
        progress: 0.075,
        duration: 1,
        ease: "power1.out",
        onUpdate: () => {
          //   this.onProgressUpdate();
        },
      },
      0
    );
  }
  disposeScrollTriggerAnims() {
    console.log(this.scrollAnimations);
    if (this.scrollAnimations) {
      this.scrollAnimations.forEach((anim) => {
        console.log(anim);
        anim.scrollTrigger.kill();
        anim.kill();
      });
      this.scrollAnimations = null;
    }
  }
  createScrollTrigger() {
    // let svg = document.getElementById("circle");
    let animations = [];
    // let progress = {
    // 	value: 0,
    // };
    // let guiItem = GUI.add(this, "progress", 0, 1, 0.01).onChange(() => {});
    let progressAnim = gsap.fromTo(
      this,
      { progress: 0.15 },
      {
        scrollTrigger: {
          trigger: "#scroll",
          //   pin: true,
          start: "top top",
          end: "bottom bottom",
          scrub: 1,
        },
        progress: 1,
        onUpdate: () => {
          console.log("scroll triggering");
          this.onProgressUpdate();
        },
        ease: "power1.inOut",
      }
    );
    animations.push(progressAnim);

    let colorSwapAnim = gsap.fromTo(
      [
        this.mountainLeft.material.uniforms.uHeightAct,
        this.mountainRight.material.uniforms.uHeightAct,
        this.mountainLeft.material.uniforms.uColorSwap,
        this.mountainRight.material.uniforms.uColorSwap,
        this.road.material.uniforms.uColorSwap,
      ],
      { value: 1 },
      {
        value: 0,
        scrollTrigger: {
          trigger: "#scroll",
          //   pin: true,
          start: "70% bottom",
          end: "90% bottom",
          scrub: 1,
        },
      }
    );
    animations.push(colorSwapAnim);

    let mountainOpacityAnim = gsap.fromTo(
      [this.mountainLeft.material.uniforms.uOpacity, this.mountainRight.material.uniforms.uOpacity],
      { value: 1 },
      {
        value: 0,
        scrollTrigger: {
          trigger: "#scroll",
          //   pin: true,
          start: "85% bottom",
          end: "90% bottom",
          scrub: 1,
        },
      }
    );
    animations.push(mountainOpacityAnim);

    let roadOpacityAnim = gsap.fromTo(
      [this.road.material.uniforms.uOpacity],
      { value: 1 },
      {
        value: 0,
        scrollTrigger: {
          trigger: "#scroll",
          //   pin: true,
          start: "92% bottom",
          end: "96% bottom",
          scrub: 1,
        },
      }
    );
    animations.push(roadOpacityAnim);

    let dissipateAnim = gsap.fromTo(
      [this.lights.material.uniforms.uDissipateProgress],
      { value: 0 },
      {
        value: 1,
        scrollTrigger: {
          trigger: "#scroll",
          //   pin: true,
          start: "80% bottom",
          end: "90% bottom",
          scrub: 1,
        },
      }
    );
    animations.push(dissipateAnim);

    let lightsOpacityAnim = gsap.fromTo(
      [this.lights.material.uniforms.uOpacity],
      { value: 1 },
      {
        value: 0,
        scrollTrigger: {
          trigger: "#scroll",
          //   pin: true,
          start: "93% bottom",
          end: "bottom bottom",
          scrub: 1,
        },
      }
    );
    animations.push(lightsOpacityAnim);

    this.scrollAnimations = animations;
  }
  dispose() {
    if (this.isDisposed) return;

    if (this.introAnimation) {
      this.introAnimation.kill();
      this.introAnimation = null;
    }
    if (this.fadeInAnimation) {
      this.fadeInAnimation.kill();
      this.fadeInAnimation = null;
    }
    this.disposeScrollTriggerAnims();

    /**
     *
     * -[] Kill gsaps
     */
    this.core.dispose();

    this.canvas = null;
    this.container = null;
    this.core = null;

    this.cameraDolly.dispose();
    this.cameraDolly = null;

    this.mountainLeft.dispose();
    this.mountainRight.dispose();
    this.mountainLeft = null;
    this.mountainRight = null;

    this.road.dispose();
    this.road = null;

    this.lights.dispose();
    this.lights = null;

    this.world = null;

    this.isDisposed = true;
  }
}

export default App;
// export { App2 };
// app.init();
