// import React from "react";
import { PageName, RouteProps } from "src/interfaces";
import { Cookies } from "./Cookies/Cookies";
import { Home } from "./Home/Home";
import { Intro } from "./Home/Intro";
import { Start } from "./Home/Start";
import { Tour } from "./Home/Tour";
import { VCPPage } from "./VCPPage/VCPPage";
import { Diversity } from "./Diversity/Diversity";

export const routes: RouteProps[] = [
  {
    pageName: PageName.index,
    redirect: PageName.home,
  },
  {
    pageName: PageName.home,
    redirect: PageName.start,
  },
  {
    pageName: PageName.home,
    exact: false,
    component: Home,
    routes: [
      {
        pageName: PageName.start,
        component: Start,
      },
      {
        pageName: PageName.intro,
        component: Intro,
      },
      {
        pageName: PageName.guidedTour2020,
        component: Tour,
      },
    ],
  },
  {
    pageName: PageName.interactiveValueCreationProcess,
    component: VCPPage,
  },
  {
    pageName: PageName.cookies,
    component: Cookies,
  },
  {
    pageName: PageName.interviewDiversity,
    component: Diversity,
  },
];
