import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { usePrevious, useTranslation } from "src/hooks";
import { useHistory } from "react-router-dom";

const AUTOPLAY_SLIDE_DURATION = 10000;

interface TourContextProps {
  progress: number;
  index: number;
  indexReady: number;
  isPlaying: boolean;
  play: () => void;
  pause: () => void;
  stop: () => void;
  next: () => void;
  prev: () => void;
  set: (n: number) => void;
  setReady: (n: number) => void;
  prevReady: () => void;
  nextReady: () => void;
}
interface TourState {
  index: number;
  indexReady: number;
}
type TourAction = { payload: { index: number; indexReady: number } };

type TourReducerType = (state: TourState, action: TourAction) => TourState;

const TourReducer: TourReducerType = (state, { payload }) => {
  return { ...state, ...payload };
};

const DEFAULT_VALUE = {
  progress: 0,
  index: 0,
  indexReady: 0,
  isPlaying: false,
  play: (): void => undefined,
  pause: (): void => undefined,
  stop: (): void => undefined,
  next: (): void => undefined,
  prev: (): void => undefined,
  set: (): void => undefined,
  nextReady: (): void => undefined,
  prevReady: (): void => undefined,
  setReady: (): void => undefined,
};

const TourContext = createContext<TourContextProps>(DEFAULT_VALUE);
export const useTour = (): TourContextProps => useContext<TourContextProps>(TourContext);

interface TourProviderProps {
  defaultIndex?: number;
  length: number;
}

export const TourProvider: React.FC<TourProviderProps> = ({ children, defaultIndex = 0, length }) => {
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const [isPlaying, setPlaying] = useState(false);
  const prevDefaultIndex = usePrevious(defaultIndex);
  const [{ index, indexReady }, dispatch] = useReducer(TourReducer, {
    index: defaultIndex,
    indexReady: defaultIndex,
  });
  const { t } = useTranslation("menu");
  const { list: tourMenuList } = t("tour");
  const tourList = tourMenuList[0].list;
  const lastIndex = length - 1;
  const prevIndex = index < 1 ? lastIndex : index - 1;
  const nextIndex = index >= lastIndex ? 0 : index + 1;
  const value = {
    progress,
    isPlaying,
    index,
    indexReady,
    play: () => {
      setPlaying(true);
    },
    pause: (): void => {
      setPlaying(false);
    },
    stop() {
      setProgress(0);
      setPlaying(false);
    },
    prevReady: (): void => {
      dispatch({ payload: { index, indexReady: prevIndex } });
    },
    prev: (): void => {
      dispatch({ payload: { index: prevIndex, indexReady: prevIndex } });
    },
    nextReady: (): void => {
      dispatch({ payload: { index, indexReady: nextIndex } });
    },
    next: (): void => {
      dispatch({ payload: { index: nextIndex, indexReady: nextIndex } });
    },
    setReady: (n: number): void => {
      dispatch({ payload: { index, indexReady: n } });
    },
    set: (n: number): void => {
      dispatch({ payload: { index: n, indexReady: n } });
    },
  };
  useEffect(() => {
    if (prevDefaultIndex === defaultIndex || defaultIndex === -1) return;
    setProgress(0);
    setPlaying(false);
  }, [defaultIndex, prevDefaultIndex, tourList]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    let myReq: number;
    if (isPlaying) {
      if (index === lastIndex || index === -1) {
        setPlaying(false);
        setProgress(0);
      }
      let start = 0;
      const step = (timestamp: number) => {
        if (!start) start = timestamp;
        const progressTime = timestamp - start;
        if (progressTime < AUTOPLAY_SLIDE_DURATION) {
          setProgress((progressTime / AUTOPLAY_SLIDE_DURATION) * 100);
          myReq = requestAnimationFrame(step);
        }
      };
      myReq = requestAnimationFrame(step);
      timeout = setTimeout(() => {
        dispatch({ payload: { index, indexReady: nextIndex } });
        history.push(tourList[nextIndex].to);
      }, AUTOPLAY_SLIDE_DURATION);
    }
    return () => {
      cancelAnimationFrame(myReq);
      clearTimeout(timeout);
    };
  }, [isPlaying, index, nextIndex]);// eslint-disable-line
  return <TourContext.Provider value={value}>{children}</TourContext.Provider>;
};
