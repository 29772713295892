import { useState } from "react";
import { useEvent } from "./use-event";

interface Size {
  width: number;
  height: number;
}
export const useResize = (): Size => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEvent(window, "resize", () =>
    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  );
  return size;
};
