import { bp, Color, font, gridColumnStart, gridColumnWidth } from "#shared/theme";
import React from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  ${gridColumnStart(2)}
  ${gridColumnWidth(11)}
  @media ${bp.m} {
    ${gridColumnStart(3)}
    ${gridColumnWidth(18)}
  }
`;

const Headline = styled.blockquote`
  position: relative;
  margin: 0;
  font-family: ${font.condensed};
  font-size: 21px;
  line-height: 28px;
  @media ${bp.m} {
    font-size: 32px;
    line-height: 42px;
  }
  @media ${bp.p} {
    font-size: 42px;
    line-height: 52px;
  }
  @media ${bp.t} {
    font-size: 55px;
    line-height: 67px;
  }
  @media ${bp.d} {
    font-size: 66px;
    line-height: 81px;
  }
  &::before {
    content: "”";
    position: absolute;
    right: 100%;
    margin-right: 0.1em;
  }
  &::after {
    content: "“";
    margin-left: 0.1em;
  }
  strong {
    color: ${Color.BlueBold};
    font-size: 28px;
    @media ${bp.m} {
      font-size: 42px;
    }
    @media ${bp.p} {
      font-size: 52px;
    }
    @media ${bp.t} {
      font-size: 67px;
    }
    @media ${bp.d} {
      font-size: 81px;
    }
  }
`;
const Author = styled.cite`
  display: block;
  font-style: normal;
  font-family: ${font.condensed};
  font-size: 21px;
  line-height: 26px;
  margin-top: 0.5em;
  @media ${bp.m} {
    font-size: 42px;
  }
  @media ${bp.d} {
    font-size: 50px;
    line-height: 62px;
  }
`;

export interface QuotationProps {
  headline: string;
  author: string;
}

export const Quotation: React.FC<QuotationProps> = ({ headline, author }) => {
  return (
    <Container>
      <Headline dangerouslySetInnerHTML={{ __html: headline }} />
      <Author>{author}</Author>
    </Container>
  );
};
