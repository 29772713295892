export const VCP = {
  intro: {
    main: {
      author: "Oliver Zipse, CEO BMW AG",
      quote1:
        "„With the integrated report, we are taking an even more comprehensive approach than before to external and independent auditing of our sustainability activities.“",
      quote2: "„After all, credibility comes <a href='#' data-line='external'>transparency</a>.“",
    },
    modal: {
      title: "BMW GROUP <span>Wertschöpfungsprozess</span>",
      text:
        "Our value creation process is a concise communication about how ours strategy, governance, performance and prospects in the context of ours external environment, lead to the creation of value over the short, medium and long term.",
    },
  },
  chart: {
    item1: {
      title1: "Finanzkapital",
      title2: "Finanzkapital",
      inputLabel1: "Einkaufsvolumen EU (in Mio.)",
      inputValue1: 60132,
      inputLabel2: "Eigenkapital (in Mio.)",
      inputValue2: 59907,
      outputLabel1: "Cashflow (in Mio.)",
      outputValue1: 2567,
      outputLabel2: "Dividende vor <br/> Steuern (in Mio.)",
      outputValue2: 3.2,
    },
    item2: {
      title1: "Humankapital",
      title2: "Humankapital",
      inputLabel1: "Einkaufsvolumen EU (in Mio.)",
      inputValue1: 60132,
      inputLabel2: "Eigenkapital (in Mio.)",
      inputValue2: 59907,
      outputLabel1: "Cashflow (in Mio.)",
      outputValue1: 2567,
      outputLabel2: "Dividende vor <br/> Steuern (in Mio.)",
      outputValue2: 3.2,
    },
    item3: {
      title1: "Beziehungskapital",
      title2: "Beziehungskapital",
      inputLabel1: "Einkaufsvolumen EU (in Mio.)",
      inputValue1: 60132,
      inputLabel2: "Eigenkapital (in Mio.)",
      inputValue2: 59907,
      outputLabel1: "Cashflow (in Mio.)",
      outputValue1: 2567,
      outputLabel2: "Dividende vor <br/> Steuern (in Mio.)",
      outputValue2: 3.2,
    },
    item4: {
      title1: "Produziertes Kapital",
      title2: "Produziertes Kapital",
      inputLabel1: "Einkaufsvolumen EU (in Mio.)",
      inputValue1: 60132,
      inputLabel2: "Eigenkapital (in Mio.)",
      inputValue2: 59907,
      outputLabel1: "Cashflow (in Mio.)",
      outputValue1: 2567,
      outputLabel2: "Dividende vor <br/> Steuern (in Mio.)",
      outputValue2: 3.2,
    },
    item5: {
      title1: "Intellektuelles Kapital",
      title2: "Intellektuelles Kapital",
      inputLabel1: "Einkaufsvolumen EU (in Mio.)",
      inputValue1: 60132,
      inputLabel2: "Eigenkapital (in Mio.)",
      inputValue2: 59907,
      outputLabel1: "Cashflow (in Mio.)",
      outputValue1: 2567,
      outputLabel2: "Dividende vor <br/> Steuern (in Mio.)",
      outputValue2: 3.2,
    },
    item6: {
      title1: "Natürliches Kapital",
      title2: "Natürliches Kapital",
      inputLabel1: "Einkaufsvolumen EU (in Mio.)",
      inputValue1: 60132,
      inputLabel2: "Eigenkapital (in Mio.)",
      inputValue2: 59907,
      outputLabel1: "Cashflow (in Mio.)",
      outputValue1: 2567,
      outputLabel2: "Dividende vor <br/> Steuern (in Mio.)",
      outputValue2: 3.2,
    },
  },
};
