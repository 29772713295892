import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Core from "./webgl/index.js";

// import styled from "styled-components/macro";

// const WrapperWithGradient = styled.div`
//  background-image: radial-gradient(circle at 60% 80%,rgb(33, 51, 71),rgb(33, 51, 71));,
// `;

export const Webgl: React.FC<{ startPathname: string }> = ({ startPathname }) => {
  const coreRef = useRef<Core | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const [ready, setReady] = useState(false);
  console.log(pathname);
  const handleInit = () => {
    setReady(true);
  };
  useEffect(() => {
    const webglCore = new Core(wrapperRef.current, canvasRef.current);
    coreRef.current = webglCore;
    webglCore.loadAssets().then(() => {
      webglCore.init(handleInit);
    });
    // console.log("mounted", wrapperRef.current);
    // if (wrapperRef.current != null) {
    //   let rect = wrapperRef.current.getBoundingClientRect();
    //   console.log(window.innerHeight, wrapperRef.current.offsetHeight, rect.height);
    // }
    return () => {
      webglCore.dispose();
    };
  }, []);

  useEffect(() => {
    if (pathname !== startPathname && coreRef.current != null) {
      console.log("disposing?");
      coreRef.current.disposeScrollTriggerAnims();
    }
    if (coreRef.current === null || pathname !== startPathname || !ready) return;

    coreRef.current.startAnimation();
  }, [pathname, ready, startPathname]);

  useEffect(() => {
    if (coreRef.current == null) return;

    // coreRef.current.pathname = pathname;
    coreRef.current.core.enabled = pathname == "/home/start/" || pathname == "/home/intro/";

    if (pathname == "/home/start/") {
      coreRef.current.fadeIn(true);
    }
    // coreRef.current.core.enabled = pathname == "/home/start/" || pathname == "/home/intro/";

    // console.log(coreRef);
  }, [pathname]);
  //   useEffect(() => {
  //     console.log(isComplete);
  //     if (coreRef.current == null || !isComplete) return;
  //     console.log(isComplete);
  //     if (coreRef.current.isInitiated) coreRef.current.fadeIn();
  //     else coreRef.current.shouldPlayIntro = true;
  //   }, [isComplete]);

  //   useEffect(() => {
  //     // console.log("updated", window.innerHeight, wrapperRef.current == null ? null : wrapperRef.current.offsetHeight);
  //   }, [wrapperRef]);

  return (
    <div
      ref={wrapperRef}
      className="gradient-wrapper"
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        // top: 0,
        height: "100%",
        right: 0,
        width: "100%",
      }}
    >
      {/* <svg x="0px" y="0px" width="360px" height="360px" viewBox="0 0 360 360">
        <circle fill="none" stroke="black" strokeWidth="10" className="st0" cx="180" cy="180" r="172" />
      </svg> */}

      <canvas
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        ref={canvasRef}
      ></canvas>
    </div>
  );
};
