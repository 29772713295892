import { bpTypes } from "#shared/theme";

export const HEADER_HEIGHT = {
  [bpTypes.i]: 70,
  [bpTypes.m]: 90,
};

export const HERO_SVG_POSITIONS = {
  [bpTypes.d]: {
    textRight: -880,
    textLeft: -728,
    left: -1632,
    right: 0,
    bg: -1920,
  },
  [bpTypes.m]: {
    textRight: -698,
    textLeft: -1168,
    left: -1148,
    right: -1228,
    bg: -1248,
  },
  [bpTypes.i]: {
    textRight: -135,
    textLeft: -365,
    left: -335,
    right: -335,
    bg: -375,
  },
};
