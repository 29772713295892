import React from "react";
import { useMediaQuery } from "react-responsive";
import { bp } from "#shared/theme";
import { EBITMarginChartMobile } from "../EBITMarginChartMobile/EBITMarginChartMobile";
import { EBITMarginChartDesktop } from "../EBITMarginChartDesktop/EBITMarginChartDesktop";
import { useTranslation } from "#hooks";
import { Translations } from "#interfaces";
import { ChartContainer } from "../ChartContainer/ChartContainer";

export const EBITMarginChart: React.FC = () => {
  const { t } = useTranslation("tour");
  const labels = t("EBITMarginChart") as Translations;
  const isDesktop = useMediaQuery({ query: bp.m });
  return (
    <ChartContainer title={labels.title} tooltipContent={labels.tooltip}>
      {isDesktop ? <EBITMarginChartDesktop labels={labels} /> : <EBITMarginChartMobile labels={labels} />}
    </ChartContainer>
  );
};
