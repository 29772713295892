import { defStyle } from "#shared/theme/vars";
import { css, FlattenSimpleInterpolation } from "styled-components/macro";

export const defTransition = `${defStyle.duration} ${defStyle.easing}`;

export const clearBtn = css`
  -webkit-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: 0;
  padding: 0;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
`;

export const expander = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const overlay = (ext: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
  &::after {
    content: "";
    ${expander}
    ${ext}
  }
`;

export const fadeIn = css`
  opacity: 1;
  visibility: visible;
  transition: opacity ${defStyle.duration} ${defStyle.easing}, visibility 0s linear;
`;
export const fadeOut = css`
  opacity: 0;
  visibility: hidden;
  transition: opacity ${defStyle.duration} ${defStyle.easing}, visibility 0s linear ${defStyle.duration};
`;

export const slideRight = (animate = false): FlattenSimpleInterpolation => css`
  transform: translateX(-20px);
  transition: transform ${defTransition};
  ${animate &&
  css`
    transform: translateX(0);
  `}
`;
export const fadeInFor = (duration: number): FlattenSimpleInterpolation => css`
  opacity: 1;
  visibility: visible;
  transition: opacity ${duration}s ${defStyle.easing}, visibility 0s linear;
`;
export const fadeOutFor = (duration: number): FlattenSimpleInterpolation => css`
  opacity: 0;
  visibility: hidden;
  transition: opacity ${duration}s ${defStyle.easing}, visibility 0s linear ${duration}s;
`;
