import React, { RefObject, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { ButtonCircle, Icon, Wrapper } from "#ui";
import { bp, bpTypes, Color, defTransition, expander, fadeIn, fadeOut, font, IconKeys } from "#shared/theme";
import { HEADER_HEIGHT } from "#constants";
import { useOnClickOutside, useResize, useTranslation } from "#hooks";
import { Translations } from "#interfaces";
import htmr from "htmr";
import { rgba } from "polished";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 1;
`;

const Figure = styled.img`
  display: none;
  position: absolute;
  @media ${bp.m} {
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 288px;
    height: 285px;
    display: block;
  }
  @media ${bp.p} {
    width: 409px;
    height: 406px;
  }
  @media ${bp.d} {
    width: 492px;
    height: 486px;
  }
`;

const Author = styled.h3`
  text-transform: uppercase;
  font-size: 10px;
  margin-bottom: 20px;
  font-family: ${font.condensed};
  @media ${bp.m} {
    font-size: 13px;
    margin-bottom: 35px;
  }
  @media ${bp.d} {
    margin-bottom: 46px;
  }
`;

const Quote = styled.div`
  max-width: 290px;
  text-align: center;
  font-size: 22px;
  color: ${Color.Black};
  line-height: 1.25;
  @media ${bp.m} {
    max-width: 548px;
    font-size: 40px;
  }
  @media ${bp.p} {
    max-width: 690px;
    font-size: 40px;
  }
  @media ${bp.d} {
    max-width: 1044px;
    font-size: 60px;
    line-height: 1.17;
  }
`;

const QuoteInteractive = styled(Quote)<{ isActive?: boolean }>`
  position: relative;
  z-index: 3;
  color: ${({ isActive }) => (isActive ? Color.Green : Color.White)};
  transition: color ${defTransition};
  margin-top: 12px;
`;

const StyledQuoteLink = styled.a<{ isActive?: boolean }>`
  color: ${Color.Black};
  position: relative;
  display: inline-block;
  cursor: pointer;
  &:after {
    content: "";
    z-index: -1;
    position: absolute;
    left: 0;
    width: 100%;
    background-color: ${Color.Green};
    bottom: -4px;
    height: ${({ isActive }) => (isActive ? "calc(100% + 4px)" : "4px")};
    transition: height ${defTransition};
    @media ${bp.m} {
      bottom: -6px;
      height: ${({ isActive }) => (isActive ? "calc(100% + 6px)" : "6px")};
    }
  }
`;

const ModalOverlay = styled.div<{ isActive?: boolean }>`
  ${({ isActive }) => (isActive ? fadeIn : fadeOut)};
  ${expander}
  position: fixed;
  background-color: ${rgba(Color.Black, 0.6)};
`;

const ModalInner = styled.div<{ isActive?: boolean }>`
  ${({ isActive }) => (isActive ? fadeIn : fadeOut)};
  z-index: 1;
  position: absolute;
  top: ${HEADER_HEIGHT[bpTypes.i]}px;
  left: 50%;
  width: 100%;
  max-width: 325px;
  transform: translateX(-50%);
  font-family: ${font.condensed};
  padding: 20px;
  background-color: ${Color.Green};
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${bp.m} {
    top: ${HEADER_HEIGHT[bpTypes.m]}px;
    max-width: 385px;
    transform: none;
    left: auto;
    right: 0;
  }
`;

const ModalText = styled.div`
  font-size: 14px;
  margin-bottom: 28px;
  line-height: 1.57;
  letter-spacing: 0.025em;
  @media ${bp.m} {
    font-size: 17px;
  }
`;

const ModalTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 20px;
  span {
    display: block;
    font-family: ${font.serif};
    font-size: 20px;
    font-weight: normal;
  }
`;

const SvgLine = styled.svg<{ isActive: boolean }>`
  display: none;
  position: absolute;
  @media ${bp.m} {
    display: block;
    ${({ isActive }) => (isActive ? fadeIn : fadeOut)};
    stroke: ${Color.Green};
    stroke-width: 2px;
    pointer-events: none;
  }
`;

interface LineSizeProps {
  left: number;
  top: number;
  width: number;
  height: number;
  y1: number;
  y2: number;
  transform?: string;
}

interface QuoteLinkProps {
  hookRef: RefObject<HTMLAnchorElement>;
  onClick?: () => void;
  isActive?: boolean;
}

const QuoteLink: React.FC<QuoteLinkProps> = ({ children, hookRef, onClick, isActive }) => (
  <StyledQuoteLink ref={hookRef} isActive={isActive} onClick={onClick}>
    {children}
  </StyledQuoteLink>
);

const getA = (hookRef: RefObject<HTMLAnchorElement>, onClick: () => void, isActive: boolean) => ({
  children: _children,
}: {
  children?: React.ReactNode;
}) => {
  return (
    <QuoteLink hookRef={hookRef} onClick={onClick} isActive={isActive}>
      {_children}
    </QuoteLink>
  );
};

export const VCPIntro: React.FC<{ openVCP: () => void }> = ({ openVCP }) => {
  const { t } = useTranslation("VCP");
  const labels = t("intro") as Translations;
  const [isModalActive, setModalActive] = useState(false);
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const [lineCoords, setLineCoords] = useState<LineSizeProps>({ left: 0, top: 0, width: 0, height: 0, y1: 0, y2: 0 });
  const parentRef = useRef<HTMLDivElement>(null);
  const hookRef = useRef<HTMLAnchorElement>(null);

  const handleModalLinkClick = () => {
    setModalActive(!isModalActive);
  };
  const transform = {
    a: getA(hookRef, handleModalLinkClick, isModalActive),
  };
  const render = htmr(labels.main.quote2.toString(), { transform });
  const { y1, y2, ...lineSize } = lineCoords;
  const size = useResize();

  useOnClickOutside(
    modalInnerRef,
    () => {
      if (!isModalActive) return;
      setModalActive(false);
    },
    [hookRef]
  );

  useEffect(() => {
    if (!hookRef?.current || !parentRef?.current || !modalInnerRef?.current) return;
    const hookSize = hookRef.current.getBoundingClientRect();
    const parentSize = parentRef.current.getBoundingClientRect();
    const targetSize = modalInnerRef.current.getBoundingClientRect();

    const hookRight = hookSize.right - parentSize.left;
    const hookTop = hookSize.top - parentSize.top;
    const targetLeft = targetSize.left - parentSize.left + 2;
    const targetTop = targetSize.top + targetSize.height - parentSize.top - 3;
    const width = Math.abs(hookRight - targetLeft) + 2;
    const height = Math.abs(hookTop - targetTop) + 2;
    const y1 = height - 1.5;
    const y2 = 0.5;
    setLineCoords({
      transform: targetLeft < hookRight ? "scaleX(-1)" : "scaleX(1)",
      left: targetLeft < hookRight ? hookRight - width : hookRight - 1,
      top: Math.min(hookTop, targetTop),
      width,
      height,
      y1,
      y2,
    });
  }, [parentRef, hookRef, modalInnerRef, size]);

  return (
    <Wrapper>
      <Container ref={parentRef}>
        <Figure src="/data/vcp-bg.svg" />
        <Author>{labels.main.author}</Author>
        <Quote>{labels.main.quote1}</Quote>
        <QuoteInteractive isActive={isModalActive}>{render}</QuoteInteractive>
        <ModalOverlay isActive={isModalActive} />
        <ModalInner ref={modalInnerRef} isActive={isModalActive}>
          <ModalTitle dangerouslySetInnerHTML={{ __html: labels.modal.title.toString() }} />
          <ModalText>{labels.modal.text}</ModalText>
          <ButtonCircle onClick={openVCP}>
            <Icon iconKey={IconKeys.SvgArrowRight} />
          </ButtonCircle>
        </ModalInner>
        <SvgLine
          isActive={isModalActive}
          style={{ ...lineSize }}
          viewBox={`0 0 ${lineCoords.width} ${lineCoords.height}`}
        >
          <line x1={0.5} y1={lineCoords.y1} x2={lineCoords.width} y2={lineCoords.y2} />
        </SvgLine>
      </Container>
    </Wrapper>
  );
};
