import React from "react";
import { useMediaQuery } from "react-responsive";
import { bp } from "#shared/theme";
import { ShareEarningsChartDesktop } from "../ShareEarningsChartDesktop/ShareEarningsChartDesktop";
import { ShareEarningsChartMobile } from "../ShareEarningsChartMobile/ShareEarningsChartMobile";
import { useTranslation } from "#hooks";
import { Translations } from "#interfaces";
import { ChartContainer } from "../ChartContainer/ChartContainer";

export const ShareEarningsChart: React.FC = () => {
  const { t } = useTranslation("tour");
  const labels = t("ShareEarningsChart") as Translations;
  const isDesktop = useMediaQuery({ query: bp.m });
  return (
    <ChartContainer title={labels.title} tooltipContent={labels.tooltip}>
      {isDesktop ? <ShareEarningsChartDesktop labels={labels} /> : <ShareEarningsChartMobile labels={labels} />}
    </ChartContainer>
  );
};
