import { PageName } from "src/interfaces";

export const pages = {
  list: {
    [PageName.index]: {
      path: "/",
      title: "BMW Group Bericht 2020",
    },
    [PageName.home]: {
      path: "/home/",
    },
    [PageName.start]: {
      path: "/home/start/",
      title: "Home",
      description: "",
      image: "",
    },
    [PageName.intro]: {
      path: "/home/intro/",
      title: "Intro",
      description: "",
      image: "",
    },
    [PageName.intro_ceo]: {
      path: "/home/intro#ceo",
      title: "Intro CEO",
      description: "",
      image: "",
    },
    [PageName.intro_cfo]: {
      path: "/home/intro#cfo",
      title: "Prolog CFO",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020]: {
      path: "/home/guided-tour-2020/",
      title: "Guided Tour 2020",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_automobile_deliveries]: {
      path: "/home/guided-tour-2020/#auslieferungen-automobile",
      title: "Auslieferungen Automobile",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_ebit_margin_automotive_segment]: {
      path: "/home/guided-tour-2020/#ebit-marge-segment-automobile",
      title: "EBIT-Marge Segment Automobile",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_motorcycles_deliveries]: {
      path: "/home/guided-tour-2020/#auslieferungen-motorraeder",
      title: "Auslieferungen Motorräder",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_new_contracts_retail_customer_financing_financial_services]: {
      path: "/home/guided-tour-2020/#neuvertraege-finanzdienstleistungen",
      title: "Neuverträge Finanzdienstleistungen",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_group_key_figures]: {
      path: "/home/guided-tour-2020/#konzernkennzahlen",
      title: "Konzernkennzahlen",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_earnings_per_share]: {
      path: "/home/guided-tour-2020/#ergebnis-je-aktie",
      title: "Ergebnis je Aktie",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_research_and_development_expenditure]: {
      path: "/home/guided-tour-2020/#f-e-leistungen",
      title: "F&E Leistungen",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_key_automobile_markets]: {
      path: "/home/guided-tour-2020/#die-wichtigsten-automobilmaerkte",
      title: "Die wichtigsten Automobilmärkte",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_deliveries_of_electrified_vehicles]: {
      path: "/home/guided-tour-2020/#auslieferungen-elektrifizierte-fahrzeuge",
      title: "Auslieferungen elektrifizierte Fahrzeuge",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_co2_emissions_per_vehicle_in_production_and_eu_fleet_value]: {
      path: "/home/guided-tour-2020/#co2-emissionen-je-fahrzeug-in-der-produktion-und-eu-flottenwert",
      title: "CO2 Emissionen je Fahrzeug in der Produktion und EU Flottenwert",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_share_of_women_in_management_positions_at_bmw_ag]: {
      path: "/home/guided-tour-2020/#frauenanteil-in-fuehrungspositionen-in-der-bmw-ag",
      title: "Frauenanteil in Führungspositionen in der BMW AG",
      description: "",
      image: "",
    },
    [PageName.interactiveValueCreationProcess]: {
      path: "/wertschoepfung",
      title: "Wie die BMW Group Werte schafft",
      description: "",
      image: "",
    },
    [PageName.interviewDiversity]: {
      path: "/interview/diversity",
      title: "Interview 1 / Diversity",
      description: "",
      image: "",
    },
    [PageName.interviewFleetEmissions]: {
      path: "/interview/flottenemissionen",
      title: "Interview 2 / Flottenemissionen",
      description: "",
      image: "",
    },
    [PageName.interviewCleanProduction]: {
      path: "/interview/clean-production",
      title: "Interview 3 / Clean Production",
      description: "",
      image: "",
    },
    [PageName.ReportOfTheSupervisoryBoard]: {
      path: "/bericht-des-aufsichtsrats",
      title: "Bericht des Aufsichtsrats",
      description: "",
      image: "",
    },
    [PageName.statementOfTheChairmanOfTheBoardOfManagement]: {
      path: "/vorwort-des-vorstandsvorsitzenden",
      title: "Vorwort des Vorstandsvorsitzenden",
      description: "",
      image: "",
    },
    [PageName.financialStatements]: {
      path: "/konzernabschluss",
      title: "Konzernabschluss",
      description: "",
      image: "",
    },
    [PageName.downloads]: {
      path: "/downloads",
      title: "Downloads",
      description: "",
      image: "",
    },
    [PageName.glossary]: {
      path: "/glossar",
      title: "Glossar",
      description: "",
      image: "",
    },
    [PageName.legalDisclaimer]: {
      path: "/rechtliche-hinweise",
      title: "Rechtliche Hinweise",
      description: "",
      image: "",
    },
    [PageName.cookies]: {
      path: "/cookies",
      title: "Cookies",
      description: "",
      image: "",
    },
    [PageName.imprint]: {
      path: "/impressum",
      title: "Impressum",
      description: "",
      image: "",
    },
    [PageName.contact]: {
      path: "/kontakt",
      title: "Kontakt",
      description: "",
      image: "",
    },
    [PageName.dataProtection]: {
      path: "/datenschutz",
      title: "Datenschutz",
      description: "",
      image: "",
    },
  },
};
