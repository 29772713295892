import { useEffect } from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";

const lockPosition = {
  x: 0,
  y: 0,
};

const scrollListener = (ev: Event): void => {
  ev.preventDefault();
  ev.stopPropagation();

  window.scrollTo(lockPosition.x, lockPosition.y);
};

const lockCurrentPosition = (): void => {
  lockPosition.x = window.scrollX;
  lockPosition.y = window.scrollY;
};

export const disableScrolling = (): void => {
  lockCurrentPosition();
  disablePageScroll();
  window.addEventListener("scroll", scrollListener);
};

export const enableScrolling = (): void => {
  enablePageScroll();
  window.removeEventListener("scroll", scrollListener);
};

export const useWindowScrollLock = (): void => {
  useEffect(() => {
    disableScrolling();
    return enableScrolling;
  }, []);
};
