import React from "react";
import { GeneralPageLayout, Text } from "#ui";
import { Link } from "#ui";
import { Root } from "#components";

const text =
  "<h1>Cookies</h1><h3>What are cookies?</h3><p>A cookie is a small file that stores Internet settings. Nearly all websites use cookie technology.</p><p>This technology is downloaded by your internet browser the first time you visit a website. The next time you access this website from the same device, the cookie and the information stored in it is either sent back to the site that generated it (first-party cookie) or to a different website to which it belongs (third-party cookie). This enables the website to recognise whether you have already accessed the site through this browser and, in many cases, it may then vary the content displayed.</p><p>Some cookies can be extremely useful, because they improve the user experience when you return to a site you have already visited several times. As long as you use the same terminal and the same browser, cookies will remember your preferences for the site, for example, and make the offers displayed more relevant to your personal interests and needs. Depending on their function and purpose, cookies can be divided into four categories <sup>*</sup>: essential cookies; performance cookies; functional cookies; and cookies for marketing purposes.</p><p>On the BMW Group website, you have the option of agreeing to the use of cookies in principle and thus being able to use all functions of the website without restrictions. If you do not accept or deactivate the use of cookies, this may result in functional restrictions. In the case of deactivation, no distinction is made between first-party cookies and third-party cookies.</p>";

export const Cookies: React.FC = () => {
  return (
    <Root withHeader headerProps={{ transparent: true }}>
      <GeneralPageLayout>
        <Text text={text} />
        <Link to="/home">Home</Link>
      </GeneralPageLayout>
    </Root>
  );
};
