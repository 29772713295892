export enum ThemeKeys {
  textColor = "textColor",
  scrollbarColor = "scrollbarColor",
  infoBg = "infoBg",
  navButtonColor = "navButtonColor",
}

export enum TextColorThemeTypes {
  Default = "Default",
  Light = "Light",
}

export enum LanguageLabels {
  en = "en",
  de = "de",
}
export enum Languages {
  en = "report",
  de = "bericht",
}
