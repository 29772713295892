import { PageName } from "src/interfaces";
import { pages } from "./pages";

export const menu = {
  tour: {
    sound: "/data/autopilot.mp3",
    list: [
      {
        title: "Guided Tour´20",
        list: [
          {
            pageName: PageName.guidedTour2020_automobile_deliveries,
            to: pages.list[PageName.guidedTour2020_automobile_deliveries].path,
            text: pages.list[PageName.guidedTour2020_automobile_deliveries].title,
            slide: "Deliveries",
          },
          {
            pageName: PageName.guidedTour2020_ebit_margin_automotive_segment,
            to: pages.list[PageName.guidedTour2020_ebit_margin_automotive_segment].path,
            text: pages.list[PageName.guidedTour2020_ebit_margin_automotive_segment].title,
            slide: "EbitMargin",
          },
          {
            pageName: PageName.guidedTour2020_motorcycles_deliveries,
            to: pages.list[PageName.guidedTour2020_motorcycles_deliveries].path,
            text: pages.list[PageName.guidedTour2020_motorcycles_deliveries].title,
            slide: "Moto",
          },
          {
            pageName: PageName.guidedTour2020_new_contracts_retail_customer_financing_financial_services,
            to: pages.list[PageName.guidedTour2020_new_contracts_retail_customer_financing_financial_services].path,
            text: pages.list[PageName.guidedTour2020_new_contracts_retail_customer_financing_financial_services].title,
            slide: "Contracts",
          },
          {
            pageName: PageName.guidedTour2020_group_key_figures,
            to: pages.list[PageName.guidedTour2020_group_key_figures].path,
            text: pages.list[PageName.guidedTour2020_group_key_figures].title,
            slide: "Keyfigures",
          },
          {
            pageName: PageName.guidedTour2020_earnings_per_share,
            to: pages.list[PageName.guidedTour2020_earnings_per_share].path,
            text: pages.list[PageName.guidedTour2020_earnings_per_share].title,
            slide: "Shares",
          },
          {
            pageName: PageName.guidedTour2020_research_and_development_expenditure,
            to: pages.list[PageName.guidedTour2020_research_and_development_expenditure].path,
            text: pages.list[PageName.guidedTour2020_research_and_development_expenditure].title,
            slide: "Research",
          },
          {
            pageName: PageName.guidedTour2020_key_automobile_markets,
            to: pages.list[PageName.guidedTour2020_key_automobile_markets].path,
            text: pages.list[PageName.guidedTour2020_key_automobile_markets].title,
            slide: "Markets",
          },
          {
            pageName: PageName.guidedTour2020_deliveries_of_electrified_vehicles,
            to: pages.list[PageName.guidedTour2020_deliveries_of_electrified_vehicles].path,
            text: pages.list[PageName.guidedTour2020_deliveries_of_electrified_vehicles].title,
            slide: "ElectricVehicles",
          },
          {
            pageName: PageName.guidedTour2020_co2_emissions_per_vehicle_in_production_and_eu_fleet_value,
            to: pages.list[PageName.guidedTour2020_co2_emissions_per_vehicle_in_production_and_eu_fleet_value].path,
            text: pages.list[PageName.guidedTour2020_co2_emissions_per_vehicle_in_production_and_eu_fleet_value].title,
            slide: "Emissions",
          },
          {
            pageName: PageName.guidedTour2020_share_of_women_in_management_positions_at_bmw_ag,
            to: pages.list[PageName.guidedTour2020_share_of_women_in_management_positions_at_bmw_ag].path,
            text: pages.list[PageName.guidedTour2020_share_of_women_in_management_positions_at_bmw_ag].title,
            slide: "Women",
          },
        ],
      },
    ],
  },
  intro: {
    list: [
      {
        list: [
          {
            to: pages.list[PageName.intro_ceo].path,
            title: "Kein Premium ohne Verantwortung",
            text: "Oliver Zipse über erreichte Ziele und die neue strategische Ausrichtung der BMW Group",
          },
          {
            to: pages.list[PageName.intro_cfo].path,
            title: "CFO intro",
            text: "lorem",
          },
        ],
      },
    ],
  },
};
