import { bp, Color, font, gridColumnStart, gridColumnWidth } from "#shared/theme";
import { Grid } from "../Grid/Grid";
import React from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  font-size: 13px;
  line-height: 19px;
  font-family: ${font.condensed};
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.s} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.m} {
    ${gridColumnStart(4)}
    ${gridColumnWidth(13)}
  }
  @media ${bp.p} {
    ${gridColumnStart(5)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.t} {
    ${gridColumnWidth(8)}
  }
  @media ${bp.d} {
    ${gridColumnWidth(6)}
  }
`;
const Headline = styled.div``;
const Link = styled.a`
  display: block;
  margin-top: 10px;
  strong {
    display: block;
    color: ${Color.Green};
    font-size: 15px;
  }
  small {
    font-size: inherit;
    border-bottom: 2px solid ${Color.Green};
    padding-bottom: 5px;
  }
`;

interface PreCaptionProps {
  headline: string;
  link?: {
    href: string;
    label: string;
  };
}

export const PreCaption: React.FC<PreCaptionProps> = ({ headline, link }) => {
  return (
    <Grid>
      <Container>
        <Headline>{headline}</Headline>
        {link && <Link href={link.href} dangerouslySetInnerHTML={{ __html: link.label }} />}
      </Container>
    </Grid>
  );
};
