import { bp, Color, font, maxHeight, screenSize } from "#shared/theme";
import React from "react";
import { ThemeKeys } from "#constants";
import { InfoCardTheme } from "#interfaces";
import styled, { css } from "styled-components/macro";
import theme from "styled-theming";

const Container = styled.div`
  position: relative;
  background-color: ${Color.White};
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex: 1 0 0%;
  flex-direction: column;
  @media ${maxHeight(screenSize.s)} and (orientation: landscape) {
    height: 100%;
    flex-direction: row;
    padding-bottom: 15px;
  }
  ${theme(ThemeKeys.infoBg, {
    [InfoCardTheme.GrayLightest]: css`
      background-color: ${Color.GrayLightest};
    `,
  })}
  ${theme(ThemeKeys.infoBg, {
    [InfoCardTheme.Gray]: css`
      background-color: ${Color.GrayLighten};
    `,
  })}
`;
const Head = styled.div`
  padding: 35px 15px 20px;
  position: relative;
  z-index: 1;
  @media ${maxHeight(screenSize.s)} and (orientation: landscape) {
    overflow-wrap: normal;
    width: fit-content;
    padding-right: 20px;
  }
  @media ${bp.m} {
    padding-bottom: 45px;
  }
`;
const Category = styled.div`
  font-family: ${font.condensed};
  font-size: 13px;
  line-height: 1.23;
`;
const Title = styled.h2`
  font-size: 22px;
  line-height: 1.45;
  @media ${bp.m} {
    font-size: 26px;
  }
`;
const Body = styled.div`
  flex: 1 0 auto;
  max-width: 100%;
  display: flex;
`;

export interface InfoCardProps {
  category?: string;
  title?: string;
}

export const InfoCard: React.FC<InfoCardProps> = ({ category, title, children }) => {
  return (
    <Container>
      {(category || title) && (
        <Head>
          {category && <Category>{category}</Category>}
          {title && <Title>{title}</Title>}
        </Head>
      )}
      <Body>{children}</Body>
    </Container>
  );
};
