import React from "react";
import { ControlledLineIconTypes, ControlledLineTargetRef } from "#interfaces";
import styled, { css } from "styled-components/macro";
import { Color, bp, IconKeys, font } from "#shared/theme";
import { Icon } from "../Icon/Icon";
import htmr from "htmr";
import { Link } from "react-router-dom";

const containerStyles = css`
  display: flex;
  margin-top: 1em;
  cursor: pointer;
`;
const ContainerLink = styled(Link)`
  ${containerStyles}
`;
const Container = styled.div`
  ${containerStyles}
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  fill: ${Color.Green};
  vertical-align: text-bottom;
`;
const StyledIconLinkExternal = styled(StyledIcon)`
  flex: 0 0 27px;
  height: 24px;
  @media ${bp.d} {
    flex: 0 0 30px;
    height: 28px;
  }
`;
const StyledIconPdf = styled(StyledIcon)`
  flex: 0 0 32px;
  height: 24px;
  @media ${bp.d} {
    flex: 0 0 62px;
    height: 46px;
  }
`;
const IconLinkExternal: React.FC = () => <StyledIconLinkExternal iconKey={IconKeys.SvgExternalLink} />;
const IconPdf: React.FC = () => <StyledIconPdf iconKey={IconKeys.SvgPdf} />;

const spanLineIcons: { [k: string]: JSX.Element } = {
  [ControlledLineIconTypes.external]: <IconLinkExternal />,
  [ControlledLineIconTypes.pdf]: <IconPdf />,
};

const LinkLabel = styled.span`
  margin-left: 5px;
  font-size: 15px;
  line-height: 17px;
  font-family: ${font.condensed};
  color: ${Color.Green};
  @media ${bp.d} {
    font-size: 18px;
    line-height: 20px;
    margin-left: 15px;
  }
  strong {
    display: block;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export interface LinkIconProps {
  targetRef?: ControlledLineTargetRef;
  type?: ControlledLineIconTypes;
  label?: string;
  href: string;
  onClick?: () => void;
}

export const LinkIcon: React.FC<LinkIconProps> = ({ type, targetRef, label, href, onClick }) => {
  const children = (
    <>
      {type && spanLineIcons[type]}
      {label && <LinkLabel>{htmr(label)}</LinkLabel>}
    </>
  );
  return href ? (
    <ContainerLink ref={targetRef} to={href}>
      {children}
    </ContainerLink>
  ) : (
    <Container ref={targetRef} onClick={onClick}>
      {children}
    </Container>
  );
};
