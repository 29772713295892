import { useChartSwitcher } from "#hooks/use-chart-switcher";
import { SvgContainer } from "#ui";
import React from "react";
import { ChartProps } from "#interfaces";
import { LeftKPI } from "./LeftKPI";
import { MainKPI } from "./MainKPI";
import { RightKPI } from "./RightKPI";

const CHART_COUNT = 3;

export const KeyFiguresChartMobile: React.FC<Pick<ChartProps, "labels">> = ({ labels }) => {
  const chartProps = useChartSwitcher(CHART_COUNT);
  const leftKPIRate = Number(labels?.leftKPI?.currentValue) / Number(labels?.mainKPI?.currentValue);
  const rightKPIRate = Number(labels?.rightKPI_1?.currentValue) / Number(labels?.mainKPI?.currentValue);
  return (
    <SvgContainer viewBox="0 0 320 400" xmlns="http://www.w3.org/2000/svg">
      <RightKPI currentRate={rightKPIRate} labels={labels?.rightKPI_1} {...chartProps} />
      <LeftKPI currentRate={leftKPIRate} labels={labels?.leftKPI} {...chartProps} />
      <MainKPI labels={labels?.mainKPI} {...chartProps} />
      <line fill="none" stroke="#000000" x1="134" y1="328.5" x2="149" y2="328.5" />
      <line fill="none" stroke="#000000" x1="155" y1="328.5" x2="164" y2="328.5" />
      <line fill="none" stroke="#000000" x1="170" y1="328.5" x2="185" y2="328.5" />
    </SvgContainer>
  );
};
