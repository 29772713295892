import React from "react";
import { useMediaQuery } from "react-responsive";
import { bp } from "#shared/theme";
import { MultipleChart } from "../MultipleChart/MultipleChart";
import { ElectricChartMobile } from "../ElectricChartMobile/ElectricChartMobile";
import { useTranslation } from "#hooks";
import { Translations } from "#interfaces";
import { ChartContainer } from "../ChartContainer/ChartContainer";

export const ElectricChart: React.FC = () => {
  const { t } = useTranslation("tour");
  const labels = t("ElectricVehiclesChart") as Translations;
  const isDesktop = useMediaQuery({ query: bp.m });
  return (
    <ChartContainer title={labels.title} tooltipContent={labels.tooltip}>
      {isDesktop ? <MultipleChart labels={labels} /> : <ElectricChartMobile labels={labels} />}
    </ChartContainer>
  );
};
