import React from "react";
import { bpTypes } from "#shared/theme";
import { ReactComponent as LargeDesktopSvg } from "./itemsDe/large-desktop1.svg";
import { ReactComponent as MobileSvg } from "./itemsDe/mobile1.svg";
import { ReactComponent as DesktopSvg } from "./itemsDe/desktop1.svg";

export const scrollHeroListDe = [
  { [bpTypes.i]: <MobileSvg />, [bpTypes.m]: <DesktopSvg />, [bpTypes.d]: <LargeDesktopSvg /> },
];
export const scrollHeroListEn = [
  { [bpTypes.i]: <MobileSvg />, [bpTypes.m]: <DesktopSvg />, [bpTypes.d]: <LargeDesktopSvg /> },
];
