import React from "react";
import { Color } from "#shared/theme";
import styled from "styled-components/macro";

const Main = styled.main`
  padding-top: 90px;
  flex: 1 0 auto;
  background-color: ${Color.GrayLighter};
`;

interface ContentProps {
  className?: string;
}

export const MainContent: React.FC<ContentProps> = ({ children, className }) => {
  return <Main className={className}>{children}</Main>;
};
