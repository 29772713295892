import { bp } from "#shared/theme";
import React from "react";
import styled from "styled-components/macro";
import { InfoCard, InfoCardProps } from "./InfoCard";

const Container = styled.div`
  position: relative;
  min-width: 260px;
  @media ${bp.m} {
    margin: 20px 35px;
    min-width: 390px;
  }
`;

interface InfoCardDiagramProps extends InfoCardProps {
  copytext: string;
}

export const InfoCardDiagram: React.FC<InfoCardDiagramProps> = ({ title, category, children }) => {
  return (
    <InfoCard title={title} category={category}>
      <Container>{children}</Container>
    </InfoCard>
  );
};
