/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeKeys } from "#constants";
import { InfoCardTheme, InfoCardType } from "#interfaces";
import React from "react";
import { bp } from "#shared/theme";
import styled, { ThemeProvider } from "styled-components/macro";
import { InfoCardProps } from "./InfoCard";
import { InfoCardCopytext } from "./InfoCardCopytext";
import { InfoCardDiagram } from "./InfoCardDiagram";
import { InfoCardGesagt } from "./InfoCardGesagt";
import { InfoCardGetan } from "./InfoCardGetan";
import { InfoCardPdf } from "./InfoCardPdf";

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  @media ${bp.m} {
    align-items: stretch;
  }
`;

export const infoCards: { [k: string]: React.FC<any> } = {
  [InfoCardType.Copytext]: InfoCardCopytext,
  [InfoCardType.Pdf]: InfoCardPdf,
  [InfoCardType.Diagram]: InfoCardDiagram,
  [InfoCardType.Gesagt]: InfoCardGesagt,
  [InfoCardType.Getan]: InfoCardGetan,
};

export interface InfoItemProps extends InfoCardProps {
  type: InfoCardType;
  props: any;
}

export interface InfoProps {
  list: InfoItemProps[];
}

export const Info: React.FC<InfoProps> = ({ list }) => {
  return (
    <Container>
      {list.map(({ type, props }, key) => {
        const CardContent = infoCards[type];
        return (
          <ThemeProvider theme={{ [ThemeKeys.infoBg]: props.theme || InfoCardTheme.Default }} key={key}>
            <CardContent {...props} />
          </ThemeProvider>
        );
      })}
    </Container>
  );
};
