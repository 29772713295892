import { LanguageLabels, Languages, TextColorThemeTypes, ThemeKeys } from "#constants";
import { Color, defTransition, font } from "#shared/theme";
import React from "react";
import styled, { css } from "styled-components/macro";
import theme from "styled-theming";
import i18n from "../../i18n";

interface LangItemProps {
  label: LanguageLabels;
  key: Languages;
  to: string;
}

const { protocol, host } = location;
const subdomain = host.split(".")[0];

const languages: LangItemProps[] = [
  { key: Languages.de, label: LanguageLabels.de, to: `${protocol}//${host.replace(subdomain, Languages.de)}` },
  { key: Languages.en, label: LanguageLabels.en, to: `${protocol}//${host.replace(subdomain, Languages.en)}` },
];

const lang = i18n.language;

const Container = styled.div`
  display: flex;
  margin: 0 -4px;
`;

interface BtnProps {
  isActive: boolean;
}

const StyledLink = styled.a<BtnProps>`
  ${({ isActive }): theme.ThemeSet =>
    theme(ThemeKeys.textColor, {
      [TextColorThemeTypes.Default]: css`
        color: ${isActive ? Color.BlueLight : Color.Black};
      `,
      [TextColorThemeTypes.Light]: css`
        color: ${isActive ? Color.Green : Color.White};
      `,
    })}
  font-family: ${font.condensed};
  font-size: 13px;
  padding: 4px;
  transition: color ${defTransition};
  & + & {
    margin-left: 1px;
  }
`;

export const LangSwitcher: React.FC = () => {
  return (
    <Container>
      {languages.map((item) => (
        <StyledLink href={item.to} key={item.label} isActive={item.key === lang}>
          {item.label}
        </StyledLink>
      ))}
    </Container>
  );
};
