export default {
  mountWidth: 80,
  mountLength: 150,
  roadWidth: 5,
  travelLength: 400,
  lightRadius: 0.1,
  lightLength: 140,
  curveAmplitude: 30,
  curveFrequency: 0.03,
};
