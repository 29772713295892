import React from "react";
import { font } from "#shared/theme";
import styled from "styled-components/macro";

const Container = styled.div`
  max-width: 500px;
  font-family: ${font.condensed};
  font-size: 12px;
`;
const Item = styled.div`
  padding-left: 30px;
  &::before {
    content: "*";
    width: 10px;
    margin-right: 5px;
    margin-left: -10px;
  }
`;

interface FootnoteProps {
  list: string[];
}
export const Footnote: React.FC<FootnoteProps> = ({ list }) => {
  const renderList = (item: string, key: number) => {
    return <Item key={key}>{item}</Item>;
  };
  return <Container>{list.map(renderList)}</Container>;
};
