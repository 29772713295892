import React, { ReactComponentElement, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useMediaQuery } from "react-responsive";
import { bp, bpTypes } from "#shared/theme";
import { HERO_SVG_POSITIONS } from "#constants";
import useMeasure from "react-use-measure";
import mergeRefs from "react-merge-refs";

gsap.registerPlugin(ScrollTrigger);

const Wrapper = styled.div`
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

const Container = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  svg {
    height: 100%;
    font-weight: bold;
  }
`;

interface ItemProps {
  [p: string]: ReactComponentElement<"svg">;
}

export interface ScrollHeroProps {
  items: ItemProps;
}

export const ScrollHero: React.FC<ScrollHeroProps> = ({ items }) => {
  const [screen, setScreen] = useState(bpTypes.i);
  const largeDesktop = useMediaQuery({ query: bp.d });
  const desktop = useMediaQuery({ query: bp.m });
  const [containerMeasureRef, { width: containerWidth }] = useMeasure();
  const [wrapperMeasureRef, { width: wrapperWidth }] = useMeasure();
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const diff = containerWidth - wrapperWidth;
  const diffPercent = diff < containerWidth ? ((containerWidth - wrapperWidth) * 2) / containerWidth : 1;
  const tl = useMemo(() => gsap.timeline({ defaults: { willChange: "transform" } }), []);
  useEffect(() => {
    if (largeDesktop) {
      setScreen(bpTypes.d);
    } else if (desktop) {
      setScreen(bpTypes.m);
    } else {
      setScreen(bpTypes.i);
    }
  }, [largeDesktop, desktop]);
  useEffect(() => {
    if (!wrapperRef.current) return;
    tl.clear();
    tl.fromTo(containerRef.current, { x: 0 }, { x: -diff }, 0)
      .fromTo("#story-header-text-right", { x: HERO_SVG_POSITIONS[screen].textRight * diffPercent }, { x: 0 }, 0)
      .fromTo("#story-header-text-left", { x: 0 }, { x: HERO_SVG_POSITIONS[screen].textLeft * diffPercent }, 0)
      // .fromTo("#story-header-left", { x: 0 }, { x: -diff * 0.2 }, 0)
      .fromTo("#story-header-right", { x: HERO_SVG_POSITIONS[screen].right * diffPercent }, { x: 0 }, 0);
    gsap
      .timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          pin: true,
          pinSpacing: true,
          start: "top top",
          end: "bottom",
          scrub: 1,
        },
      })
      .add(tl);
  }, [wrapperRef, screen, tl, diff, diffPercent]);
  return (
    <Wrapper ref={mergeRefs([wrapperRef, wrapperMeasureRef])}>
      <Container ref={mergeRefs([containerRef, containerMeasureRef])}>{items[screen]}</Container>
    </Wrapper>
  );
};
