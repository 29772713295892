import { Footer, Routes } from "#components";
import { routes } from "#pages/routes";
import { ModalContainer, RoutingProvider, ScrollLockProvider } from "#providers";
import { GlobalStyle, Loader } from "#ui";
import React, { useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import { AudioPlayerProvider } from "react-use-audio-player";
import styled from "styled-components/macro";

const Container = styled.div`
  flex: 1 0 0%;
  display: flex;
  flex-direction: column;
`;

export const Skeleton: React.FC = () => {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  return (
    <AudioPlayerProvider>
      <ScrollLockProvider>
        <BrowserRouter>
          <RoutingProvider>
            <ModalContainer.Provider value={{ modalContainerRef }}>
              <Container ref={modalContainerRef}>
                <GlobalStyle />
                <Routes list={routes} level={1} />
                <Footer />
              </Container>
            </ModalContainer.Provider>
          </RoutingProvider>
        </BrowserRouter>
      </ScrollLockProvider>
      <Loader />
    </AudioPlayerProvider>
  );
};

export const App: React.FC = () => {
  return <Skeleton />;
};
