import React, { useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import { bp } from "#shared/theme";
import { InfoCard, InfoCardProps } from "./InfoCard";
import styled from "styled-components/macro";

const Container = styled.div`
  position: relative;
  margin: 0 15px;
  @media ${bp.m} {
    margin: 20px 50px 70px;
  }
`;
const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  column-width: 260px;
  column-fill: auto;
  column-gap: 20px;
  @media ${bp.m} {
    column-gap: 30px;
    column-width: 390px;
  }
  @media ${bp.p} {
    column-width: 490px;
  }
`;
const Text = styled.div`
  width: fit-content;
  height: fit-content;
  p + p {
    margin-top: 20px;
  }
  img {
    max-width: 100%;
  }
`;

interface InfoCardCopytextProps extends InfoCardProps {
  copytext: string;
}

export const InfoCardCopytext: React.FC<InfoCardCopytextProps> = ({ title, category, copytext }) => {
  const [width, setWidth] = useState<number>(100000);
  const [textRef, { width: textWidth }] = useMeasure();
  useEffect(() => {
    if (textWidth === 0) return;
    setWidth(textWidth);
  }, [textWidth, setWidth]);
  return (
    <InfoCard title={title} category={category}>
      <Container style={{ width: width > 0 ? `${width}px` : "auto" }}>
        <Box>
          <Text dangerouslySetInnerHTML={{ __html: copytext }} ref={textRef} />
        </Box>
      </Container>
    </InfoCard>
  );
};
