import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { ogLocales } from "src/constants";
import { useTranslation } from "src/hooks";
import i18n from "src/i18n";
import { PageMeta, PageName } from "src/interfaces";

export const Head: React.FC = () => {
  const { pathname, hash } = useLocation();
  const { t: tPages } = useTranslation("pages");
  const pagesList = tPages("list") as { [k: string]: PageMeta };
  const [meta, setMeta] = useState<PageMeta>({
    title: pagesList[PageName.index].title,
    description: pagesList[PageName.index].description,
    image: pagesList[PageName.index].image,
    path: pagesList[PageName.index].path,
  });
  useEffect(() => {
    const foundPage = Object.entries(pagesList).find((item) => {
      return item[1].path === `${pathname}${hash}`;
    });
    const { title: pageTitle, description, image, path } = foundPage?.[1] || (pagesList[PageName.index] as any);
    const { title: indexPageTitle } = pagesList[PageName.index];
    const title = `${pageTitle} — ${indexPageTitle}`;
    if (path === meta.path) return;
    setMeta({
      title,
      description,
      image,
      path,
    });
  }, [pathname, hash, pagesList, meta]);
  const { title, description, image } = meta;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:locale" content={ogLocales[i18n.language]} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure_url" content={image} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};
