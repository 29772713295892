import * as THREE from "three";
import vertexShader from "./shaders/js/roadVert";
import fragmentShader from "./shaders/js/roadFrag";
class Road extends THREE.Mesh {
  constructor(Core) {
    super();

    this.geometry = new THREE.PlaneBufferGeometry(1, 1, 1, 100);
    // this.renderOrder = -1;
    this.material = new THREE.ShaderMaterial({
      // wireframe: true,
      // depthTest: true,
      fog: true,
      transparent: true,
      uniforms: {
        ...THREE.UniformsUtils.clone(THREE.UniformsLib["fog"]),

        uTime: Core.globalUniforms.uTime,
        uTravelLength: Core.globalUniforms.uTravelLength,
        uCurveAmplitude: Core.globalUniforms.uCurveAmplitude,
        uCurveFrequency: Core.globalUniforms.uCurveFrequency,
        uColorSwap: new THREE.Uniform(1),
        uOpacity: new THREE.Uniform(1),
      },
      vertexShader,
      fragmentShader,
    });
  }
  dispose() {
    if (this.isDisposed) return;
    this.geometry.dispose();
    this.material.dispose();
    this.geometry = null;
    this.material = null;

    this.isDisposed = true;
  }
}

export default Road;
