import React, { Suspense } from "react";
import { hydrate, render } from "react-dom";
import { App } from "./App";
import { Fallback } from "#ui";
import "./i18n";

const Init = () => (
  <Suspense fallback={<Fallback />}>
    <App />
  </Suspense>
);

const rootElement = document.getElementById("root");
if (rootElement?.hasChildNodes()) {
  hydrate(<Init />, rootElement);
} else {
  render(<Init />, rootElement);
}
