import React, { RefObject, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { bp, Color, fadeIn, fadeOut } from "#shared/theme";
import { useOnClickOutside } from "#hooks";
import useMeasure from "react-use-measure";
import { useMediaQuery } from "react-responsive";

const Container = styled.div`
  @media ${bp.m} {
    position: relative;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 70vh;
  overflow: auto;
  padding: 16px 10px;
  background-color: ${Color.White};
  border: 2px solid ${Color.Green};
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 0.0175em;
  color: ${Color.Black};
  line-height: 1.4;
  p + p {
    margin-top: 8px;
  }
  @media ${bp.m} {
    padding: 24px;
  }
`;

interface DropdownBoxProps {
  isOpen: boolean;
  isLeftSide: boolean;
  markerLeft: number;
}

const DropdownBox = styled.div<DropdownBoxProps>`
  width: calc(80% - 20px);
  position: absolute;
  top: 34px;
  right: 10px;
  ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)};
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: ${({ markerLeft }): string => `${markerLeft + 5}px`};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent #cffb5f transparent;
    @media ${bp.m} {
      left: ${({ isLeftSide }): string => (isLeftSide ? "auto" : "30px")};
      right: ${({ isLeftSide }): string => (isLeftSide ? "30px" : "auto")};
    }
  }
  @media ${bp.s} {
    right: 20px;
  }
  @media ${bp.m} {
    left: ${({ isLeftSide }): string => (isLeftSide ? "auto" : "calc(50% - 35px)")};
    right: ${({ isLeftSide }): string => (isLeftSide ? "calc(50% - 35px)" : "auto")};
    width: 326px;
  }
`;
const DropdownHandler = styled.div`
  cursor: pointer;
`;

export interface DropdownProps {
  content: string;
  buttonRef: RefObject<HTMLButtonElement>;
}

export const Dropdown: React.FC<DropdownProps> = ({ children, content, buttonRef }) => {
  const [markerLeft, setMarkerLeft] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [isLeftSide, setLeftSide] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [boxRef, { width, left: boxLeft }] = useMeasure();
  const [handlerRef, { right }] = useMeasure();
  const isDesktop = useMediaQuery({ query: bp.m });
  useEffect(() => {
    if (isDesktop) {
      setLeftSide(window.innerWidth <= right + width);
    } else {
      const buttonLeft = buttonRef.current?.getBoundingClientRect().left || 0;
      setMarkerLeft(buttonLeft - boxLeft);
    }
  }, [width, right, buttonRef, boxLeft, isDesktop]);

  useOnClickOutside(containerRef, () => {
    if (!isOpen) return;
    setOpen(false);
  });

  const handlerClick = () => {
    setOpen(!isOpen);
  };
  return (
    <Container ref={containerRef}>
      <DropdownHandler ref={handlerRef} onClick={handlerClick}>
        {children}
      </DropdownHandler>
      <DropdownBox ref={boxRef} isOpen={isOpen} isLeftSide={isLeftSide} markerLeft={markerLeft}>
        <Content dangerouslySetInnerHTML={{ __html: content }} />
      </DropdownBox>
    </Container>
  );
};
