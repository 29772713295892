export const footer = {
  topLinks: [
    {
      label: "BMW Group Bericht 2020 PDF",
      href: "#",
    },
    {
      label: "Übersicht Downloads",
      href: "#",
    },
    {
      label: "Glossar",
      href: "#",
    },
    {
      label: "Reminder Service",
      href: "#",
    },
  ],
  bottomLinks: [
    {
      label: "Legal disclaimer",
      href: "https://www.bmwgroup.com/en/general/legal-disclaimer.html",
    },
    {
      label: "Imprint",
      href: "https://www.bmwgroup.com/en/general/imprint.html",
    },
    {
      label: "Data Protection",
      href: "https://www.bmwgroup.com/en/general/data_privacy.html",
    },
  ],
};
