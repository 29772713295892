import { useFontLoader, useLoader } from "#hooks";
import { Color, expander, fadeOutFor } from "#shared/theme";
import React, { TransitionEvent, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { isReactSnap } from "src/constants";
import styled from "styled-components/macro";

export const Fallback = styled.div<{ isComplete?: boolean }>`
  ${expander}
  position: fixed;
  z-index: 10000;
  display: flex;
  padding-top: 100px;
  background-color: ${Color.BlueLoading};
  background-image: radial-gradient(circle at 60% 80%, rgb(0, 193, 233), rgb(37, 88, 130));
  ${({ isComplete }) => isComplete && navigator.userAgent !== "ReactSnap" && fadeOutFor(0.75)}
`;

type LoadingBarRef = {
  add(value: number): void;
  decrease(value: number): void;
  continuousStart(startingValue?: number, refreshRate?: number): void;
  staticStart(startingValue: number): void;
  complete(): void;
};

export const Loader: React.FC = () => {
  const lsInitiallyLoaded = localStorage.getItem("initiallyLoaded");
  const { isLoaded: isFontLoaded } = useFontLoader();
  const containerRef = useRef<HTMLDivElement>(null);
  const loadingBarRef = useRef<LoadingBarRef>(null);

  const { isLoading, isComplete, isDestroyed, complete, destroy } = useLoader();

  const handleLoaderFinished = () => {
    complete();
  };
  const handleFadeOut = (e: TransitionEvent<HTMLDivElement>) => {
    if (e.target !== containerRef.current) return;
    localStorage.setItem("initiallyLoaded", "true");
    destroy();
  };

  useEffect(() => {
    if (!isLoading || isDestroyed) return;
    if (!isFontLoaded) {
      loadingBarRef.current?.continuousStart(0, 5);
    } else {
      loadingBarRef.current?.complete();
    }
  }, [isDestroyed, isLoading, isFontLoaded, complete, lsInitiallyLoaded]);
  if (isDestroyed) return null;
  return (
    <Fallback isComplete={isComplete} onTransitionEnd={handleFadeOut} ref={containerRef}>
      <LoadingBar
        color={Color.Green}
        height={lsInitiallyLoaded || isReactSnap ? 0 : 5}
        ref={loadingBarRef}
        onLoaderFinished={handleLoaderFinished}
      />
    </Fallback>
  );
};
