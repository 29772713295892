import { bp, Color, expander } from "#shared/theme";
import React from "react";
import { Link } from "react-router-dom";
import { SlideshowProvider, useSlideshow } from "#providers";
import { NavButton } from "#ui";
import styled from "styled-components/macro";
import { Swiper } from "../Swiper/Swiper";
import { StartSlideshowNav } from "./StartSlideshowNav";

enum StartSlideshowTheme {
  BlueAccent = "BlueAccent",
  Blue = "Blue",
  GrayLightest = "GrayLightest",
}

const Container = styled.div`
  position: relative;
  padding-left: 50px;
`;

interface StartSlideshowItemProps {
  theme: StartSlideshowTheme;
  headline: string;
  image: {
    src: string;
  };
  link: {
    href: string;
    label: string;
  };
}

interface StartSlideshowProps {
  list: StartSlideshowItemProps[];
}

const Slides = styled.div`
  width: 230px;
  height: 430px;
  display: flex;
  position: relative;
  @media ${bp.t} {
    width: 276px;
    height: 516px;
  }
`;
const Item = styled.div`
  width: 100%;
  height: 100%;
`;
const Nav = styled.div`
  @media ${bp.m} {
  }
`;
const NavArrow = styled.div`
  @media ${bp.m} {
    ${expander}
    z-index: 1;
    height: 50px;
    margin: auto;
  }
`;
const NavPrev = styled(NavArrow)`
  transform: rotate(180deg);
  @media ${bp.m} {
    right: auto;
  }
`;
const Card = styled.div<{ theme: StartSlideshowTheme }>`
  ${expander}
  background-color: ${({ theme }) => Color[theme]};
  padding: 25px 20px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 18px;
  color: ${Color.Black};
  @media ${bp.t} {
    font-size: 18px;
    line-height: 21px;
  }
`;
const Headline = styled.h4`
  text-align: center;
  font-weight: normal;
`;
const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
const StyledLink = styled(Link)`
  border-bottom: 2px solid ${Color.Green};
  font-weight: bold;
  padding-bottom: 2px;
  &:hover {
    color: ${Color.Green};
  }
`;

const StartSlideshowItem: React.FC<StartSlideshowItemProps> = ({ theme, headline, image, link }) => {
  return (
    <Card theme={theme}>
      <Headline>{headline}</Headline>
      <Img src={image.src} draggable={false} />
      <StyledLink to={link.href}>{link.label}</StyledLink>
    </Card>
  );
};

const SlideList: React.FC<StartSlideshowProps> = ({ list }) => {
  const { next } = useSlideshow();
  const renderItem = (item: StartSlideshowItemProps, key: number) => {
    return (
      <Item key={key}>
        <StartSlideshowItem {...item} />
      </Item>
    );
  };
  return (
    <Container>
      <Slides>
        <Swiper<StartSlideshowItemProps> list={list} renderItem={renderItem} />
        <Nav>
          <StartSlideshowNav length={list.length} />
        </Nav>
      </Slides>
      <NavPrev>
        <NavButton onClick={next} />
      </NavPrev>
    </Container>
  );
};

export const StartSlideshow: React.FC<StartSlideshowProps> = ({ list }) => {
  return (
    <SlideshowProvider length={list.length}>
      <SlideList list={list} />
    </SlideshowProvider>
  );
};
