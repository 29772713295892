import { ButtonModal, ControlledLine } from "#components";
import { useTranslation } from "#hooks";
import { Figure, LinkIcon, TextAside } from "#ui";
import React from "react";

export const Dummy3: React.FC = () => {
  const { t: tHome } = useTranslation("home");
  const { copytext, link } = tHome("dummy3");
  return (
    <ControlledLine source={copytext} modal={link.modal}>
      {({ targetRef, render }) => (
        <TextAside content={render} withLine>
          <Figure>
            <ButtonModal list={link.modal}>
              {({ onClick }) => <LinkIcon targetRef={targetRef} onClick={onClick} {...link} />}
            </ButtonModal>
          </Figure>
        </TextAside>
      )}
    </ControlledLine>
  );
};
