export const tour = {
  ContractsChart: {
    title: "Neuverträge Finanzdienstleistungen",
    mainKPI: {
      label1: "",
      label2: "in units",
      maxValue: 5000000,
      currentValue: 2500000,
      prevValue: 2900000,
      diff: "17.1%",
    },
    leftKPI: {
      label1: "Credit Financing",
      label2: "in %",
      maxValue: 100,
      currentValue: 60,
      prevValue: 60,
      diff: "",
      rate: 0.6,
    },
    rightKPI_1: {
      label1: "Leasing",
      label2: "in %",
      maxValue: 100,
      currentValue: 40,
      prevValue: 40,
      diff: "",
      rate: 0.4,
    },
  },
  DeliveriesChart: {
    title: "Auslieferungen Automobile",
    tooltip:
      "Ein Neu- oder Gebrauchtfahrzeug wird als Auslieferung erfasst, wenn das Fahrzeug an einen Endkunden übergeben wird. Zu den Endkunden gehören auch Leasingnehmer im Rahmen von Leasingverträgen mit BMW Financial Services sowie in den USA und Kanada auch Händler, wenn sie ein Fahrzeug als Werkstattersatzfahrzeug oder Vorführfahrzeug klassifizieren. Im Falle von Gebrauchtfahrzeugen zählen zu den Endkunden auch Händler und andere Dritte, wenn sie ein Fahrzeug bei einer Auktion oder direkt von der BMW Group erwerben. Für den Endkunden vorgesehene Fahrzeuge, die auf dem Transportwege einen Totalschaden erleiden, werden auch als Auslieferung gezählt. Auslieferungen können durch die BMW AG, eine ihrer internationalen Tochtergesellschaften, eine BMW Group Niederlassung oder einen unabhängigen Händler erfolgen. Die überwiegende Mehrzahl der Auslieferungen und damit die Meldung einer Auslieferung an die BMW Group erfolgt durch unabhängige Händler. Auslieferungen von Fahrzeugen während eines Berichtszeitraumes stehen nicht in direktem Zusammenhang mit der Umsatzlegung der BMW Group im entsprechenden Berichtszeitraum.",
    bottomLink: {
      text: "Hinweis",
      to: "#",
    },
    mainKPI: {
      label1: "Total",
      label2: "in units",
      maxValue: 5000000,
      currentValue: 2500000,
      prevValue: 2900000,
      diff: "17.1%",
    },
    leftKPI: {
      label1: "BMW",
      label2: "in units",
      maxValue: 2000000,
      currentValue: 1300000,
      prevValue: 1500000,
      diff: "-17%",
      rate: 0.75,
    },
    rightKPI_1: {
      label1: "Rolls-Royce",
      label2: "in units",
      maxValue: 10000,
      currentValue: 5000,
      prevValue: 4000,
      diff: "1.0%",
      rate: 0.05,
    },
    rightKPI_2: {
      label1: "MINI",
      label2: "in units",
      maxValue: 1000000,
      currentValue: 347000,
      prevValue: 300000,
      diff: "3.0%",
      rate: 0.2,
    },
  },
  EBITMarginChart: {
    title: "EBIT-Marge Segment Automobile",
    tooltip: "Ergebnis vor Finanzergebnis bezogen auf die Umsatzerlöse.",
    margin: {
      label1: "EBIT margin",
      label2: "in %",
      maxValue: 10,
      maxMobileValue: 6,
      currentValue: 2.2,
      prevValue: 4.9,
      diff: "17.1% points",
      maxMargin: 3,
      minMargin: 0,
    },
    earnings: {
      label1: "Profit before financial result (EBIT) ",
      label2: "in € million",
      maxValue: 10000,
      currentValue: 7777,
      prevValue: 6499,
      diff: "17.1%",
    },
    sales: {
      label1: "Revenues",
      label2: "in € million",
      maxValue: 110000,
      currentValue: 99999,
      prevValue: 80682,
      diff: "20.1%",
    },
  },
  ElectricVehiclesChart: {
    title: "Auslieferungen elektrifizierte Fahrzeuge",
    mainKPI: {
      label1: "Electrified vehicles",
      label2: "in units",
      maxValue: 100000,
      currentValue: 66666,
      prevValue: 66000,
      diff: "17.1%",
    },
    leftKPI: {
      label1: "Fully electric vehicles",
      label2: "in units",
      maxValue: 100000,
      currentValue: 77777,
      prevValue: 45000,
      diff: "2.0%",
      rate: 0.75,
    },
    rightKPI_1: {
      label1: "Plug-in hybrids",
      label2: "in units",
      maxValue: 100000,
      currentValue: 33333,
      prevValue: 33000,
      diff: "2.0%",
      rate: 0.25,
    },
  },
  EmissionsChart: {
    title: "CO2 Emissionen je Fahrzeug in der Produktion und EU Flottenwert",
    tooltip:
      "Zur Berechnung der durchschnittlichen CO₂-Flottenemissionen eines Herstellers wird der gewichtete Mittelwert der CO₂-Emissionen über alle im Berichtszeitraum neu zugelassenen Fahrzeuge ermittelt. Zugrunde liegen das Volumen der Neuzulassungen eines Herstellers in der EU im Kalenderjahr sowie die einzelfahrzeugspezifischen CO₂-Emissionen, die nach dem WLTP-Typprüfverfahren ermittelt und auf den Neuen Europäischen Fahrzyklus (NEFZ) zurückgerechnet werden. Zusätzliche Effekte aus der Anrechnung von Öko-Innovationen sind nicht in der Angabe der Flottenemissionen 2019 berücksichtigt. Im Rahmen der Prognose für 2020 bezieht sich die Angabe auf die reine Reduzierung der CO₂-Flottenemissionen und nicht auf den CO₂-Flottengrenzwert, den die BMW Group zu erreichen hat. Das bedeutet, die Anrechnung von Supercredits, Phase-in und Öko-Innovationen ist nicht Bestandteil der Prognose.",
    value1: {
      label1: "CO2 emissions",
      label2: "per vehicle produced in t",
      maxValue: 1,
      currentValue: 0.34,
      prevValue: 0.25,
      diff: "2.5% points",
    },
    value2: {
      label1: "CO2 emissions",
      label2: "in the European new vehicle fleet",
      label3: "in g CO2/100km",
      labelMobile1: "CO2 emissions",
      labelMobile2: "in the European",
      labelMobile3: "new vehicle fleet",
      labelMobile4: "in g CO2 / 100km",
      maxValue: 100,
      currentValue: 30,
      prevValue: 20,
      diff: "2.5% points",
    },
  },
  GlobalMarketsChart: {
    title: "Die wichtigsten Automobilmärkte",
    labels: {
      america: "America*",
      asia: "Asia*",
      europe: "Europe*",
      other: "Others*",
      legend1: "* in %",
      legend2: "",
    },
    charts: {
      america: 25.0,
      europe: 25.0,
      asia: 25.0,
      other: 25.0,
    },
  },
  KeyFiguresChart: {
    title: "Konzernkennzahlen",
    tooltip: "EBIT zuzüglich Finanzergebnis.",
    mainKPI: {
      label1: "Group profit before tax",
      label2: "in € million",
      maxValue: 5000,
      currentValue: 3000,
      prevValue: 2900,
      diff: "17.1%",
    },
    leftKPI: {
      label1: "Group revenues",
      label2: "in € million",
      maxValue: 5000,
      currentValue: 2500,
      prevValue: 500,
      diff: "2.0%",
    },
    rightKPI_1: {
      label1: "Group net profit",
      label2: "in € million",
      maxValue: 1000,
      currentValue: 700,
      prevValue: 400,
      diff: "2.0%",
    },
  },
  MotoChart: {
    title: "Auslieferungen Motorräder",
    tooltip:
      "Ein Neu- oder Gebrauchtfahrzeug wird als Auslieferung erfasst, wenn das Fahrzeug an einen Endkunden übergeben wird. Zu den Endkunden gehören auch Leasingnehmer im Rahmen von Leasingverträgen mit BMW Financial Services sowie in den USA und Kanada auch Händler, wenn sie ein Fahrzeug als Werkstattersatzfahrzeug oder Vorführfahrzeug klassifizieren. Im Falle von Gebrauchtfahrzeugen zählen zu den Endkunden auch Händler und andere Dritte, wenn sie ein Fahrzeug bei einer Auktion oder direkt von der BMW Group erwerben. Für den Endkunden vorgesehene Fahrzeuge, die auf dem Transportwege einen Totalschaden erleiden, werden auch als Auslieferung gezählt. Auslieferungen können durch die BMW AG, eine ihrer internationalen Tochtergesellschaften, eine BMW Group Niederlassung oder einen unabhängigen Händler erfolgen. Die überwiegende Mehrzahl der Auslieferungen und damit die Meldung einer Auslieferung an die BMW Group erfolgt durch unabhängige Händler. Auslieferungen von Fahrzeugen während eines Berichtszeitraumes stehen nicht in direktem Zusammenhang mit der Umsatzlegung der BMW Group im entsprechenden Berichtszeitraum.",
    label1: "",
    label2: "in units",
    maxValue: 200000,
    currentValue: 135162,
    prevValue: 150400,
    diff: "17.1%",
  },
  ResearchChart: {
    title: "F&E Leistungen",
    tooltip:
      "Bestehen aus Forschungs- und nicht aktivierungsfähigen Entwicklungskosten sowie aus Investitionen in aktivierungspflichtige Entwicklungskosten ohne deren planmäßige Abschreibungen.",
    mainKPI: {
      label1: "R&D costs",
      label2: "in € million",
      maxValue: 10000,
      currentValue: 6400,
      prevValue: 6800,
      diff: "17.1%",
    },
    leftKPI: {
      label1_1: "R&D quote",
      label1_2: "in %",
      label2_1: "Strategic target framework",
      label2_2: "in %",
      maxValue: 7,
      maxMobileValue: 6,
      currentValue: 5.43,
      prevValue: 6,
      diff: "-17% points",
      frameworkMin: 5,
      frameworkMax: 5.5,
      frameworkValuesLabel: "5.0 - 5.5",
    },
  },
  ShareEarningsChart: {
    title: "Ergebnis je Aktie",
    tooltip:
      "Das Ergebnis je Aktie ergibt sich, indem für Stamm- und Vorzugsaktien das auf die jeweilige Aktiengattung entfallende Konzernergebnis nach Minderheitenanteilen auf die durchschnittliche Anzahl der jeweiligen Aktien bezogen wird. Beim Ergebnis je Vorzugsaktie werden nur die für das jeweilige Geschäftsjahr dividendenberechtigten Vorzugsaktien berücksichtigt.",
    label1_1: "Per share of",
    label1_2: "common stock in €",
    label2_1: "Per share of",
    label2_2: "preferred stock in €",
    maxValue: 10,
    currentValue1: 7.47,
    currentValue2: 7.49,
    prevValue: 6,
    valueLabel1: "7.47",
    valueLabel2: "7.49",
    diff1: "in € -29.5%",
    diff2: "in € -29.5%",
  },
  WomenChart: {
    title: "Frauenanteil in Führungspositionen in der BMW AG",
    label1: "Share of women",
    label2: "in %",
    maxValue: 100,
    currentValue: 32,
    prevValue: 28,
    diff: "17.1%",
  },
};
