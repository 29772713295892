export enum ColorName {
  Black = "Black",
  BlackLight = "BlackLight",
  White = "White",
  Red = "Red",
  Green = "Green",
  GreenDark = "GreenDark",
  GrayDark = "GrayDark",
  Gray = "Gray",
  GrayLight = "GrayLight",
  GrayLighten = "GrayLighten",
  GrayLighter = "GrayLighter",
  GrayLightest = "GrayLightest",
  Blue = "Blue",
  BlueSaturated = "BlueSaturated",
  BlueAccent = "BlueAccent",
  BlueBold = "BlueBold",
  BlueDark = "BlueDark",
  BlueLight = "BlueLight",
  BlueGray = "BlueGray",
  BlueBg = "BlueBg",
  BlueLoading = "BlueLoading",
}
export const Color: { [k: string]: string } = {
  [ColorName.Black]: "#000",
  [ColorName.BlackLight]: "#020204",
  [ColorName.White]: "#fff",
  [ColorName.Red]: "#e42110",
  [ColorName.Green]: "#CEFB5F",
  [ColorName.GreenDark]: "#346C64",
  [ColorName.GrayDark]: "#444444",
  [ColorName.Gray]: "#666666",
  [ColorName.GrayLight]: "#9C9C9C",
  [ColorName.GrayLighten]: "#D6D6D6",
  [ColorName.GrayLighter]: "#EEEEEE",
  [ColorName.GrayLightest]: "#F6F6F6",
  [ColorName.BlueBg]: "#1879A3",
  [ColorName.Blue]: "#2773B2",
  [ColorName.BlueSaturated]: "#006DB6",
  [ColorName.BlueBold]: "#1EBEE4",
  [ColorName.BlueAccent]: "#01ACD4",
  [ColorName.BlueDark]: "#1c517d",
  [ColorName.BlueLight]: "#41637C",
  [ColorName.BlueGray]: "#9ABED9",
  [ColorName.BlueLoading]: "#223347",
};
