import React from "react";
import { bp, font } from "#shared/theme";
import styled from "styled-components/macro";

const Container = styled.div`
  font-family: ${font.condensed};
  text-transform: uppercase;
  line-height: 18px;
  @media ${bp.d} {
    line-height: 22px;
  }
`;
const Headline = styled.div`
  font-size: 15px;
  font-weight: bold;
  @media ${bp.d} {
    font-size: 18px;
  }
`;
const Desc = styled.div`
  font-size: 13px;
  @media ${bp.d} {
    font-size: 16px;
  }
`;

export interface ImageAuthorProps {
  headline: string;
  desc: string;
}

export const ImageAuthor: React.FC<ImageAuthorProps> = ({ headline, desc }) => {
  return (
    <Container>
      <Headline>{headline}</Headline>
      <Desc>{desc}</Desc>
    </Container>
  );
};
