import React from "react";
import { Grid, Wrapper } from "#ui";
import { AudioPlayer, AudioPlayerProps } from "#components";
import { useTranslation } from "#hooks";
import styled from "styled-components/macro";
import { gridColumnStart, gridColumnWidth, bp } from "#shared/theme";

const Content = styled.div`
  ${gridColumnStart(1)}
  ${gridColumnWidth(12)}
  @media ${bp.s} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(10)}
  }
  @media ${bp.m} {
    ${gridColumnStart(4)}
    ${gridColumnWidth(18)}
  }
  @media ${bp.p} {
    ${gridColumnStart(5)}
    ${gridColumnWidth(16)}
  }
`;

export const Audio: React.FC = () => {
  const { t: tHome } = useTranslation("home");
  const audio = tHome("audio") as unknown;
  return (
    <Wrapper>
      <Grid>
        <Content>
          <AudioPlayer {...(audio as AudioPlayerProps)} />
        </Content>
      </Grid>
    </Wrapper>
  );
};
