import React from "react";
import { useMediaQuery } from "react-responsive";
import { bp } from "#shared/theme";
import { GlobalMarketsChartDesktop } from "../GlobalMarketsChartDesktop/GlobalMarketsChartDesktop";
import { GlobalMarketsChartMobile } from "../GlobalMarketsChartMobile/GlobalMarketsChartMobile";
import { useTranslation } from "#hooks";
import { Translations } from "#interfaces";
import { ChartContainer } from "../ChartContainer/ChartContainer";

export const GlobalMarketsChart: React.FC = () => {
  const { t } = useTranslation("tour");
  const labels = t("GlobalMarketsChart") as Translations;
  const isDesktop = useMediaQuery({ query: bp.m });
  return (
    <ChartContainer title={labels.title} tooltipContent={labels.tooltip}>
      {isDesktop ? <GlobalMarketsChartDesktop labels={labels} /> : <GlobalMarketsChartMobile labels={labels} />}
    </ChartContainer>
  );
};
