import React from "react";
import { useMediaQuery } from "react-responsive";
import { bp } from "#shared/theme";
import { EmissionsChartMobile } from "../EmissionsChartMobile/EmissionsChartMobile";
import { EmissionsChartDesktop } from "../EmissionsChartDesktop/EmissionsChartDesktop";
import { useTranslation } from "#hooks";
import { Translations } from "#interfaces";
import { ChartContainer } from "../ChartContainer/ChartContainer";

export const EmissionsChart: React.FC = () => {
  const { t } = useTranslation("tour");
  const labels = t("EmissionsChart") as Translations;
  const isDesktop = useMediaQuery({ query: bp.m });
  return (
    <ChartContainer title={labels.title} tooltipContent={labels.tooltip}>
      {isDesktop ? <EmissionsChartDesktop labels={labels} /> : <EmissionsChartMobile labels={labels} />}
    </ChartContainer>
  );
};
