import { useTranslation } from "#hooks";
import { bp, colsDesktop, font, gridColumnStart, gridColumnWidth } from "#shared/theme";
import { Grid, PreCaption, Wrapper } from "#ui";
import React from "react";
import styled from "styled-components/macro";

const Container = styled(Wrapper)``;

const Columns = styled.div`
  @media ${bp.m} {
    display: grid;
    grid-template-columns: repeat(${colsDesktop}, 1fr);
  }
`;
const List = styled.ul`
  margin: 40px 0 0;
  padding: 0;
  @media ${bp.m} {
    margin-top: 60px;
    ${gridColumnStart(3)}
    ${gridColumnWidth(20)}
    display: flex;
    justify-content: space-between;
  }
`;
const ListItem = styled.li`
  display: block;
  margin-top: 60px;
  @media ${bp.m} {
    margin-top: 0;
    flex: 0 0 137px;
  }
  @media ${bp.p} {
    flex: 0 0 190px;
  }
  @media ${bp.d} {
    flex: 0 0 240px;
  }
`;
const Result = styled(Grid)`
  @media ${bp.m} {
    display: block;
  }
`;
const Value = styled.div`
  font-size: 80px;
  line-height: 80px;
  text-align: right;
  padding-right: 15px;
  ${gridColumnStart(1)}
  ${gridColumnWidth(6)}
  @media ${bp.s} {
    font-size: 100px;
    line-height: 100px;
  }
  @media ${bp.m} {
    font-size: 80px;
    line-height: 140px;
    text-align: left;
  }
  @media ${bp.p} {
    font-size: 100px;
  }
  @media ${bp.d} {
    font-size: 120px;
  }
`;
const Meter = styled.span`
  font-size: 30px;
  @media ${bp.d} {
    font-size: 36px;
  }
`;
const Caption = styled.div`
  font-family: ${font.condensed};
  font-size: 13px;
  line-height: 19px;
  ${gridColumnStart(7)}
  ${gridColumnWidth(6)}
  em {
    font-style: normal;
    text-transform: uppercase;
  }
  strong {
    display: block;
  }
  @media ${bp.s} {
    ${gridColumnWidth(5)}
  }
  @media ${bp.d} {
    font-size: 16px;
    line-height: 24px;
  }
`;

interface ItemProps {
  value: string;
  meter: string;
  caption: string;
}

export const Results: React.FC = () => {
  const { t: tHome } = useTranslation("home");
  const { head, list } = tHome("results");
  const renderList = ({ value, meter, caption }: ItemProps, key: number) => (
    <ListItem key={key}>
      <Result>
        <Value>
          {value}
          <Meter>{meter}</Meter>
        </Value>
        <Caption dangerouslySetInnerHTML={{ __html: caption }} />
      </Result>
    </ListItem>
  );
  return (
    <Container>
      <PreCaption {...head} />
      <Columns>
        <List>{list.map(renderList)}</List>
      </Columns>
    </Container>
  );
};
