import React from "react";
import { bp } from "#shared/theme";
import styled from "styled-components/macro";
import { PdfViewer, PdfViewerProps } from "../PdfViewer/PdfViewer";
import { InfoCard, InfoCardProps } from "./InfoCard";

const Container = styled.div`
  display: flex;
  @media ${bp.m} {
    width: calc(100vw - 40px);
    max-width: 1280px;
    max-height: 832px;
  }
`;
interface InfoCardPdfProps extends InfoCardProps, PdfViewerProps {
  copytext: string;
}

export const InfoCardPdf: React.FC<InfoCardPdfProps> = ({ children, ...props }) => {
  return (
    <InfoCard>
      <Container>
        <PdfViewer {...props} />
      </Container>
    </InfoCard>
  );
};
