import React from "react";
import { GeneralPageLayout } from "#ui";
import { Root, ScrollHero } from "#components";
import { scrollHeroListDe } from "../../components/ScrollHero/ScrollHeroItems";

export const Diversity: React.FC = () => {
  return (
    <Root withHeader headerProps={{ transparent: true }}>
      <ScrollHero items={scrollHeroListDe[0]} />
      <GeneralPageLayout>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam assumenda atque beatae dolor eius est
          explicabo illum ipsam, iusto nostrum pariatur, recusandae sapiente suscipit tempora velit voluptate
          voluptates. Asperiores delectus doloribus eius ipsa iusto natus sed. Amet animi dolorum eaque eligendi itaque
          maiores modi, nulla officiis porro quidem totam veritatis? Asperiores atque consectetur corporis earum esse
          eum fuga non rem rerum, vitae. Accusantium asperiores corporis dicta eius est expedita fugit laboriosam, modi
          non quam qui quo repellat saepe tenetur veniam. Assumenda atque corporis error explicabo hic minima nam neque,
          nesciunt optio pariatur perferendis quaerat rem reprehenderit saepe vel voluptate voluptatibus! A accusantium
          aliquid architecto aspernatur consequatur dicta eaque et expedita explicabo facere fugit in labore laboriosam
          libero magnam magni modi mollitia natus neque nulla odit omnis praesentium, quaerat quas quibusdam, quidem
          quod saepe similique tempora tenetur ullam vitae voluptate voluptatum! Aperiam asperiores dolore
          exercitationem quaerat voluptatum. Autem dolorum fugit hic necessitatibus nulla porro reprehenderit saepe sint
          velit? Ab asperiores aspernatur at aut culpa dolore dolorum ducimus eaque earum eius eligendi enim, est et
          eveniet ex excepturi, fuga fugiat illum labore laudantium magnam magni maxime mollitia, neque nisi non odio
          officiis quae quasi quis sapiente veritatis voluptatem voluptatum? Ad adipisci alias blanditiis culpa cumque
          cupiditate debitis dignissimos dolorem doloremque eaque eos est et eum eveniet facilis, illo magnam
          necessitatibus odit quis, repellat. Aliquid aperiam aspernatur autem commodi debitis deserunt dicta doloribus,
          ducimus eius eos esse expedita hic id impedit, in iste labore magnam magni minima molestiae necessitatibus
          nulla pariatur perferendis praesentium quaerat quasi qui quos rem saepe, sed sit unde voluptate voluptatum. A
          aspernatur consequuntur dicta doloribus id nulla. Consequatur enim officia provident. Aliquam beatae cum
          cupiditate dolorum, eos eveniet facere labore obcaecati, perspiciatis porro quam quas recusandae sint?
          Doloremque, in inventore iste qui quibusdam quisquam rerum! Deserunt eius et, ipsum magnam omnis quia ratione
          ullam voluptatum! Aperiam asperiores assumenda ipsam iste molestiae nam omnis, sequi sint tempora tenetur. Ab
          autem eius expedita ipsum quos reiciendis rem rerum saepe tempore voluptas! Ab accusantium ad adipisci alias
          aliquam commodi consectetur consequatur cupiditate deserunt dignissimos, distinctio doloribus ea eos et eum
          explicabo harum impedit, ipsa iusto libero maiores minus nam nemo nobis officiis praesentium quam quibusdam
          quo ratione repellat reprehenderit sint suscipit tempore tenetur velit veritatis voluptatem! Ab amet, debitis
          eligendi fuga hic iure laboriosam magnam minima minus nostrum odit porro praesentium quaerat quo saepe
          sapiente, soluta sunt ullam. Alias aliquam autem culpa delectus deserunt eligendi explicabo facilis, iste
          iusto laudantium, maiores molestias quaerat qui quia quibusdam ratione sint, voluptate. Aut enim fugit nihil
          quibusdam quidem recusandae rem similique sunt. Accusamus, alias animi asperiores consequuntur cumque deserunt
          distinctio dolores doloribus error est eveniet ex exercitationem facere fugit illo, in incidunt ipsam iure
          labore laborum libero magnam magni molestiae neque nihil non odio praesentium qui quibusdam quisquam quos
          ratione saepe temporibus vel veritatis vero voluptatem. Amet assumenda exercitationem iste repellendus. Ab
          accusantium aliquam amet animi architecto aspernatur consequatur cupiditate, debitis delectus deleniti ea
          earum est fugit illo in inventore ipsum itaque maiores minima modi necessitatibus numquam omnis perspiciatis
          recusandae reiciendis repellat rerum sequi similique sit tempora unde voluptas voluptates voluptatum? Autem,
          consectetur consequatur cum dolor dolorem dolores doloribus esse est exercitationem inventore ipsum maiores
          maxime nisi officiis omnis optio quibusdam quo reprehenderit tempora unde? Ad assumenda modi nisi quis ratione
          saepe sint tempore! Ad aliquam aspernatur cupiditate, dicta dignissimos dolorem doloribus et ex, facere ipsam
          ipsum minus numquam officia officiis quas similique soluta temporibus voluptatum. Amet aperiam consectetur
          delectus distinctio dolores, et, eum ex excepturi explicabo facere in iste itaque nemo nesciunt nostrum omnis
          perspiciatis quae quibusdam quis reprehenderit soluta, sunt veniam! Atque aut corporis distinctio dolore
          eligendi enim eos error expedita incidunt libero minus molestias nihil nisi nobis non obcaecati officia optio
          pariatur placeat, provident quasi soluta suscipit tempora tenetur vel voluptas, voluptatibus. Animi autem
          consectetur consequuntur culpa dolorem doloremque eaque enim explicabo facere facilis fuga fugit incidunt
          ipsam iure labore laboriosam magnam magni maxime, nesciunt nihil nulla officiis pariatur perspiciatis
          praesentium provident repudiandae sequi sit soluta sunt totam ullam vel vero voluptatum. Accusantium adipisci
          aliquam architecto assumenda atque consequuntur cupiditate earum eius error eveniet excepturi explicabo
          facilis inventore, laborum magni modi molestias neque nesciunt nostrum numquam omnis porro, quae quasi quia
          repellat repudiandae temporibus tenetur velit veritatis voluptate! Ad adipisci animi corporis cupiditate
          deserunt dicta dolor dolorem doloribus earum et excepturi exercitationem explicabo, fugiat fugit harum illo,
          iusto neque nesciunt, nostrum officiis quas sit tempore totam veritatis voluptatem? Blanditiis corporis
          cupiditate dolore dolorum harum, labore laborum maiores modi natus nemo, nostrum optio quaerat sapiente sed
          tempora. Aliquam assumenda culpa dolorem, doloremque eum id impedit ipsam libero maxime, nesciunt nisi
          obcaecati quos, repellat rerum sapiente vel velit voluptatibus? Aliquid animi architecto atque corporis
          delectus doloribus earum enim et, ex facere facilis fugit harum id, inventore ipsa ipsam ipsum laudantium
          magni molestiae, molestias nemo neque non odio officia officiis omnis optio perferendis quasi qui reiciendis
          sed sit ullam vel veniam vero voluptas voluptate. Ab aperiam dicta distinctio dolor doloribus eaque explicabo,
          itaque laborum maiores modi molestiae nisi quasi, quos sunt voluptatibus. At, beatae doloremque ipsam iure
          magnam nam quia quidem veritatis. A aut commodi dolores explicabo fugit, inventore odit optio quasi recusandae
          saepe similique sit suscipit voluptas? At, atque commodi consequuntur doloremque, est excepturi laborum magnam
          magni maxime odio omnis porro quae quis reiciendis repudiandae! Accusantium architecto, cumque dolorum ducimus
          enim eos facere id in laudantium molestias mollitia nesciunt odit provident quaerat quia quibusdam quis
          repellendus repudiandae similique sint tempora veniam, voluptatibus? Accusamus consectetur cumque earum
          excepturi fugiat hic impedit libero, numquam quasi. Consectetur cum dolorem laboriosam mollitia omnis rerum
          sunt totam unde vitae voluptatum. Dicta ducimus error neque porro? Aperiam autem cumque dolorem ea earum
          eligendi est exercitationem expedita hic inventore ipsam, magni minus nulla perferendis, placeat possimus
          quibusdam quis recusandae repellendus similique tempora unde voluptas! Accusamus aliquid architecto assumenda
          aut consectetur corporis eius eligendi harum hic itaque laudantium magni nam necessitatibus officiis porro
          quam recusandae, repellendus ut, vitae voluptas? Accusamus atque autem blanditiis, consectetur deleniti
          distinctio doloremque, eius esse fugit inventore ipsa provident, voluptatum. Ab at ducimus eveniet inventore
          itaque nisi perferendis sequi, suscipit. Commodi delectus dolor eos eveniet, expedita incidunt minima
          molestiae numquam odit placeat quisquam ratione saepe sed vero voluptatum. A amet commodi consequatur
          consequuntur fugiat, impedit magni minus, neque nulla quo sed sequi sit, unde? Ab alias aliquam autem
          consectetur dolor, eius, est incidunt natus neque, non odio quas quibusdam ratione repudiandae sit temporibus
          ut voluptatem voluptatum. Ab animi aperiam asperiores, assumenda blanditiis consequatur consequuntur cumque
          dolor eaque eligendi enim excepturi facere hic incidunt iste itaque maxime officiis perferendis perspiciatis
          placeat porro possimus reiciendis repudiandae, saepe tempora temporibus veritatis? Aliquam aliquid amet
          corporis debitis dolores dolorum earum error esse excepturi, hic id iusto minus neque nihil praesentium, quam
          quod reiciendis sunt tempora tenetur ullam ut voluptatum. Ad aliquam aliquid at consequuntur corporis debitis
          deserunt dignissimos dolorem dolorum ea esse exercitationem facilis harum ipsam iste laboriosam laborum magnam
          natus nostrum officia officiis, provident quaerat ratione rem sed sint veniam voluptas! Cupiditate debitis
          fugiat nesciunt, nihil placeat porro rerum voluptatibus. At aut consequuntur error, et eum, excepturi ipsam
          laboriosam neque obcaecati officia provident sunt unde, vitae? Animi necessitatibus nemo quisquam vero.
          Consequatur impedit odit perspiciatis quia quod reiciendis vel velit voluptatem! Accusamus esse est eveniet,
          expedita laboriosam neque nisi quibusdam quis, rem repellendus reprehenderit ullam, veniam veritatis.
          Asperiores cumque delectus dolor doloribus eius eligendi facilis ipsa mollitia, non perferendis perspiciatis
          reiciendis repellat, voluptatum. Adipisci, alias, deserunt, dolore dolorem dolorum eum harum illo officiis sit
          suscipit ullam voluptatum. A, aliquam aliquid architecto asperiores atque autem beatae corporis dolorum
          ducimus eius error eum fugit iusto laboriosam magnam minima mollitia officiis possimus qui quos repellat
          sapiente sequi suscipit tenetur unde vel veniam veritatis. Accusamus amet aut, distinctio eius excepturi fugit
          ipsum magni maiores minima mollitia nihil nobis obcaecati odio odit quaerat repellat sit. Consectetur
          excepturi nesciunt quis recusandae. Accusamus alias aliquid assumenda at aut culpa dolores ea earum eius et
          eum ex excepturi fugiat harum incidunt, inventore iure magnam modi molestias mollitia natus neque non nostrum
          porro provident quidem sed sequi soluta suscipit tempora ullam voluptas voluptate voluptates? A ab adipisci,
          cumque eius exercitationem iusto minus numquam quibusdam quod vero. Ab, adipisci assumenda, atque deserunt
          dolores eligendi esse est eveniet fugiat fugit harum illum ipsa iste iusto laboriosam laudantium maxime modi
          molestias natus necessitatibus nostrum nulla numquam officiis optio perferendis praesentium quibusdam rerum
          sed similique sit tempora temporibus tenetur vel! Adipisci, aspernatur cupiditate delectus dolore esse et
          incidunt ipsum libero officia quaerat recusandae repellat sit voluptate. Beatae consectetur ducimus earum
          explicabo fuga hic in, incidunt minus, natus nesciunt quae quaerat quisquam sunt tempora veniam. Accusamus
          architecto assumenda autem consequatur, culpa dicta dignissimos eaque eius error et excepturi fugit impedit in
          ipsam iusto laudantium magni molestias necessitatibus neque nisi non nostrum nulla obcaecati officia officiis
          perferendis perspiciatis placeat provident quam quod repellendus sint sit tempora ut velit veniam vitae!
          Accusantium adipisci alias aliquid ducimus laborum, minima minus nam nisi pariatur perspiciatis praesentium
          quia recusandae reiciendis reprehenderit sequi sunt vel? Accusamus aliquid animi deleniti dolore error est
          exercitationem facilis fugit illum laboriosam libero mollitia obcaecati odit, officiis omnis pariatur
          perferendis perspiciatis quae qui quibusdam quisquam ratione reiciendis repellendus ullam vero? A commodi
          eligendi fuga iure laudantium. Aut beatae blanditiis consequatur minima officia quas reiciendis voluptate?
          Blanditiis eum in officiis omnis perferendis quia suscipit vero. Accusamus aliquam exercitationem facilis
          incidunt ipsam labore magni, minima nisi perspiciatis quibusdam quisquam, reprehenderit sit sunt suscipit
          totam? Ab blanditiis cum ducimus ea ex in laudantium mollitia, nesciunt, quam quia velit voluptatibus!
          Accusamus ad odit omnis possimus reiciendis repellat reprehenderit velit. Aliquam aspernatur autem consequatur
          corporis culpa earum eius est explicabo hic illo impedit ipsam ipsum iste iusto natus nemo nisi nostrum
          officia provident qui quod repudiandae sequi, sit tempora tempore temporibus unde. Aliquid dolor est harum
          ipsa labore modi quae ratione sequi totam voluptates. Adipisci animi, autem culpa dicta dolor earum est,
          explicabo id iste perferendis porro quam qui quia totam voluptatum. Ab autem delectus ea illo incidunt magnam
          magni maxime nesciunt, nihil nostrum odio officiis pariatur, quo repudiandae rerum sit, totam ut veritatis
          voluptates voluptatum. Accusamus adipisci asperiores aspernatur autem deleniti dolore dolores eos excepturi
          fugit impedit ipsam iusto nobis nulla obcaecati quaerat quasi quia repellat repellendus repudiandae rerum
          saepe sit ut, vero vitae voluptatum? Aliquid aperiam cupiditate dolor ipsa minus nemo nobis officia quo
          tempore veritatis? Delectus expedita itaque optio praesentium quaerat rerum. Accusantium ad aspernatur cum
          cumque dolor dolore ducimus, enim facilis fugit hic labore laborum nisi numquam officiis perferendis placeat
          porro praesentium quisquam quo reiciendis sequi vero voluptates voluptatibus. Aliquid assumenda beatae
          commodi, culpa doloremque dolores et eum exercitationem facilis hic itaque laboriosam laborum minima molestiae
          nam nulla quae quasi, qui quidem quis quos reiciendis rem repellat sint sit ullam unde veniam, veritatis
          voluptate voluptates? Aperiam assumenda deserunt distinctio doloremque ex neque nihil quod repellendus,
          tempore voluptatibus? Alias aspernatur beatae consequuntur debitis deserunt doloribus eaque eius excepturi
          itaque, iure iusto neque obcaecati optio quasi sapiente suscipit voluptas voluptates. A accusantium alias
          aliquid animi aperiam beatae consequatur consequuntur cum deleniti dignissimos eius esse eum excepturi
          explicabo, harum iusto laudantium libero molestiae molestias nostrum omnis provident quaerat quam quibusdam
          repellat sed vero. Dolores, magni maiores! Aperiam deleniti dignissimos earum eos eveniet excepturi
          exercitationem fugiat id ipsam iste modi molestias, necessitatibus pariatur perspiciatis possimus quaerat
          quidem quo repudiandae sit tempora velit veniam voluptate voluptates. Architecto, cum deserunt error explicabo
          incidunt inventore iure iusto minus nam ratione, repellat rerum sed, tempore! Accusamus aperiam at consequatur
          delectus deserunt illum nulla porro quibusdam ratione sapiente, soluta totam velit vitae? A commodi debitis
          doloribus eligendi error facere harum minima neque nihil perferendis porro, praesentium quaerat, quo
          recusandae repellat reprehenderit saepe, sunt unde vel voluptatum! Aliquid dicta dolor, doloribus dolorum,
          exercitationem illum iusto laborum libero maiores porro saepe sunt voluptas voluptatum. Aliquam architecto
          cumque cupiditate dicta dolorem eaque impedit maxime molestiae nobis officiis placeat possimus, quae qui quis
          reiciendis, sed tempore voluptates. Ab alias asperiores corporis cum delectus, eum inventore mollitia omnis,
          perspiciatis, quae quibusdam sed similique veritatis? Aspernatur distinctio eligendi excepturi fugit in libero
          maiores molestias nihil, odio, quas rem vel velit vero? Asperiores debitis eligendi eos ipsa quis quisquam,
          repellat saepe? Autem beatae eveniet obcaecati possimus sit? Accusantium aspernatur consectetur consequatur
          dignissimos dolorem dolorum earum eos expedita illo ipsum iusto labore laudantium libero maiores maxime nemo
          nihil non officia perferendis provident quam quas qui quia quibusdam quod quos repellat rerum sapiente
          similique, sint tenetur unde voluptatibus voluptatum. Alias atque cumque debitis doloremque eligendi enim eos
          excepturi illo impedit inventore labore laboriosam laudantium magni maxime natus nemo nihil odit officia optio
          placeat quae quasi quisquam, repellat sunt tenetur ullam vel voluptas. Dolorem expedita, explicabo?
        </div>
      </GeneralPageLayout>
    </Root>
  );
};
