import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { bp, bpTypes, clearBtn, fadeIn, fadeOut, IconKeys } from "#shared/theme";
import { Icon } from "#ui";
import styled, { css } from "styled-components/macro";
import { HEADER_HEIGHT } from "#constants";
import { useTranslation } from "#hooks";
import { PageName, PagesList } from "#interfaces";

const Container = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)};
  z-index: 8;
  position: fixed;
  right: 10px;
  margin-top: 20px;
  top: ${HEADER_HEIGHT[bpTypes.i]}px;
  @media ${bp.m} {
    margin-top: 40px;
    right: 20px;
    top: ${HEADER_HEIGHT[bpTypes.m]}px;
  }
  @media ${bp.t} {
    right: 36px;
  }
`;
const ToggleButton = styled.button`
  ${clearBtn}
  position: relative;
  height: 20px;
  width: 20px;
`;
const StyledIcon = styled(Icon)<{ isActive: boolean }>`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: rotate(0);
  transform-origin: 50% 50%;
  transition: transform ease-in-out 0.5s, opacity ease-in-out 0.5s;
  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
      transform: rotate(-180deg);
    `}; ;
`;

const Links = styled.div<{ isActive: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  transform-origin: 0 0;
  transform: scaleY(0);
  transition: transform ease-in-out 0.5s, opacity ease-in-out 0.5s;
  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
      transform: scaleY(1);
    `}; ;
`;

const Link = styled.a`
  display: block;
  height: 20px;
  width: 20px;
  margin: 32px 0;
`;

export const ShareLinks: React.FC = () => {
  const { t: tPages } = useTranslation("pages");
  const pagesList = tPages("list") as PagesList;
  const { pathname } = useLocation();
  const [isOpened, setOpened] = useState(false);
  const isStart = pathname === pagesList[PageName.start].path;
  const toggleOpen = () => {
    setOpened(!isOpened);
  };
  return (
    <Container isVisible={!isStart}>
      <ToggleButton onClick={toggleOpen}>
        <StyledIcon iconKey={IconKeys.SvgShare} isActive={!isOpened} />
        <StyledIcon iconKey={IconKeys.SvgClose} isActive={isOpened} />
      </ToggleButton>
      <Links isActive={isOpened}>
        <Link href="#">
          <Icon iconKey={IconKeys.SvgLinkedin} />
        </Link>
        <Link href="#">
          <Icon iconKey={IconKeys.SvgFacebook} />
        </Link>
        <Link href="#">
          <Icon iconKey={IconKeys.SvgTwitter} />
        </Link>
      </Links>
    </Container>
  );
};
