import React, { useRef } from "react";
import { useNumberFormat } from "#hooks";
import { SvgLines } from "./SvgLines";
import {
  VCPContent,
  VCPLeftContent,
  VCPValue,
  VCPLink,
  RightContent,
  VCPTurbo,
  VCPValueLabel,
  VCPValues,
  VCPValuesContent,
} from "#ui";
import { useSlideshow } from "#providers";
import { VCPItemProps } from "#interfaces";

export const Item5: React.FC<VCPItemProps> = ({ slideIndex, labels }) => {
  const { index, set } = useSlideshow();
  const turboSvgRef = useRef<SVGSVGElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const valuesInputRef = useRef<HTMLDivElement>(null);
  const valuesOutputRef = useRef<HTMLDivElement>(null);
  const inputDefaultRef = useRef<SVGPathElement>(null);
  const inputFillRef1 = useRef<SVGPathElement>(null);
  const inputFillRef2 = useRef<SVGPathElement>(null);
  const outputDefaultRef = useRef<SVGPathElement>(null);
  const outputFillRef1 = useRef<SVGPathElement>(null);
  const outputFillRef2 = useRef<SVGPathElement>(null);
  const isActive = index === slideIndex;
  const handleClick = () => {
    set(slideIndex === index ? -1 : slideIndex);
  };

  return (
    <SvgLines
      {...{
        turboSvgRef,
        contentRef,
        inputDefaultRef,
        inputFillRef1,
        inputFillRef2,
        outputDefaultRef,
        outputFillRef1,
        outputFillRef2,
        valuesInputRef,
        valuesOutputRef,
        isActive,
      }}
    >
      {({
        valueInputRef1,
        valueInputRef2,
        valueOutputRef1,
        valueOutputRef2,
        turboInputRef,
        turboOutputRef,
        pathInputLength,
        pathOutputLength,
        valuesContentRef,
      }) => (
        <>
          <VCPContent ref={contentRef}>
            <VCPLeftContent>
              <VCPLink onClick={handleClick} isActive={isActive}>
                {labels?.title1}
              </VCPLink>
              <VCPValues ref={valuesInputRef}>
                <VCPValuesContent ref={valuesContentRef}>
                  <VCPValue ref={valueInputRef1}>
                    <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.inputLabel1.toString() || "" }} />
                    {useNumberFormat(Number(labels?.inputValue1))}
                  </VCPValue>
                  <VCPValue ref={valueInputRef2}>
                    <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.inputLabel2.toString() || "" }} />
                    {useNumberFormat(Number(labels?.inputValue2))}
                  </VCPValue>
                </VCPValuesContent>
              </VCPValues>
            </VCPLeftContent>
            <RightContent>
              <VCPLink onClick={handleClick} isActive={isActive}>
                {labels?.title2}
              </VCPLink>
              <VCPValues ref={valuesOutputRef}>
                <VCPValuesContent>
                  <VCPValue ref={valueOutputRef1}>
                    <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.outputLabel1.toString() || "" }} />
                    {useNumberFormat(Number(labels?.outputValue1))}
                  </VCPValue>
                  <VCPValue ref={valueOutputRef2}>
                    <VCPValueLabel dangerouslySetInnerHTML={{ __html: labels?.outputLabel2.toString() || "" }} />
                    {useNumberFormat(Number(labels?.outputValue2))}
                  </VCPValue>
                </VCPValuesContent>
              </VCPValues>
            </RightContent>
          </VCPContent>
          <VCPTurbo ref={turboSvgRef} viewBox="0 0 409 406" xmlns="http://www.w3.org/2000/svg" opacity="0">
            <g>
              <path
                ref={turboInputRef}
                fill="none"
                stroke="#000000"
                strokeWidth="0"
                d="M1.5,287h203c46.392,0,84-37.608,84-84s-37.608-84-84-84"
              />

              <path
                ref={inputDefaultRef}
                strokeDasharray={`0 ${pathInputLength}`}
                fill="none"
                stroke="#000000"
                strokeWidth="10"
                strokeMiterlimit="10"
                d="M1.5,287h203c46.392,0,84-37.608,84-84s-37.608-84-84-84"
              />

              <path
                ref={inputFillRef1}
                strokeDasharray={`0 ${pathInputLength}`}
                fill="none"
                stroke="#346C64"
                strokeWidth="10"
                strokeMiterlimit="10"
                d="   M1.5,287h203c46.392,0,84-37.608,84-84s-37.608-84-84-84"
              />

              <path
                ref={inputFillRef2}
                strokeDasharray={`0 ${pathInputLength}`}
                fill="none"
                stroke="#1EBEE4"
                strokeWidth="2"
                strokeMiterlimit="10"
                d="   M1.5,287h203c46.392,0,84-37.608,84-84s-37.608-84-84-84"
              />

              <path
                ref={turboOutputRef}
                fill="none"
                stroke="#FFFFFF"
                strokeWidth="0"
                d="M407.5,119h-203c-46.392,0-84,37.608-84,84s37.608,84,84,84"
              />

              <path
                ref={outputDefaultRef}
                strokeDashoffset={pathOutputLength}
                strokeDasharray={`${pathOutputLength} ${pathOutputLength}`}
                mask="url(#_x36_-pathes_1_)"
                fill="none"
                stroke="#FFFFFF"
                strokeWidth="10"
                strokeMiterlimit="10"
                d="   M407.5,119h-203c-46.392,0-84,37.608-84,84s37.608,84,84,84"
              />

              <path
                ref={outputFillRef1}
                strokeDashoffset={pathOutputLength}
                strokeDasharray={`${pathOutputLength} ${pathOutputLength}`}
                mask="url(#_x36_-pathes_1_)"
                fill="none"
                stroke="#CEFB5F"
                strokeWidth="10"
                strokeMiterlimit="10"
                d="   M407.5,119h-203c-46.392,0-84,37.608-84,84s37.608,84,84,84"
              />

              <path
                ref={outputFillRef2}
                strokeDashoffset={pathOutputLength}
                strokeDasharray={`${pathOutputLength} ${pathOutputLength}`}
                mask="url(#_x36_-pathes_1_)"
                fill="none"
                stroke="#1EBEE4"
                strokeWidth="2"
                strokeMiterlimit="10"
                d="   M407.5,119h-203c-46.392,0-84,37.608-84,84s37.608,84,84,84"
              />
            </g>
          </VCPTurbo>
        </>
      )}
    </SvgLines>
  );
};
