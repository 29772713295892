import { ColorName, iconsList, IconType } from "#shared/theme";
import React from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components/macro";

export enum IconSize {
  Default = "Default",
  Middle = "Middle",
  Large = "Large",
}

export const IconSizeByType: { [i: string]: number } = {
  [IconSize.Default]: 16,
  [IconSize.Middle]: 24,
  [IconSize.Large]: 34,
};

export interface IconProps {
  iconKey: IconType;
  color?: ColorName;
  className?: string;
  size?: IconSize;
}

const FilteredIcon: React.FC<IconProps> = ({ iconKey, className }) => {
  return <ReactSVG src={iconsList[iconKey]} className={className} wrapper="span" />;
};

export const Icon = styled(FilteredIcon)<IconProps>`
  width: ${({ size = IconSize.Large }) => IconSizeByType[size]}px;
  height: ${({ size = IconSize.Large }) => IconSizeByType[size]}px;
  display: inline;
  white-space: nowrap;
  &::before {
    content: "\\65279";
    font-size: 0;
    line-height: 0;
  }
  span {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
