import { ContextMenu, Root, Webgl, SvgAnimation } from "#components";
import { isReactSnap, TextColorThemeTypes } from "#constants";
import { useTranslation } from "#hooks";
import { PageName, PagesList, SlideshowItemProps } from "#interfaces";
import { TourProvider } from "#providers";
import { expander } from "#shared/theme";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import React, { useEffect, useRef, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import styled from "styled-components/macro";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const ScrollContainer = styled.div`
  height: 100%;
`;

const GradientContainer = styled.div`
  ${expander}
  position: fixed;
  z-index: -2;
  background-image: radial-gradient(circle at 60% 80%, rgb(0, 193, 233), rgb(0, 109, 182));
`;
const GradientAnimation: React.FC<{ startPathname: string }> = ({ startPathname }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const [isInitiated, setIsInitiated] = useState<boolean>(false);

  useEffect(() => {
    if (pathname !== startPathname || isInitiated) return;

    gsap.fromTo(
      divRef.current,
      {
        backgroundImage: "radial-gradient(circle at 60% 80%,rgb(0, 193, 233), rgb(0, 109, 182))",
      },
      {
        backgroundImage: "radial-gradient(circle at 60% 80%, rgb(0, 109, 182), rgb(0, 109, 182))",
        ease: "power3.in",
        scrollTrigger: {
          trigger: "#scroll",
          //   pin: true,
          start: "top top",
          end: "bottom bottom",
          scrub: 1,
        },
      }
    );
    setIsInitiated(true);
  }, [pathname, startPathname, isInitiated]);
  return <GradientContainer ref={divRef}></GradientContainer>;
};

const { pathname, hash } = location;

export const Home: React.FC = ({ children }) => {
  const headerProps = {
    title: "BMW Group Bericht 2020",
    transparent: true,
    withNav: true,
  };
  const { t } = useTranslation("menu");
  const { t: tPages } = useTranslation("pages");
  const pagesList = tPages("list") as PagesList;
  const { list: tourMenuList } = t("tour");
  const tourList = tourMenuList[0].list;
  const defaultIndex = tourList.findIndex((item: SlideshowItemProps) => item.to === `${pathname}${hash}`);
  if (pathname === pagesList[PageName.guidedTour2020].path && defaultIndex === -1) {
    return <Redirect to={tourList[0].to} />;
  }
  return (
    <Root withHeader headerProps={headerProps} theme={TextColorThemeTypes.Light}>
      <GradientAnimation startPathname={pagesList[PageName.intro].path} />
      <SvgAnimation startPathname={pagesList[PageName.intro].path} />
      {!isReactSnap && <Webgl startPathname={pagesList[PageName.intro].path} />}
      <ScrollContainer id="scroll">
        <TourProvider length={tourList.length} defaultIndex={defaultIndex}>
          <ContextMenu />
          {children}
        </TourProvider>
      </ScrollContainer>
    </Root>
  );
};
