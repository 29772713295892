import { HEADER_HEIGHT, TextColorThemeTypes, ThemeKeys } from "#constants";
import { bp, bpTypes, Color, expander, font, IconKeys } from "#shared/theme";
import { Icon, Link, Wrapper } from "#ui";
import React from "react";
import { useTranslation } from "src/hooks";
import { PageName, Translations } from "src/interfaces";
import styled, { css } from "styled-components/macro";
import theme from "styled-theming";
import { Nav } from "../Nav/Nav";

const Logo = styled(Icon)`
  width: 100%;
  height: 100%;
  display: block;
  ${theme(ThemeKeys.textColor, {
    [TextColorThemeTypes.Default]: css`
      fill: ${Color.Black};
    `,
    [TextColorThemeTypes.Light]: css`
      fill: ${Color.White};
    `,
  })}
`;

const BrandLink = styled(Link)`
  width: 54px;
  height: 24px;
  display: flex;
  align-items: center;
  text-decoration: none;
  @media ${bp.s} {
    width: 63px;
    height: 28px;
  }
  @media ${bp.m} {
    width: 72px;
    height: 32px;
  }
`;

interface HeaderContainerProps {
  transparent: boolean;
}

const HeaderContainer = styled.header<HeaderContainerProps>`
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ transparent }): string => (transparent ? "transparent" : Color.White)};
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT[bpTypes.i]}px;
  @media ${bp.m} {
    height: ${HEADER_HEIGHT[bpTypes.m]}px;
  }
  ${theme(ThemeKeys.textColor, {
    [TextColorThemeTypes.Default]: css`
      color: ${Color.Black};
    `,
    [TextColorThemeTypes.Light]: css`
      color: ${Color.White};
    `,
  })}
`;

const HeaderTitle = styled.div`
  z-index: -1;
  ${expander}
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: ${font.condensed};
  font-weight: bold;
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: 0.067em;
  @media ${bp.m} {
    font-size: 13px;
  }
`;

export interface HeaderProps {
  title?: string;
  transparent?: boolean;
  withNav?: boolean;
}

export const Header: React.FC<HeaderProps> = ({ title, transparent = false, withNav = false }) => {
  const { t: tPages } = useTranslation("pages");
  const pagesList = tPages("list") as Translations;
  const { path } = pagesList[PageName.start];
  return (
    <>
      <HeaderContainer transparent={transparent}>
        <Wrapper>
          <BrandLink to={path}>
            <Logo iconKey={IconKeys.SvgLogo} />
          </BrandLink>
          <HeaderTitle>{title}</HeaderTitle>
        </Wrapper>
      </HeaderContainer>
      <Nav withNav={withNav} />
    </>
  );
};
