export const tour = {
  ContractsChart: {
    title: "New contracts retail customer financing, financial services",
    mainKPI: {
      label1: "",
      label2: "in units",
      maxValue: 5000000,
      currentValue: 2500000,
      prevValue: 2900000,
      diff: "17.1%",
    },
    leftKPI: {
      label1: "Credit Financing",
      label2: "in %",
      maxValue: 100,
      currentValue: 60,
      prevValue: 60,
      diff: "",
      rate: 0.6,
    },
    rightKPI_1: {
      label1: "Leasing",
      label2: "in %",
      maxValue: 100,
      currentValue: 40,
      prevValue: 40,
      diff: "",
      rate: 0.4,
    },
  },
  DeliveriesChart: {
    title: "Automobile deliveries",
    tooltip:
      "A new or used vehicle will be recorded as a delivery once handed over to the end user. End users also include leaseholders under lease contracts with BMW Financial Services and – in the US and Canada – dealers when they designate a vehicle as a service loaner or demonstrator vehicle. In the case of used vehicles, end users include dealers and other third parties when they purchase a vehicle at auction or directly from BMW Group. Vehicles designated for the end user and suffering total loss in transit will also be recorded as deliveries. Deliveries may be made by BMW AG, one of its international subsidiaries, a BMW Group retail outlet, or independent third party dealers. The vast majority of deliveries – and hence the reporting to BMW Group of deliveries – is made by independent third party dealers. Retail vehicle deliveries during a given reporting period do not correlate directly to the revenue that BMW Group recognises in respect of such reporting period.",
    bottomLink: {
      text: "Hinweis",
      to: "#",
    },
    mainKPI: {
      label1: "Total",
      label2: "in units",
      maxValue: 5000000,
      currentValue: 2500000,
      prevValue: 2900000,
      diff: "17.1%",
    },
    leftKPI: {
      label1: "BMW",
      label2: "in units",
      maxValue: 2000000,
      currentValue: 1300000,
      prevValue: 1500000,
      diff: "-17%",
      rate: 0.75,
    },
    rightKPI_1: {
      label1: "Rolls-Royce",
      label2: "in units",
      maxValue: 10000,
      currentValue: 5000,
      prevValue: 4000,
      diff: "1.0%",
      rate: 0.05,
    },
    rightKPI_2: {
      label1: "MINI",
      label2: "in units",
      maxValue: 1000000,
      currentValue: 347000,
      prevValue: 300000,
      diff: "3.0%",
      rate: 0.2,
    },
  },
  EBITMarginChart: {
    title: "EBIT margin automotive segment",
    tooltip: "Profit/loss before financial result as a percentage of revenues.",
    margin: {
      label1: "EBIT margin",
      label2: "in %",
      maxValue: 10,
      maxMobileValue: 6,
      currentValue: 2.2,
      prevValue: 4.9,
      diff: "17.1% points",
      maxMargin: 3,
      minMargin: 0,
    },
    earnings: {
      label1: "Profit before financial result (EBIT) ",
      label2: "in € million",
      maxValue: 10000,
      currentValue: 7777,
      prevValue: 6499,
      diff: "17.1%",
    },
    sales: {
      label1: "Revenues",
      label2: "in € million",
      maxValue: 110000,
      currentValue: 99999,
      prevValue: 80682,
      diff: "20.1%",
    },
  },
  ElectricVehiclesChart: {
    title: "Deliveries of electrified vehicles",
    mainKPI: {
      label1: "Electrified vehicles",
      label2: "in units",
      maxValue: 100000,
      currentValue: 66666,
      prevValue: 66000,
      diff: "17.1%",
    },
    leftKPI: {
      label1: "Fully electric vehicles",
      label2: "in units",
      maxValue: 100000,
      currentValue: 77777,
      prevValue: 45000,
      diff: "2.0%",
      rate: 0.75,
    },
    rightKPI_1: {
      label1: "Plug-in hybrids",
      label2: "in units",
      maxValue: 100000,
      currentValue: 33333,
      prevValue: 33000,
      diff: "2.0%",
      rate: 0.25,
    },
  },
  EmissionsChart: {
    title: "CO2 emissions per vehicle in production and EU fleet value",
    tooltip:
      "The calculation of average CO₂ fleet emissions of a manufacturer is reported through the weighted average of CO₂ emissions by all vehicles newly registered in the reporting period. The calculation is based on the volume of new registrations of the manufacturer in the EU in the calendar year as well as the individual vehicle-specific CO₂ emissions, which have been calculated based on the WLTP test cycle and adapted to the New European Driving Cycle (NEDC). Additional effects from the recognition of eco-innovations are not taken into consideration in the disclosure of fleet emissions for 2019. For the 2020 forecast, the disclosure relates purely to a reduction of the CO₂ fleet emissions and not the CO₂ fleet limit, which the BMW Group needs to achieve.This means that the recognition of super credits, phase-in and eco-innovations is not part of the forecast.",
    value1: {
      label1: "CO2 emissions",
      label2: "per vehicle produced in t",
      maxValue: 1,
      currentValue: 0.34,
      prevValue: 0.25,
      diff: "2.5% points",
    },
    value2: {
      label1: "CO2 emissions",
      label2: "in the European new vehicle fleet",
      label3: "in g CO2/100km",
      labelMobile1: "CO2 emissions",
      labelMobile2: "in the European",
      labelMobile3: "new vehicle fleet",
      labelMobile4: "in g CO2 / 100km",
      maxValue: 100,
      currentValue: 30,
      prevValue: 20,
      diff: "2.5% points",
    },
  },
  GlobalMarketsChart: {
    title: "Key automobile markets",
    labels: {
      america: "America*",
      asia: "Asia*",
      europe: "Europe*",
      other: "Others*",
      legend1: "* in %",
      legend2: "",
    },
    charts: {
      america: 25.0,
      europe: 25.0,
      asia: 25.0,
      other: 25.0,
    },
  },
  KeyFiguresChart: {
    title: "Group key figures",
    tooltip: "EBIT plus financial result.",
    mainKPI: {
      label1: "Group profit before tax",
      label2: "in € million",
      maxValue: 5000,
      currentValue: 3000,
      prevValue: 2900,
      diff: "17.1%",
    },
    leftKPI: {
      label1: "Group revenues",
      label2: "in € million",
      maxValue: 5000,
      currentValue: 2500,
      prevValue: 500,
      diff: "2.0%",
    },
    rightKPI_1: {
      label1: "Group net profit",
      label2: "in € million",
      maxValue: 1000,
      currentValue: 700,
      prevValue: 400,
      diff: "2.0%",
    },
  },
  MotoChart: {
    title: "Motorcycles deliveries",
    tooltip:
      "A new or used vehicle will be recorded as a delivery once handed over to the end user. End users also include leaseholders under lease contracts with BMW Financial Services and – in the US and Canada – dealers when they designate a vehicle as a service loaner or demonstrator vehicle. In the case of used vehicles, end users include dealers and other third parties when they purchase a vehicle at auction or directly from BMW Group. Vehicles designated for the end user and suffering total loss in transit will also be recorded as deliveries. Deliveries may be made by BMW AG, one of its international subsidiaries, a BMW Group retail outlet, or independent third party dealers. The vast majority of deliveries – and hence the reporting to BMW Group of deliveries – is made by independent third party dealers. Retail vehicle deliveries during a given reporting period do not correlate directly to the revenue that BMW Group recognises in respect of such reporting period.",
    label1: "",
    label2: "in units",
    maxValue: 200000,
    currentValue: 135162,
    prevValue: 150400,
    diff: "17.1%",
  },
  ResearchChart: {
    title: "Research and development expenditure",
    tooltip:
      "The sum of research and non-capitalised development cost and capitalised development cost (not including the associated scheduled amortisation).",
    mainKPI: {
      label1: "R&D costs",
      label2: "in € million",
      maxValue: 10000,
      currentValue: 6400,
      prevValue: 6800,
      diff: "17.1%",
    },
    leftKPI: {
      label1_1: "R&D quote",
      label1_2: "in %",
      label2_1: "Strategic target framework",
      label2_2: "in %",
      maxValue: 7,
      maxMobileValue: 6,
      currentValue: 5.43,
      prevValue: 6,
      diff: "-17% points",
      frameworkMin: 5,
      frameworkMax: 5.5,
      frameworkValuesLabel: "5.0 - 5.5",
    },
  },
  ShareEarningsChart: {
    title: "Earnings per share",
    tooltip:
      "Basic earnings per share are calculated for common and preferred stock by dividing the net profit after minority interests, as attributable to each category of stock, by the average number of shares in circulation. Earnings per share of preferred stock are computed on the basis of the number of preferred stock shares entitled to receive a dividend in each of the relevant financial years.",
    label1_1: "Per share of",
    label1_2: "common stock in €",
    label2_1: "Per share of",
    label2_2: "preferred stock in €",
    maxValue: 10,
    currentValue1: 7.47,
    currentValue2: 7.49,
    prevValue: 6,
    valueLabel1: "7.47",
    valueLabel2: "7.49",
    diff1: "in € -29.5%",
    diff2: "in € -29.5%",
  },
  WomenChart: {
    title: "Share of women in management positions at BMW AG",
    label1: "Share of women",
    label2: "in %",
    maxValue: 100,
    currentValue: 32,
    prevValue: 28,
    diff: "17.1%",
  },
};
