import { normalize } from "polished";
import { createGlobalStyle } from "styled-components/macro";
import { Color, defTransition, font, fontFace } from "#shared/theme";
import "overlayscrollbars/css/OverlayScrollbars.css";

const loadFonts = (): void => {
  const head = document.getElementsByTagName("head")[0];
  const style = document.createElement("style");
  style.innerHTML = fontFace;
  head.appendChild(style);
};
loadFonts();

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  * {
    box-sizing: border-box;
    ::selection {
      background-color: ${Color.Green};
    }
  }
  html {
    min-height: 100%;
    display: flex;
    overflow-y: scroll;
    overscroll-behavior: none;
  }
  body, .sb-show-main.sb-main-fullscreen {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-family: ${font.serif};
    color: ${Color.Black};
    line-height: 1.5;
    font-size: 16px;
    flex: 1 0 0;
    max-width: 100%;
    display: flex;
    background-color: ${Color.White};
    background-attachment: fixed;
  }
  a, input, button {
    outline: none;
    color: inherit;
    text-decoration: inherit;
  }
  a {
    transition: color ${defTransition};
  }
  #root {
    flex: 1 0 0%;
    max-width: 100%;
    display: flex;
    justify-content: stretch;
  }
  h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
  }
  p + p {
    margin-top: 1em;
  }
`;
