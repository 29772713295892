import { useTranslation } from "#hooks";
import { bp, Color, font, IconKeys } from "#shared/theme";
import { ButtonCircle, Icon, Wrapper } from "#ui";
import htmr from "htmr";
import React from "react";
// import { useInView } from "react-intersection-observer";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { useTour } from "#providers";

const Container = styled.div`
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Headline = styled.h3`
  font-size: 28px;
  line-height: 28px;
  font-weight: normal;
  color: ${Color.Black};
  @media ${bp.m} {
    font-size: 38px;
    line-height: 41px;
  }
`;
const Subheadline = styled.div`
  font-size: 22px;
  line-height: 28px;
  margin-top: 1em;
  @media ${bp.m} {
    font-size: 31px;
    line-height: 41px;
  }
`;
const Buttons = styled.ul`
  margin: 90px 0 0;
  padding: 0;
`;
const ButtonsItem = styled.li`
  display: block;
  position: relative;
  & + & {
    margin-top: 30px;
  }
`;
const ButtonLabel = styled.span`
  position: absolute;
  right: 100%;
  white-space: nowrap;
  margin-right: 30px;
  text-align: right;
  font-family: ${font.condensed};
  color: ${Color.Green};
  font-size: 15px;
  line-height: 17px;
  strong {
    text-transform: uppercase;
    display: block;
  }
`;

export const GuidedTour: React.FC = () => {
  const { t } = useTranslation();
  const { t: tMenu } = useTranslation("menu");
  const { t: tHome } = useTranslation("home");
  const { headline, subheadline } = tHome("guidedtour");
  const { set, play } = useTour();
  const history = useHistory();
  const { list: tourMenuList } = tMenu("tour");
  const tourList = tourMenuList[0].list;
  const go = () => {
    history.push(tourList[0].to);
    set(0);
  };
  const startAutoplay = () => {
    go();
    play();
  };
  //   const [ref, inView] = useInView();
  return (
    <Wrapper>
      <Container>
        <Headline>{headline}</Headline>
        <Subheadline>{htmr(subheadline)}</Subheadline>
        <Buttons>
          <ButtonsItem>
            <ButtonLabel>{htmr(t("autoplay"))}</ButtonLabel>
            <ButtonCircle onClick={startAutoplay}>
              <Icon iconKey={IconKeys.SvgPlay} />
            </ButtonCircle>
          </ButtonsItem>
          <ButtonsItem>
            <ButtonCircle onClick={go}>
              <Icon iconKey={IconKeys.SvgArrowRight} />
            </ButtonCircle>
          </ButtonsItem>
        </Buttons>
      </Container>
    </Wrapper>
  );
};
