import { useFullscreen, useTranslation } from "#hooks";
import { bp, Color, expander, font, IconKeys, IconType } from "#shared/theme";
import { Icon, Pdf, Scrollbars } from "#ui";
import React, { MouseEvent } from "react";
import styled from "styled-components/macro";
import { Fullscreen } from "../Fullscreen/Fullscreen";

const Container = styled.div`
  overflow: hidden;
  flex: 1 0 0%;
  display: flex;
  width: 262px;
`;
const Aside = styled.div`
  padding: 30px 15px 120px;
  flex: 0 0 262px;
  font-size: 13px;
  line-height: 1.23;
  padding-right: 10px;
  @media ${bp.m} {
    flex: 0 0 16%;
  }
`;
const Title = styled.h2`
  font-size: inherit;
  font-family: ${font.condensed};
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  margin-bottom: 30px;
`;
const Type = styled(Title)`
  margin: 20px 0;
`;
const Headline = styled.h3`
  font-size: inherit;
  font-weight: bold;
  line-height: 1.5;
  margin: 10px 0;
`;
const Chapter = styled(Headline)``;
const Pages = styled.div``;
const Main = styled.div<{ isOpen: boolean }>`
  flex: 1 0 0%;
  overflow: hidden;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  @media ${bp.m} {
    display: block;
    position: relative;
  }
`;
const MainInner = styled.div`
  ${expander}
  display: flex;
  background-color: ${Color.GrayLighten};
`;
const Content = styled.div`
  flex: 1 0 auto;
  max-width: 100%;
  width: 100%;
  background-color: ${Color.GrayLighter};
  padding: 0 5px;
`;
const Footer = styled.div`
  margin-top: 30px;
`;

const Item = styled.div`
  & + & {
    margin-top: 10px;
  }
`;
const StyledLink = styled.a`
  display: inline-flex;
  cursor: pointer;
  font-weight: bold;
`;

const LinkIcon = styled(Icon)`
  flex: 0 0 20px;
  height: 20px;
`;

const LinkLabel = styled.span`
  margin-left: 5px;
  border-bottom: 2px solid ${Color.Green};
`;

interface LinkProps {
  href?: string;
  download?: boolean;
  onClick?: () => void;
  iconKey: IconType;
}

const Link: React.FC<LinkProps> = ({ href, download, onClick, iconKey, children }) => {
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (!onClick) return;
    e.preventDefault();
    onClick();
  };
  return (
    <StyledLink onClick={handleClick} download={download} href={href}>
      <LinkIcon iconKey={iconKey} />
      <LinkLabel>{children}</LinkLabel>
    </StyledLink>
  );
};

export interface PdfViewerProps {
  src: string;
  chapter: string;
  headline: string;
  pages: number;
}

export const PdfViewer: React.FC<PdfViewerProps> = ({ src, chapter, headline, pages }) => {
  const { t } = useTranslation();
  const handle = useFullscreen();
  const { enter, active } = handle;
  return (
    <Container>
      <Aside>
        <Title>{t("BMW GROUPBERICHT 2020")}</Title>
        <Type as={"div"}>PDF</Type>
        <Chapter>{chapter}</Chapter>
        <Headline>{headline}</Headline>
        <Pages>
          {t("Page")}: {pages}
        </Pages>
        <Footer>
          <Item>
            <Link href={src} download iconKey={IconKeys.SvgDownload}>
              {t("Download")}
            </Link>
          </Item>
          <Item>
            <Link onClick={enter} iconKey={IconKeys.SvgFullscreen}>
              {t("Fullscreen")}
            </Link>
          </Item>
        </Footer>
      </Aside>
      <Main isOpen={active}>
        <Fullscreen {...handle}>
          <MainInner>
            <Scrollbars thickOffset>
              <Content>
                <Pdf src={src} />
              </Content>
            </Scrollbars>
          </MainInner>
        </Fullscreen>
      </Main>
    </Container>
  );
};
