export const bpTypes = {
  i: "i",
  s: "s",
  m: "m",
  p: "p",
  t: "t",
  l: "l",
  d: "d",
  h: "h",
};
export const screenSize = {
  [bpTypes.i]: 0,
  [bpTypes.s]: 375,
  [bpTypes.m]: 720,
  [bpTypes.p]: 960,
  [bpTypes.t]: 1248,
  [bpTypes.l]: 1350,
  [bpTypes.d]: 1600,
  [bpTypes.h]: 1920,
};

export const minWidth = (size: number): string => `(min-width: ${size}px)`;
export const maxWidth = (size: number): string => `(max-width: ${size - 1}px)`;
export const maxHeight = (size: number): string => `(max-height: ${size - 1}px)`;

const bp = {} as { [key: string]: string };

for (const [name, value] of Object.entries(screenSize)) {
  bp[name] = minWidth(value);
}

export { bp };

export const maxWrapperWidth = 1524;
