import { useOnClickOutside } from "#hooks";
import { ModalContainer, useDarkOverlay, useScrollLock } from "#providers";
import { expander, fadeIn, fadeOut } from "#shared/theme";
import { ModalCloseButton, Scrollbars } from "#ui";
import React, { TransitionEvent, useContext, useEffect, useRef, useState } from "react";
import { Portal } from "react-portal";
import styled from "styled-components/macro";

const Container = styled.div<{ isOpen: boolean }>`
  ${expander}
  position: fixed;
  display: flex;
  z-index: 999;
  ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)}
`;

const Box = styled.div`
  position: relative;
  display: flex;
  margin: auto;
  height: 100%;
  max-height: 832px;
`;

export interface ModalProps {
  close: () => void;
  reset: () => void;
  isOpen: boolean;
}
export const Modal: React.FC<ModalProps> = ({ children, close, reset, isOpen }) => {
  const { modalContainerRef } = useContext(ModalContainer);
  const { open, close: closeOverlay, reset: resetOverlay } = useDarkOverlay();
  const [isMount, setMount] = useState(false);
  const boxRef = useRef(null);
  const containerRef = useRef(null);
  const handleCloseClick = () => {
    close();
    closeOverlay();
  };
  const handleTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
    if (e.target !== containerRef.current || isOpen) return;
    reset();
    resetOverlay();
  };
  const { disableScrolling, enableScrolling } = useScrollLock();
  useOnClickOutside(boxRef, close);
  useEffect(() => {
    if (isMount) return;
    setMount(true);
    open();
  }, [isMount, open]);
  useEffect(() => {
    disableScrolling();
    return () => {
      enableScrolling();
    };
  }, [disableScrolling, enableScrolling]);
  return (
    <Portal node={modalContainerRef?.current}>
      <Container ref={containerRef} isOpen={isOpen} onTransitionEnd={handleTransitionEnd}>
        <Scrollbars centerContent style={{ padding: "10px" }}>
          <Box ref={boxRef}>{children}</Box>
        </Scrollbars>
        <ModalCloseButton onClick={handleCloseClick} />
      </Container>
    </Portal>
  );
};
