import React from "react";
import styled from "styled-components/macro";
import { Color, font, bp } from "#shared/theme";
import { useTranslation } from "#hooks";

const Container = styled.footer`
  background-color: ${Color.White};
  padding: 28px;
  color: ${Color.Black};
  font-family: ${font.condensed};
  letter-spacing: 0.018em;
  position: relative;
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  & + & {
    margin-top: 12px;
    @media ${bp.m} {
      margin-top: 0;
    }
  }
`;

const ItemLink = styled.a<{ isSmall?: boolean }>`
  border-bottom: 1px solid ${Color.Black};
  margin: ${({ isSmall }): string => (isSmall ? "8px 4px" : "8px")};
  line-height: ${({ isSmall }): string => (isSmall ? "1.43" : "1.67")};
  font-size: ${({ isSmall }): string => (isSmall ? "12px" : "14px")};
`;

export interface FooterProps {
  disableFirstLine?: boolean;
}

interface LinkItem {
  label: string;
  href: string;
}

export const Footer: React.FC<FooterProps> = ({ disableFirstLine }) => {
  const { t } = useTranslation("footer");
  const topItems = t("topLinks") as LinkItem[];
  const bottomItems = t("bottomLinks") as LinkItem[];
  return (
    <Container>
      {!disableFirstLine && !!topItems.length && (
        <Links>
          {topItems.map((item) => (
            <ItemLink href={item.href} key={item.label}>
              {item.label}
            </ItemLink>
          ))}
        </Links>
      )}
      {bottomItems.length && (
        <Links>
          {bottomItems.map((item) => (
            <ItemLink href={item.href} key={item.label} isSmall target="_blank">
              {item.label}
            </ItemLink>
          ))}
        </Links>
      )}
    </Container>
  );
};
