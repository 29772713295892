import React, { useState } from "react";
import styled from "styled-components/macro";
import { Root, VCPChart } from "#components";
import { Color, fadeIn, fadeOut, IconKeys } from "#shared/theme";
import { ButtonCircle, Icon } from "#ui";
import { VCPIntro } from "./VCPIntro";
import { gsap } from "gsap";
import { Power3 } from "gsap/gsap-core";

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  padding: 96px 0;
  position: relative;
`;

const PageContainer = styled.div<{ isVisible?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)};
`;

const InfoButtonContainer = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 36px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const VCPPage: React.FC = () => {
  const [isVCPActive, setVCPActive] = useState(false);
  const headerProps = {
    title: "BMW Group Bericht 2020",
    transparent: true,
    withNav: true,
  };
  const openVCPChart = () => {
    gsap.to(window, {
      duration: 0.3,
      scrollTo: { y: 0 },
      ease: Power3.easeInOut,
      onComplete: () => {
        setVCPActive(true);
      },
    });
  };
  return (
    <Root style={{ backgroundColor: Color.BlueBold }} withHeader headerProps={headerProps}>
      <Main>
        <PageContainer isVisible={!isVCPActive}>
          <VCPIntro openVCP={openVCPChart} />
        </PageContainer>
        {isVCPActive && (
          <PageContainer isVisible={true}>
            <VCPChart />
          </PageContainer>
        )}
        <InfoButtonContainer>
          <ButtonCircle onClick={openVCPChart}>
            <Icon iconKey={IconKeys.SvgArrowRight} />
          </ButtonCircle>
        </InfoButtonContainer>
      </Main>
    </Root>
  );
};
