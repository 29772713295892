import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { gsap, Linear } from "gsap";
import { ChartPropsMobile } from "#interfaces";
import { Power3 } from "gsap/gsap-core";
import { chartReverseDuration } from "#constants";

interface ChartAnimationProps extends ChartPropsMobile {
  maxManualValue?: number;
  currentValue: number;
  prevValue: number;
  currentPathRef: RefObject<SVGLineElement>;
  prevPathRef: RefObject<SVGLineElement>;
  negativePathRef: RefObject<SVGLineElement>;
  lineRef: RefObject<SVGPolylineElement>;
  topLineRef: RefObject<SVGLineElement>;
  contentRef: RefObject<SVGGElement>;
  animationDuration: number;
  animationDelay?: number;
  fullHeight?: number;
  mainBottomY?: number;
}

type useChartAnimationMobile = (chartArguments: ChartAnimationProps) => number;

const setPoints = (points: string, value: number): string => {
  return points?.replace(/\d+(\ \d+,)\d+$/, `${value - 0.5}$1${value - 0.5}`);
};

const gap = 2;
const extraNegativeDuration = 0.3;

export const useChartAnimationMobile: useChartAnimationMobile = ({
  playIn,
  playOut,
  onPlayOutEnd,
  maxManualValue,
  currentValue,
  prevValue,
  currentRate = 1,
  prevRate = 0,
  contentRef,
  currentPathRef,
  prevPathRef,
  negativePathRef,
  lineRef,
  topLineRef,
  animationDuration,
  animationDelay = 0,
  fullHeight = 215,
  mainBottomY = 324,
}) => {
  const [pathLength, setPathLength] = useState(0);
  const totalHeight = Math.round(fullHeight * currentRate);
  const bottomY = mainBottomY - Math.round(fullHeight * prevRate);
  const isPositive = currentValue >= prevValue;
  const maxValue = maxManualValue ? maxManualValue : isPositive ? currentValue : prevValue;
  const currentPercent = Number(((100 / maxValue) * currentValue).toFixed(1));
  const prevPercent = Number(((100 / maxValue) * prevValue).toFixed(1));
  const percentageDiff = Math.abs(currentPercent - prevPercent);
  const duration = animationDuration * currentRate;
  const delay = animationDuration * prevRate + animationDelay;
  const prevLineDuration = isPositive ? duration * (1 - percentageDiff / currentPercent) : duration;
  const currentLineDuration = duration * (percentageDiff / currentPercent);
  const prevLineY = bottomY - Math.round((totalHeight * prevPercent) / 100);
  const currentLineY = bottomY - Math.round((totalHeight * currentPercent) / 100);
  const onReverseEnd = useRef(onPlayOutEnd);
  const tl = useMemo(
    () => gsap.timeline({ defaults: { ease: Linear.easeInOut, force3D: true, willChange: "transform" }, paused: true }),
    []
  );
  const tlReverse = useMemo(
    () =>
      gsap.timeline({
        defaults: { ease: Power3.easeOut, force3D: true, willChange: "transform", duration: chartReverseDuration },
        paused: true,
        onComplete: () => {
          onReverseEnd.current?.();
        },
      }),
    []
  );

  useEffect(() => {
    tl.set(lineRef.current, { opacity: 0 });
    setTimeout(() => {
      const points = lineRef.current?.getAttribute("points") || "";
      tl.set(lineRef.current, {
        attr: { points: setPoints(points, isPositive ? bottomY - totalHeight : currentLineY) },
        onComplete: () => {
          setPathLength(Math.round(lineRef.current?.getTotalLength() || 0));
        },
      });
    }, 200);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!pathLength) return;
    tl.set(contentRef.current, { opacity: 0, y: 10 })
      .set(topLineRef.current, { opacity: 0, attr: { y1: bottomY - gap, y2: bottomY - gap - 1 } })
      .fromTo(
        [prevPathRef.current, negativePathRef.current],
        { attr: { y1: bottomY - gap, y2: bottomY - gap } },
        { duration: prevLineDuration, delay, attr: { y2: prevLineY } }
      );
    if (!isPositive) {
      tl.set(topLineRef.current, { opacity: 1 }, delay)
        .to(topLineRef.current, { duration: prevLineDuration, delay, attr: { y1: prevLineY, y2: prevLineY - 1 } }, 0)
        .to(
          topLineRef.current,
          {
            duration: currentLineDuration + extraNegativeDuration,
            delay: delay + prevLineDuration,
            attr: { y1: currentLineY, y2: currentLineY - 1 },
          },
          0
        )
        .to(
          prevPathRef.current,
          {
            duration: currentLineDuration + extraNegativeDuration,
            delay: delay + prevLineDuration,
            attr: { y2: currentLineY },
          },
          0
        );
      tlReverse.to(prevPathRef.current, { attr: { y1: bottomY - gap, y2: bottomY - gap } }, 0);
    } else {
      tl.set(topLineRef.current, { opacity: 1 }, delay)
        .set(currentPathRef.current, { attr: { y1: prevLineY, y2: prevLineY } }, delay)
        .to(
          topLineRef.current,
          { duration: duration, delay, attr: { y1: bottomY - totalHeight, y2: bottomY - totalHeight - 1 } },
          0
        )
        .to(
          currentPathRef.current,
          { duration: currentLineDuration, attr: { y2: bottomY - totalHeight } },
          delay + prevLineDuration
        );
      tlReverse.to(currentPathRef.current, { attr: { y1: prevLineY, y2: prevLineY } }, 0);
    }
    tl.set(lineRef.current, { opacity: 1 })
      .to(lineRef.current, { strokeDasharray: pathLength, strokeDashoffset: pathLength * 2, duration: 0.6 })
      .to(contentRef.current, { opacity: 1, y: 0, duration: 0.2 });

    tlReverse
      .to(contentRef.current, { opacity: 0, y: 10 }, 0)
      .to(topLineRef.current, { attr: { y1: bottomY - gap, y2: bottomY - gap - 1 } }, 0)
      .to([negativePathRef.current, prevPathRef.current, currentPathRef.current, topLineRef.current], { opacity: 0 }, 0)
      .to(lineRef.current, { strokeDasharray: pathLength, strokeDashoffset: pathLength, opacity: 0 }, 0);
  }, [pathLength]); // eslint-disable-line

  useEffect(() => {
    if (playIn) {
      tlReverse.restart().pause();
      tl.restart();
    }
    if (playOut) {
      tl.pause();
      tlReverse.restart();
    }
  }, [playIn, playOut]); // eslint-disable-line

  return pathLength;
};
