import styled from "styled-components/macro";
import { bp, Color, defTransition, font, gridColumnWidth } from "#shared/theme";

export const VCPContainer = styled.div`
  width: 100%;
  height: 100%;
  ${gridColumnWidth(24)}
`;

export const VCPItems = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const VCPTurbo = styled.svg`
  font-family: ${font.condensed};
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: 280px;
  @media ${bp.m} {
    width: 288px;
    height: 285px;
  }
  @media ${bp.p} {
    width: 409px;
    height: 406px;
  }
  @media ${bp.d} {
    width: 492px;
    height: 486px;
  }
`;

export const VCPContent = styled.div`
  opacity: 0;
  display: flex;
  justify-content: space-between;
  line-height: 1.2;
`;

export const VCPValues = styled.div`
  height: 0;
  overflow: hidden;
  @media ${bp.m} {
    margin-bottom: 20px;
  }
`;

export const VCPValuesContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VCPValue = styled.div`
  margin-bottom: 28px;
  max-width: 88px;
  font-size: 28px;
  @media ${bp.m} {
    font-size: 50px;
    max-width: 156px;
  }
`;

export const VCPValueLabel = styled.div`
  font-size: 10px;
  font-family: ${font.condensed};
  @media ${bp.m} {
    font-size: 14px;
  }
`;

export const VCPLink = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  font-size: 14px;
  font-family: ${font.condensed};
  margin-bottom: 28px;
  position: relative;
  display: inline-block;
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: ${Color.GreenDark};
    height: ${({ isActive }): string => (isActive ? "4px" : "2px")};
    transition: height ${defTransition};
  }
  @media ${bp.m} {
    font-size: 18px;
  }
`;

export const RightContent = styled.div`
  text-align: right;
  color: ${Color.White};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${VCPLink}:after {
    background-color: ${Color.Green};
  }
`;
export const VCPLeftContent = styled.div``;
