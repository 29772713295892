import { bp, Color, font, gridColumnStart, gridColumnWidth } from "#shared/theme";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components/macro";
import { Grid } from "../Grid/Grid";

const Container = styled.figure`
  margin: 0;
`;
const Frame = styled.div`
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 34px ${rgba(Color.Black, 0.26)};
  width: 133px;
  height: 133px;
  position: relative;
  @media ${bp.t} {
    width: 190px;
    height: 190px;
  }
  @media ${bp.d} {
    width: 241px;
    height: 241px;
  }
`;
const Img = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
const Content = styled.div`
  margin-top: 35px;
  ${gridColumnStart(2)}
  ${gridColumnWidth(8)}
  @media ${bp.s} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(7)}
  }
  @media ${bp.m} {
    ${gridColumnStart(2)}
    ${gridColumnWidth(5)}
  }
`;
const Caption = styled.figcaption`
  font-family: ${font.condensed};
  text-transform: uppercase;
  font-size: 13px;
  line-height: 18px;
  @media ${bp.d} {
    font-size: 16px;
    line-height: 22px;
  }
  p + p {
    margin-top: 1em;
  }
  a {
    font-size: 15px;
    color: ${Color.Green};
    font-weight: bold;
    @media ${bp.d} {
      font-size: 18px;
    }
  }
  strong {
    color: ${Color.Green};
    font-size: 15px;
    font-weight: bold;
    @media ${bp.d} {
      font-size: 18px;
    }
  }
`;
export interface FigureProps {
  image?: {
    src: string;
  };
  caption?: string;
}

export const Figure: React.FC<FigureProps> = ({ image, caption, children }) => {
  return (
    <Container>
      {image?.src && (
        <Frame>
          <Img src={image.src} />
        </Frame>
      )}
      <Grid columns={[10, 7]}>
        <Content>
          {caption && (
            <>
              <Caption dangerouslySetInnerHTML={{ __html: caption }} />
              {children}
            </>
          )}
        </Content>
      </Grid>
      {!caption && children}
    </Container>
  );
};
