import { useGalleryItemState } from "#providers";
import { bp, clearBtn, Color, defTransition, expander } from "#shared/theme";
import { rgba } from "polished";
import React, { useRef, useState } from "react";
import { useDrag } from "react-use-gesture";
import styled, { css } from "styled-components/macro";
import { CardProps } from "./CardProps";

const Container = styled.div`
  perspective: 500vw;
  user-select: none;
  padding-top: 30px;
  @media ${bp.m}, (orientation: landscape) {
    perspective: 300vw;
    padding: 0;
  }
`;
const List = styled.div<{ isFlipped: boolean }>`
  position: relative;
  will-change: transform;
  transition: transform 0.4s;
  transform-style: preserve-3d;
  transform: rotateY(${({ isFlipped }) => (isFlipped ? "-180deg" : "0deg")});
  padding-bottom: 149.2957746478873%;
  @media ${bp.m}, (orientation: landscape) {
    padding-bottom: 53.198127925117%;
  }
`;
const Item = styled.div<{ isBack: boolean }>`
  ${expander}
  border-radius: 4.478873239436619% / 3%;
  overflow: hidden;
  will-change: transform;
  backface-visibility: hidden;
  transform: rotateY(${({ isBack }) => (isBack ? "-180deg" : "0deg")});
  @media ${bp.m}, (orientation: landscape) {
    box-shadow: 0 0 35px ${rgba(Color.Black, 0.26)};
    border-radius: 3.19188767550702% / 6%;
  }
`;
const Img = styled.div<{ desktop?: boolean }>`
  width: 100%;
  height: 100%;
  background-color: ${Color.White};
  svg {
    height: 100%;
    width: 100%;
    font-weight: bold;
  }
  ${({ desktop = false }) =>
    desktop
      ? css`
          display: none;
          @media ${bp.m}, (orientation: landscape) {
            display: block;
          }
        `
      : css`
          @media ${bp.m}, (orientation: landscape) {
            display: none;
          }
        `}
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media ${bp.m} {
    position: absolute;
    top: 100%;
    left: 35px;
  }
`;
const NavItemButton = styled.button`
  ${clearBtn}
  padding: 25px 15px;
  display: flex;
  & + & {
    border-left: 2px dashed ${Color.White};
  }
  @media ${bp.m}, (orientation: landscape) {
    padding: 15px 5px;
  }
`;
const NavThumb = styled.div<{ isActive: boolean }>`
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid ${({ isActive }) => (isActive ? Color.Green : Color.White)};
  transition: border ${defTransition};
`;
const Thumb = styled.img<{ isActive: boolean }>`
  display: block;
  width: 60px;
  height: 44px;
  object-fit: cover;
  object-position: center;
  opacity: ${({ isActive }) => (!isActive ? 0.3 : 1)};
  transition: opacity ${defTransition};
  @media ${bp.m} {
    width: 50px;
    height: 38px;
  }
`;

export interface FlipCardSideProps {
  thumb?: { src: string };
  images: React.FC<CardProps>[];
  isBack?: boolean;
}
interface FlipCardNavItemProps {
  isActive: boolean;
  onClick: () => void;
  src: string;
}

const NavItem: React.FC<FlipCardNavItemProps> = ({ isActive, onClick, src }) => {
  return (
    <NavItemButton>
      <NavThumb isActive={isActive} onClick={onClick}>
        <Thumb isActive={isActive} src={src} draggable={false} />
      </NavThumb>
    </NavItemButton>
  );
};

interface FlipCardNavProps {
  list: FlipCardSideProps[];
  flip: () => void;
  unflip: () => void;
  isFlipped: boolean;
}

const Nav: React.FC<FlipCardNavProps> = ({ list, flip, unflip, isFlipped }) => {
  return (
    <NavContainer>
      {list[0].thumb && <NavItem onClick={unflip} isActive={!isFlipped} src={list[0].thumb.src} />}
      {list[1].thumb && <NavItem onClick={flip} isActive={isFlipped} src={list[1].thumb.src} />}
    </NavContainer>
  );
};

const FlipCardSide: React.FC<FlipCardSideProps & { isFlipped: boolean }> = ({ images, isBack = false, isFlipped }) => {
  const { isActive } = useGalleryItemState();
  const SvgMobile = images[0];
  const SvgDesktop = images[1];
  const motionProps = {
    initial: false,
    animate: !isActive ? "exit" : isBack !== isFlipped ? "exit" : "enter",
  };
  return (
    <Item isBack={isBack}>
      <Img draggable={false}>
        <SvgMobile motionProps={motionProps} />
      </Img>
      <Img draggable={false} desktop>
        <SvgDesktop motionProps={motionProps} />
      </Img>
    </Item>
  );
};

export interface FlipCardProps {
  list: FlipCardSideProps[];
}

export const FlipCard: React.FC<FlipCardProps> = ({ list }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFlipped, setFlipped] = useState(false);
  const flip = () => {
    setFlipped(true);
  };
  const unflip = () => {
    setFlipped(false);
  };
  const bind = useDrag(
    ({ active, direction: [xDir], distance, cancel, event }) => {
      const containerWidth = containerRef.current?.getBoundingClientRect().width || 0;
      const nextIndex = xDir > 0 ? -1 : 1;
      const isOutOfRange = nextIndex > 1 || nextIndex < -1;
      if (isOutOfRange) {
        event.stopPropagation();
      }
      if (active && distance > containerWidth / 10) {
        xDir > 0 ? unflip() : flip();
        cancel();
      }
    },
    { axis: "x", experimental_preventWindowScrollY: true, filterTaps: true }
  );
  return (
    <Container ref={containerRef}>
      <List isFlipped={isFlipped} {...bind()}>
        <FlipCardSide images={list[0].images} isFlipped={isFlipped} />
        <FlipCardSide images={list[1].images} isFlipped={isFlipped} isBack />
      </List>
      <Nav list={list} flip={flip} unflip={unflip} isFlipped={isFlipped} />
    </Container>
  );
};
