import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { gsap, Linear } from "gsap";
import { ChartProps } from "#interfaces";
import { Power3 } from "gsap/gsap-core";
import { chartReverseDuration } from "#constants";

interface ChartAnimationProps extends ChartProps {
  maxValue: number;
  currentValue: number;
  currentPathRef: RefObject<SVGLineElement>;
  lineRef: RefObject<SVGPolylineElement>;
  topLineRef: RefObject<SVGLineElement>;
  contentRef: RefObject<SVGGElement>;
  animationDuration: number;
}

type useMarketsChartAnimationMobile = (chartArguments: ChartAnimationProps) => number;

const setPoints = (points: string, value: number): string => {
  return points?.replace(/\d+(\ \d+,)\d+$/, `${value - 0.5}$1${value - 0.5}`);
};

const fullHeight = 215;
const bottomY = 324;

export const useMarketsChartAnimationMobile: useMarketsChartAnimationMobile = ({
  playIn,
  playOut,
  onPlayOutEnd,
  currentValue,
  maxValue,
  contentRef,
  currentPathRef,
  lineRef,
  topLineRef,
  animationDuration,
}) => {
  const [pathLength, setPathLength] = useState(0);
  const rate = currentValue / maxValue;
  const duration = animationDuration * rate;
  const currentLineY = bottomY - Math.round(fullHeight * rate);
  const onReverseEnd = useRef(onPlayOutEnd);
  const tl = useMemo(
    () => gsap.timeline({ defaults: { ease: Linear.easeInOut, force3D: true, willChange: "transform" }, paused: true }),
    []
  );
  const tlReverse = useMemo(
    () =>
      gsap.timeline({
        defaults: { ease: Power3.easeOut, force3D: true, willChange: "transform", duration: chartReverseDuration },
        paused: true,
        onComplete: () => {
          onReverseEnd.current?.();
        },
      }),
    []
  );

  useEffect(() => {
    tl.set(lineRef.current, { opacity: 0 });
    setTimeout(() => {
      const points = lineRef.current?.getAttribute("points") || "";
      tl.set(lineRef.current, {
        attr: { points: setPoints(points, currentLineY) },
        onComplete: () => {
          setPathLength(Math.round(lineRef.current?.getTotalLength() || 0));
        },
      });
    }, 200);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!pathLength) return;
    tl.set(topLineRef.current, { attr: { y1: bottomY, y2: bottomY - 1 } })
      .fromTo(
        currentPathRef.current,
        { attr: { y1: bottomY, y2: bottomY } },
        { duration: duration, attr: { y2: currentLineY } }
      )
      .to(topLineRef.current, { duration: duration, attr: { y1: currentLineY, y2: currentLineY - 1 } }, 0)
      .set(lineRef.current, { opacity: 1 })
      .to(lineRef.current, { strokeDasharray: pathLength, strokeDashoffset: pathLength * 2, duration: 0.6 })
      .to(contentRef.current, { opacity: 1, y: 0, duration: 0.2 });

    tlReverse
      .to(contentRef.current, { opacity: 0, y: 10 }, 0)
      .to(topLineRef.current, { attr: { y1: bottomY, y2: bottomY - 1 }, opacity: 0 }, 0)
      .to(currentPathRef.current, { opacity: 0 }, 0)
      .to(lineRef.current, { strokeDasharray: pathLength, strokeDashoffset: pathLength }, 0);
  }, [pathLength]);// eslint-disable-line

  useEffect(() => {
    if (playIn) {
      tlReverse.restart().pause();
      tl.restart();
    }
    if (playOut) {
      tl.pause();
      tlReverse.restart();
    }
  }, [playIn, playOut]); // eslint-disable-line

  return pathLength;
};
