export const home = {
  start: {
    headline: "Über die ökonomische Leistung der BMW Group und ihren ökologischen und gesellschäftlichen Beitrag.",
    title: "DER BMW GROUP<br>BERICHT 2020",
    slideshow: [
      {
        theme: "BlueBold",
        headline: "Denn Glaubwürdigkeit entsteht durch Transparenz.",
        image: {
          src: "/data/StartSlideshow__Card_1.png",
        },
        link: {
          href: "#",
          label: "Wertschöpfungsprozess",
        },
      },
      {
        theme: "Blue",
        headline: "Die wichtigsten Kennzahlen unseres Geschaftsjahres 2020",
        image: {
          src: "/data/StartSlideshow__Card_2.png",
        },
        link: {
          href: "#",
          label: "Guided Tour '20",
        },
      },
      {
        theme: "GrayLightest",
        headline: "Der BMW Group Bericht 2020 im PDF",
        image: {
          src: "/data/StartSlideshow__Card_3.png",
        },
        link: {
          href: "#",
          label: "Download",
        },
      },
    ],
  },
  hero: {
    headline: "<small>DUIS AUTEM VEL EUM</small>KEIN PREMIUM OHNE VERANTWORTUNG",
    author: "OLIVER ZIPSE",
  },
  entry: {
    copytext:
      "Einleitung Oliver Zipse über die strategische Neuausrichtung der BMW Group. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd no sea takimata sanctus est. Cetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt. ",
    image: {
      src: "/data/zipse.jpg",
    },
    caption: {
      headline: "OLIVER ZIPSE",
      desc: "VORSITZENDER DES VORSTANDS DER BMW AG",
    },
  },
  values: {
    copytext:
      '<p>Lorem dolor sit amet, etetur sadipscing. Wertschöpfungs-prozess eirmod tempor.</p><p>Lorem dolor sit amet sanctus est sit amet, consetetur sadipscing elitr, sed diam <a href="#" data-line="external">WIE DIE BMW GROUP WERTE SCHAFFT</a> at vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd no sea takimata.</p>',
    image: {
      src: "/data/VCP-Teaser@4x.png",
    },
    caption:
      '<p>At vero eos et accusam et justo duo dolores et ea rebum invidunt.</p><p>Die interaktive Grafik des <a href="#">Wie die BMW Group Werte schafft</a></p><p>der BMW Group</p>',
    link: {
      href: "#",
      type: "external",
    },
  },
  quote1: {
    headline:
      "DUIS AUTEM VEL EUM IRIURE DOLOR IN HENDRERIT IN VULPUTATE <strong>STRATEGIE 2020</strong> HENDRERIT CONSEQUAT.",
    author: "OLIVER ZIPSE",
  },
  goals: {
    copytext:
      '<p>Über die erreichten strategischen Ziele 2020. Lorem dolor sit amet sanctus est sit amet, consetetur sadipscing elitr, sed diam nonumy. eirmod tempor. At vero eos et accusam et mehr <a href="#" data-line="pdf">TRANSPARENZ</a> und fürend in der  duo dolores et ea rebum. Stet clita kasd no sea takimata.</p><p>Lorem dolor sit amet, etetur sadipscing. Wertschöpfungs-prozess eirmod tempor.</p>',
    link: {
      label: "<strong>PDF-BERICHT</strong>Kapitel Strategie 2025",
      type: "pdf",
      modal: [
        {
          type: "Pdf",
          props: {
            theme: "Gray",
            chapter: "An unsere Stakeholder",
            headline: "Die BMW Group im Dialogmit Stakeholdern",
            pages: 24,
            src: "/data/BM-13887_IKB_2020_A4_210215_Exemplarische_Seiten_LB_Boxen.pdf",
          },
        },
      ],
    },
  },
  strategy: {
    copytext:
      '<p>Über die gesetzten strategischen Ziele. Lorem dolor sit amet sanctus est sit amet, consetetur sadipscing elitr, sed diam nonumy <a href="#" data-line="pdf">BMW GROUP STRATEGIE</a> tempor. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd no sea takimata.</p><p>Lorem dolor sit amet, etetur sadipscing eirmod tempor nonumy eirmod invidunt ut labore et dolore</p>',
    link: {
      label: "<strong>PDF-BERICHT</strong>Kapitel Strategie 2025",
      type: "pdf",
      modal: [
        {
          type: "Pdf",
          props: {
            theme: "Gray",
            chapter: "An unsere Stakeholder",
            headline: "Die BMW Group im Dialogmit Stakeholdern",
            pages: 24,
            src: "/data/BM-13887_IKB_2020_A4_210215_Exemplarische_Seiten_LB_Boxen.pdf",
          },
        },
      ],
    },
  },
  audio: {
    photo: { src: "/data/zipse-audio.jpg" },
    src: "/data/test.mp3",
    name: "OLIVER ZIPSE",
    copytext:
      "ÜBER DIE DIE GEPLANTEN LOREM IPSUM DOLORES SED ETIAM. AT VERO EOS ET ACCUSAM ET JUSTO DUO DOLORES ET EA REBUM. STET CLITA KASD NO SEA TAKIMATA.",
  },
  dummy: {
    copytext: "Lorem dolor sit amet, etetur sadipscing eirmod tempor nonumy eirmod invidunt ut labore et dolore.",
  },
  results: {
    head: {
      headline: "AT VERO EOS ET ACCUSAM ET JUSTO DUO DOLORES ET EA REBUM INVIDUNT.",
      link: {
        label: "<strong>WICHTIGSTE ZIELE</strong> <small>DER BMW GROUP</small>",
        href: "#",
      },
    },
    list: [
      {
        value: "-80",
        meter: "%",
        caption:
          "<p><em>LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPSCING ELITR.</em></p><p><strong>LOREM IPSUM</strong>Quist ute nem quo ex et quiam, odis ut et autem.</p><p><strong>AKKUSATIVUM</strong>Sit quist ute nem quo ex et quiam.</p>",
      },
      {
        value: "-40",
        meter: "%",
        caption:
          "<p><em>LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPSCING ELITR.</em></p><p><strong>LOREM IPSUM</strong>Quist ute nem quo ex et quiam, odis ut et autem.</p><p><strong>AKKUSATIVUM</strong>Sit quist ute nem quo ex et quiam.</p>",
      },
      {
        value: "7",
        meter: "Mio.",
        caption:
          "<p><em>LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPSCING ELITR.</em></p><p><strong>LOREM IPSUM</strong>Quist ute nem quo ex et quiam, odis ut et autem.</p><p><strong>AKKUSATIVUM</strong>Sit quist ute nem quo ex et quiam.</p>",
      },
    ],
  },
  dummy2: {
    copytext:
      "<p>Lorem dolor sit amet, etetur sadipscing eirmod tempor nonumy eirmod invidunt ut labore et dolore.</p><p><cite>Ihr Oliver Zipse<small>VORSITZENDER DES VORSTANDS DER BMW AG</small></cite></p>",
  },
  quote2: {
    headline:
      "DUIS AUTEM VEL EUM IRIURE DOLOR IN HENDRERIT IN VULPUTATE <strong>GESCHÄFTSJAHR 2020</strong> CONSEQUAT.",
    author: "DR. NICOLAS PETER",
  },
  about: {
    copytext:
      "<p>Dr. Nicolas Peter über die neue Form der Berichterstattung der BMW Group und die damit erreichte höchste Transparenz in den Wertschöpfungs-prozessen für bestmögliche Investitionsentscheidungen autem vel eum iriure dolor in hendrerit in.</p><p>Consetetur sadipscing elitr, sed dia nonumy eirmod tempor invi dunt ut labore et dolore magna aliquy am erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>",
    image: {
      src: "/data/peter.jpg",
    },
    caption: {
      headline: "DR. NICOLAS PETER",
      desc: "MITGLIED DES VORSTANDS DER BMW AG, FINANZEN",
    },
  },
  dummy3: {
    copytext:
      '<p>Lorem dolor sit amet, etetur sadipscing labore et dolo <a href="#" data-line="pdf">WIE DIE BMW GROUP WERTE SCHAFFT</a> dolor sit amet, etetur sadipscing eirmod tempor nonumy labore et dolore.</p><p><cite>Dr. Nicolas Peter<small>MITGLIED DES VORSTANDS DER BMW AG, FINANZEN</small></cite><p>',
    link: {
      label: "<strong>PDF-BERICHT</strong>Kapitel Strategie 2025",
      type: "pdf",
      modal: [
        {
          type: "Pdf",
          props: {
            theme: "Gray",
            chapter: "An unsere Stakeholder",
            headline: "Die BMW Group im Dialogmit Stakeholdern",
            pages: 24,
            src: "/data/BM-13887_IKB_2020_A4_210215_Exemplarische_Seiten_LB_Boxen.pdf",
          },
        },
      ],
    },
  },
  guidedtour: {
    headline: "Guided Tour",
    subheadline: "Die Kennzahlen<br>unseres Geschäftsjahres<br>2020",
  },
  gesagtgetan: {
    head: {
      headline: "AT VERO EOS ET ACCUSAM ET JUSTO DUO DOLORES ET EA REBUM INVIDUNT.",
      link: {
        label: "<strong>ZIELE-GRAFIK</strong> <small>AUS DEM PDF-BERICHT</small>",
        href: "#",
      },
    },
  },
};
