import { Spacer } from "#ui";
import { gsap } from "gsap";
import { Power3 } from "gsap/gsap-core";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { About } from "./About";
import { Audio } from "./Audio";
import { Dummy } from "./Dummy";
import { Dummy2 } from "./Dummy2";
import { Dummy3 } from "./Dummy3";
import { GesagtGetan } from "./GesagtGetan";
import { Goals } from "./Goals";
import { GuidedTour } from "./GuidedTour";
import { Hero } from "./Hero";
import { Entry } from "./Entry";
import { Quote1 } from "./Quote1";
import { Quote2 } from "./Quote2";
import { Results } from "./Results";
import { Strategy } from "./Strategy";
import { Values } from "./Values";

export const Intro: React.FC = () => {
  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (!element) return;
      gsap.to(window, { duration: 1, scrollTo: { y: hash, offsetY: 160 }, ease: Power3.easeInOut });
    }
  }, [hash]);
  return (
    <>
      <Hero />
      <Spacer />
      <Entry />
      <Spacer />
      <Values />
      <Spacer size={150} />
      <Quote1 />
      <Spacer size={150} />
      <Goals />
      <Spacer size={200} />
      <GesagtGetan />
      <Spacer size={200} />
      <Strategy />
      <Spacer size={200} />
      <Audio />
      <Spacer size={200} />
      <Dummy />
      <Spacer />
      <Results />
      <Spacer size={250} />
      <Dummy2 />
      <Spacer size={250} />
      <Quote2 id="cfo" />
      <Spacer size={250} />
      <About />
      <Spacer size={150} />
      <Dummy3 />
      <GuidedTour />
    </>
  );
};
