import { ControlledLine } from "#components";
import { useTranslation } from "#hooks";
import { Figure, LinkIcon, TextAside } from "#ui";
import React from "react";

export const Values: React.FC = () => {
  const { t: tHome } = useTranslation("home");
  const { copytext, image, caption, link } = tHome("values");
  return (
    <ControlledLine source={copytext} modal={link.modal}>
      {({ targetRef, render }) => (
        <TextAside content={render} withLine>
          <Figure image={image} caption={caption}>
            {link && <LinkIcon targetRef={targetRef} {...link} />}
          </Figure>
        </TextAside>
      )}
    </ControlledLine>
  );
};
