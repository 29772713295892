import { useLayoutEffect, useCallback } from "react";

interface EventCallback {
  (e?: Event): void;
}
interface useEventTarget {
  (...args: [EventTarget, string, EventCallback]): [EventTarget, () => void];
}

export const useEvent: useEventTarget = (target, ...args) => {
  const off = useCallback(() => target.removeEventListener(...args), [target, args]);
  useLayoutEffect(() => {
    target.addEventListener(...args);
    return off;
  }, [target, args, off]);
  return [target, off];
};
