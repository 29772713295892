import { chartMainItemDuration } from "#constants";
import { useChartAnimation, useNumberFormat } from "#hooks";
import { ChartProps } from "#interfaces";
import { Color } from "#shared/theme";
import { SvgText, SvgTextSmall } from "#ui";
import React, { useRef } from "react";

export const RightKPI: React.FC<ChartProps> = ({ labels, ...props }) => {
  const maskID = `f${(~~(Math.random() * 1e8)).toString(16)}`;
  const currentPathRef = useRef<SVGPathElement>(null);
  const prevPathRef = useRef<SVGPathElement>(null);
  const negativePathRef = useRef<SVGPathElement>(null);
  const contentRef = useRef<SVGGElement>(null);
  const lineRef = useRef<SVGPathElement>(null);
  useChartAnimation({
    ...props,
    maxValue: Number(labels?.maxValue),
    currentValue: Number(labels?.prevValue),
    prevValue: Number(labels?.currentValue),
    minDegree: 250,
    maxDegree: 35,
    currentPathRef,
    prevPathRef,
    contentRef,
    lineRef,
    negativePathRef,
    duration: chartMainItemDuration,
    delay: 0.5,
    left: true,
  });
  return (
    <g fill="none">
      <mask id={maskID} height="1" maskUnits="userSpaceOnUse" width="165" x="447.987" y="271.451">
        <circle cx="448" cy="272" r="155" />
        <path
          d="m447.987 127c80.081 0 145 64.919 145 145s-64.919 145-145 145-145-64.919-145-145 64.918-145 145-145m0-20c-22.266 0-43.877 4.365-64.232 12.975-19.65 8.311-37.294 20.206-52.441 35.353s-27.041 32.791-35.353 52.441c-8.609 20.355-12.975 41.965-12.975 64.232s4.365 43.877 12.975 64.232c8.311 19.651 20.206 37.294 35.353 52.441s32.791 27.041 52.441 35.353c20.355 8.608 41.966 12.973 64.232 12.973s43.877-4.365 64.232-12.975c19.651-8.311 37.294-20.206 52.441-35.353s27.041-32.791 35.353-52.441c8.609-20.355 12.975-41.966 12.975-64.232s-4.365-43.877-12.975-64.232c-8.311-19.65-20.206-37.294-35.353-52.441s-32.791-27.041-52.441-35.353c-20.356-8.608-41.966-12.973-64.232-12.973z"
          fill="#fff"
        />
      </mask>
      <path
        ref={currentPathRef}
        opacity=".3"
        d="M578.4,364.7 C597,338.5,608,306.5,608,272c0-88.4-71.6-160-160-160s-160,71.6-160,160c0,34.5,11,66.5,29.6,92.7"
        stroke={Color.BlackLight}
        strokeWidth="9.9"
      />
      <path
        d="M578.4,364.7 C597,338.5,608,306.5,608,272c0-88.4-71.6-160-160-160s-160,71.6-160,160c0,34.5,11,66.5,29.6,92.7"
        stroke={Color.White}
        ref={negativePathRef}
        strokeWidth="10"
      />
      <path
        d="M578.4,364.7 C597,338.5,608,306.5,608,272c0-88.4-71.6-160-160-160s-160,71.6-160,160c0,34.5,11,66.5,29.6,92.7"
        stroke={Color.BlackLight}
        ref={prevPathRef}
        strokeWidth="10"
      />
      <path ref={lineRef} d="m447.987 272h165" mask={`url(#${maskID})`} stroke={Color.White} />
      <SvgText ref={contentRef}>
        <SvgTextSmall>
          <text opacity=".5" fontSize="14" transform="matrix(1 0 0 1 448.0002 522.0773)">
            {labels?.diff}
          </text>
          <text fill={Color.BlackLight} fontSize="14" transform="matrix(1 0 0 1 448.0002 381.2773)">
            <tspan x="0" y="0">
              {labels?.label1}
            </tspan>
            <tspan x="0" y="16.8">
              {labels?.label2}
            </tspan>
            <tspan x="0" y="33.6">
              {labels?.label3}
            </tspan>
          </text>
        </SvgTextSmall>
        <text fontSize="60" transform="matrix(1 0 0 1 447.9997 483.0128)">
          {useNumberFormat(Number(labels?.currentValue))}
        </text>
      </SvgText>
    </g>
  );
};
