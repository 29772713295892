import { PageName } from "src/interfaces";

export const pages = {
  list: {
    [PageName.index]: {
      path: "/",
      title: "BMW Group Report 2020",
    },
    [PageName.home]: {
      path: "/home/",
    },
    [PageName.start]: {
      path: "/home/start/",
      title: "Home",
      description: "",
      image: "",
    },
    [PageName.intro]: {
      path: "/home/intro/",
      title: "Intro",
      description: "",
      image: "",
    },
    [PageName.intro_ceo]: {
      path: "/home/intro#ceo",
      title: "Intro CEO",
      description: "",
      image: "",
    },
    [PageName.intro_cfo]: {
      path: "/home/intro#cfo",
      title: "Prolog CFO",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020]: {
      path: "/home/guided-tour-2020/",
      title: "Guided Tour 2020",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_automobile_deliveries]: {
      path: "/home/guided-tour-2020/#automobile-deliveries",
      title: "Automobile deliveries",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_ebit_margin_automotive_segment]: {
      path: "/home/guided-tour-2020/#ebit-margin-automotive-segment",
      title: "EBIT margin automotive segment",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_motorcycles_deliveries]: {
      path: "/home/guided-tour-2020/#motorcycles-deliveries",
      title: "Motorcycles deliveries",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_new_contracts_retail_customer_financing_financial_services]: {
      path: "/home/guided-tour-2020/#new-contracts-retail-customer-financing-financial-services",
      title: "New contracts retail customer financing, financial services",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_group_key_figures]: {
      path: "/home/guided-tour-2020/#group-key-figures",
      title: "Group key figures",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_earnings_per_share]: {
      path: "/home/guided-tour-2020/#earnings-per-share",
      title: "Earnings per share",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_research_and_development_expenditure]: {
      path: "/home/guided-tour-2020/#research-and-development-expenditure",
      title: "Research and development expenditure",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_key_automobile_markets]: {
      path: "/home/guided-tour-2020/#key-automobile-markets",
      title: "Key automobile markets",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_deliveries_of_electrified_vehicles]: {
      path: "/home/guided-tour-2020/#deliveries-of-electrified-vehicles",
      title: "Deliveries of electrified vehicles",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_co2_emissions_per_vehicle_in_production_and_eu_fleet_value]: {
      path: "/home/guided-tour-2020/#co2-emissions-per-vehicle-in-production-and-eu-fleet-value",
      title: "CO2 emissions per vehicle in production and EU fleet value",
      description: "",
      image: "",
    },
    [PageName.guidedTour2020_share_of_women_in_management_positions_at_bmw_ag]: {
      path: "/home/guided-tour-2020/#share-of-women-in-management-positions-at-bmw-ag",
      title: "Share of women in management positions at BMW AG",
      description: "",
      image: "",
    },
    [PageName.interactiveValueCreationProcess]: {
      path: "/interactive-value-creation-process",
      title: "Interactive Value Creation Process",
      description: "",
      image: "",
    },
    [PageName.interviewDiversity]: {
      path: "/interview/diversity",
      title: "Interview 1 / Diversity",
      description: "",
      image: "",
    },
    [PageName.interviewFleetEmissions]: {
      path: "/interview/fleet-emissions",
      title: "Interview 2 / Fleet Emissions",
      description: "",
      image: "",
    },
    [PageName.interviewCleanProduction]: {
      path: "/interview/clean-production",
      title: "Interview 3 / Clean Production",
      description: "",
      image: "",
    },
    [PageName.ReportOfTheSupervisoryBoard]: {
      path: "/report-of-the-supervisory-board",
      title: "Report of the Supervisory Board",
      description: "",
      image: "",
    },
    [PageName.statementOfTheChairmanOfTheBoardOfManagement]: {
      path: "/statement-of-the-chairman-of-the-board-of-management",
      title: "Statement of the Chairman of the Board of Management",
      description: "",
      image: "",
    },
    [PageName.financialStatements]: {
      path: "/financial-statements",
      title: "Financial Statements",
      description: "",
      image: "",
    },
    [PageName.downloads]: {
      path: "/downloads",
      title: "Downloads",
      description: "",
      image: "",
    },
    [PageName.glossary]: {
      path: "/glossary",
      title: "Glossary",
      description: "",
      image: "",
    },
    [PageName.legalDisclaimer]: {
      path: "/legal-disclaimer",
      title: "Legal Disclaimer",
      description: "",
      image: "",
    },
    [PageName.cookies]: {
      path: "/cookies",
      title: "Cookies",
      description: "",
      image: "",
    },
    [PageName.imprint]: {
      path: "/imprint",
      title: "Imprint",
      description: "",
      image: "",
    },
    [PageName.contact]: {
      path: "/contact",
      title: "Contact",
      description: "",
      image: "",
    },
    [PageName.dataProtection]: {
      path: "/data-protection",
      title: "Data Protection",
      description: "",
      image: "",
    },
  },
};
