import React from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components/macro";
import { Color, font } from "#shared/theme";
import { svg } from "#mocks";

interface StyledTextProps {
  withLines: boolean;
}

const StyledText = styled.div<StyledTextProps>`
  h1 {
    font-family: ${font.condensed};
    font-size: 30px;
    line-height: 1.13;
    text-transform: uppercase;
    margin-bottom: 56px;
    font-weight: bold;
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 8px;
  }
  h3 {
    text-transform: uppercase;
    ${({ withLines }): FlattenSimpleInterpolation | false =>
      withLines &&
      css`
        position: relative;
        padding-top: 6px;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 2px;
          width: 32px;
          background-color: ${Color.Black};
        }
      `}
  }
  h3,
  h4 {
    font-family: ${font.condensed};
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    margin-bottom: 2px;
    & + h3,
    & + h4 {
      margin-top: 44px;
    }
    & + a[download] {
      margin-top: 28px;
    }
  }
  p {
    font-size: 18px;
    line-height: 1.33;
    & + p {
      margin-top: 10px;
    }
    & + h3,
    & + h4 {
      margin-top: 44px;
    }
  }
  a[download] {
    font-size: 18px;
    line-height: 1.33;
    display: flex;
    align-items: center;
    color: ${Color.GrayDark};
    &:before {
      content: "";
      margin-right: 16px;
      margin-left: 6px;
      background-image: url(${svg.arrowDownload});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 20px;
      width: 20px;
    }
    &[data-excel] {
      &:before {
        background-image: url(${svg.graphs});
      }
    }
    & + h3,
    & + h4 {
      margin-top: 44px;
    }
    & + a {
      margin-top: 28px;
    }
  }
`;

export interface TextProps {
  text: string;
  withLines?: boolean;
}

export const Text: React.FC<TextProps> = ({ text, withLines = false }) => {
  return <StyledText dangerouslySetInnerHTML={{ __html: text }} withLines={withLines} />;
};
