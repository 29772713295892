import React, { useContext, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import useMeasure from "react-use-measure";
import { bp } from "#shared/theme";
import styled from "styled-components/macro";
import { ScrollbarsContext } from "../Scrollbars/Scrollbars";

const Container = styled.div`
  overflow: hidden;
`;
const PdfPageContainer = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  & + & {
    margin-top: 12px;
    @media ${bp.p} {
      margin-top: 20px;
    }
    @media ${bp.d} {
      margin-top: 25px;
    }
  }
`;

export interface PdfPageProps {
  scrollableContainer?: HTMLDivElement | null;
  width: number;
  height: number;
  pageNumber: number;
}

export const PdfPage: React.FC<PdfPageProps> = ({ scrollableContainer, width, pageNumber, height }) => {
  const [pageRef, inView] = useInView({
    root: scrollableContainer,
  });
  return (
    <PdfPageContainer ref={pageRef} height={height}>
      {inView && <Page pageNumber={pageNumber} width={width} />}
    </PdfPageContainer>
  );
};
export interface PdfProps {
  src: string;
}

export const Pdf: React.FC<PdfProps> = ({ src }) => {
  const { scrollbarRef } = useContext(ScrollbarsContext);
  const [numPages, setNumPages] = useState<number>();
  const [ratio, setRatio] = useState<number>(1);
  const [containerRef, { width }] = useMeasure({ debounce: 300 });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDocumentLoadSuccess = async (pdf: any): Promise<void> => {
    setNumPages(pdf.numPages);
    const page = await pdf.getPage(1);
    const viewport = await page.getViewport();
    setRatio(viewport.viewBox[2] / viewport.viewBox[3]);
  };

  return (
    <Container ref={containerRef}>
      <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (_, index) => (
          <PdfPage
            key={`page_${index + 1}`}
            width={width}
            height={width / ratio}
            pageNumber={index + 1}
            scrollableContainer={scrollbarRef?.current?.osTarget()}
          />
        ))}
      </Document>
    </Container>
  );
};
