import React from "react";
import styled from "styled-components/macro";
import { gridColumnWidth, gridColumnStart, bp } from "#shared/theme";
import { MainContent } from "../MainContent/MainContent";
import { Wrapper } from "../Wrapper/Wrapper";
import { Grid } from "../Grid/Grid";

const Content = styled.div`
  ${gridColumnStart(1)}
  ${gridColumnWidth(23)}
  @media ${bp.m} {
    ${gridColumnStart(5)}
    ${gridColumnWidth(16)}
  }
`;

export const GeneralPageLayout: React.FC = ({ children }) => {
  return (
    <MainContent>
      <Wrapper>
        <Grid>
          <Content>{children}</Content>
        </Grid>
      </Wrapper>
    </MainContent>
  );
};
