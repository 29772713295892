import { bp, Color, defTransition, font } from "#shared/theme";
import React from "react";
import { Scrollbars } from "#ui";
import styled, { css } from "styled-components/macro";

const Container = styled.div``;
const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  display: none;
  @media ${bp.t} {
    display: block;
  }
`;

interface TableStickyProp {
  isSticky?: boolean;
}

const TableTag = styled.table<TableStickyProp>`
  table-layout: fixed;
  border-collapse: separate;
  min-width: 100%;
  font-family: ${font.condensed};
  line-height: 1.25;
  border-spacing: 8px 0;
  overflow-wrap: normal;
  margin-bottom: 15px;
  color: ${Color.Black};
  background-color: ${Color.GrayLightest};
  font-size: 15px;
  @media ${bp.l} {
    font-size: 17px;
  }
  ${({ isSticky = false }) =>
    isSticky &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    `}
`;
const Caption = styled.div`
  font-family: ${font.serif};
  font-weight: bold;
  font-size: 13px;
  text-align: left;
  padding: 15px 8px;
  @media ${bp.l} {
    font-size: 14px;
  }
`;
interface TdProps {
  type?: "td" | "th";
  accented?: boolean;
  bold?: boolean;
}
const Td = styled.td<TdProps>`
  font-weight: ${({ bold = false }) => (bold ? "bold" : "normal")};
  white-space: ${({ type = "td" }): string => (type === "th" ? "normal" : "nowrap")};
  position: relative;
  padding: 30px 8px 15px;
  height: 100%;
  min-width: 60px;
  width: 80px;
  text-align: right;
  vertical-align: bottom;
  background-color: ${Color.GrayLightest};
  border-bottom: 1px solid ${Color.Black};
  transition: background-color ${defTransition};
  ${({ accented = false }) =>
    accented &&
    css`
      color: ${Color.BlueAccent};
      border-bottom-color: ${Color.BlueAccent};
    `};
  &:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    min-width: 200px;
    width: 320px;
    text-align: left;
  }
`;
const Inner = styled.div``;
const Tr = styled.tr`
  &:hover ${Td} {
    background-color: ${Color.White};
  }
`;
const THead = styled.thead`
  font-family: ${font.serif};
  line-height: 1.34;
  font-weight: bold;
  font-size: 13px;
  @media ${bp.l} {
    font-size: 14px;
  }
  ${Td} {
    border-bottom: 3px solid ${Color.Black};
    white-space: normal;
    padding-top: 15px;
    font-weight: bold;
  }
  ${Tr}:first-child ${Td} {
    border-top: 3px solid ${Color.Black};
  }
`;
const TBody = styled.tbody<TableStickyProp>`
  ${Tr}:last-child ${Td} {
    border-bottom: 3px solid ${Color.Black};
  }
  ${({ isSticky }) =>
    isSticky &&
    css`
      ${Td} {
        padding-top: 0;
        padding-bottom: 0;
        border: 0;
      }
      ${Tr}:last-child ${Td} {
        border: 0;
      }
      ${Inner} {
        height: 0;
        overflow: hidden;
      }
    `}
`;

interface TableCellProps {
  props?: TdProps;
  children: string;
}

export interface TableProps {
  head: TableCellProps[][];
  data: TableCellProps[][];
  caption?: string;
}

const TableComponent: React.FC<TableProps & TableStickyProp> = ({ isSticky, data, head }) => {
  const renderRow = (tr: TableCellProps[], y: number): JSX.Element => {
    const renderCell = ({ children, props }: TableCellProps, x: number): JSX.Element => {
      return (
        <Td key={`${y}.${x}`} as={props?.type || "td"} {...props}>
          <Inner>{children}</Inner>
        </Td>
      );
    };
    return <Tr key={y}>{tr.map(renderCell)}</Tr>;
  };
  return (
    <TableTag isSticky={isSticky}>
      <THead>{head.map(renderRow)}</THead>
      <TBody isSticky={isSticky}>{data.map(renderRow)}</TBody>
    </TableTag>
  );
};
export const Table: React.FC<TableProps> = ({ caption, ...restProps }) => {
  return (
    <Container>
      {caption && <Caption>{caption}</Caption>}
      <StickyContainer>
        <TableComponent isSticky {...restProps} />
      </StickyContainer>
      <Scrollbars withMask>
        <TableComponent {...restProps} />
      </Scrollbars>
    </Container>
  );
};
