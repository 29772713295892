enum OS {
  Mac = "Mac OS",
  Ios = "iOS",
  Windows = "Windows",
  Android = "Android",
  Linux = "Linux",
  Unknown = "Unknown",
}

const getOS = (): OS => {
  let os = OS.Unknown;
  if (typeof window === "undefined") return os;
  const { userAgent, platform } = window?.navigator;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OS.Mac;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OS.Ios;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OS.Windows;
  } else if (/Android/.test(userAgent)) {
    os = OS.Android;
  } else if (/Linux/.test(platform)) {
    os = OS.Linux;
  }

  return os;
};

export const isWindows = getOS() === OS.Windows;
export const isLinux = getOS() === OS.Linux;
export const isIos = getOS() === OS.Ios;

export const isClient = (): boolean => {
  return typeof window !== "undefined" && typeof document !== "undefined";
};

export const isReactSnap = navigator.userAgent === "ReactSnap";
