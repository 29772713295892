import FontFaceObserver from "fontfaceobserver";
import { useEffect, useState } from "react";
import { FontFamily } from "#shared/theme";

const fonts: { family: FontFamily; weight: number }[] = [
  {
    family: FontFamily.BMWGroupSerif,
    weight: 400,
  },
  {
    family: FontFamily.BMWGroupCondensed,
    weight: 400,
  },
  {
    family: FontFamily.BMWGroupCondensed,
    weight: 700,
  },
  {
    family: FontFamily.BMWGroup,
    weight: 400,
  },
];

const observers: Promise<void>[] = [];

fonts.forEach((font) => {
  const obs = new FontFaceObserver(font.family, { weight: font.weight });
  observers.push(obs.load(null, 5000));
});

export const useFontLoader = (): { isLoaded: boolean } => {
  const [isLoaded, setLoaded] = useState(false);
  useEffect(() => {
    let isSubscribed = true;
    if (isLoaded) return;
    Promise.all(observers)
      .then(() => {
        if (!isSubscribed) return;
        setLoaded(true);
      })
      .catch(function (err) {
        console.warn("Some critical font are not available:", err);
      });
    return (): void => {
      isSubscribed = false;
    };
  }, [isLoaded]);
  return {
    isLoaded,
  };
};
