import React, { createContext, useContext, useReducer } from "react";

interface RoutingContextProps {
  keys: number[];
  set: (n: string) => void;
}
interface RoutingState {
  keys: number[];
}
type RoutingAction = { payload: { level: number } };

type RoutingReducerType = (state: RoutingState, action: RoutingAction) => RoutingState;

const RoutingReducer: RoutingReducerType = (state, { payload }) => {
  const newState = { ...state };
  newState.keys[payload.level - 1] = Math.random();
  return { ...newState };
};

const DEFAULT_VALUE = {
  keys: [],
  set: (): void => undefined,
};

const RoutingContext = createContext<RoutingContextProps>(DEFAULT_VALUE);
export const useRouting = (): RoutingContextProps => useContext<RoutingContextProps>(RoutingContext);

export const RoutingProvider: React.FC = ({ children }) => {
  const paths = location.pathname.split("/");
  const [{ keys }, dispatch] = useReducer(RoutingReducer, {
    keys: paths.map(() => Math.random()),
  });
  const set = (path: string): void => {
    const [pathname] = path.split("#");
    dispatch({ payload: { level: pathname.split("/").length - 1 } });
  };
  const value = {
    keys,
    set,
  };
  return <RoutingContext.Provider value={value}>{children}</RoutingContext.Provider>;
};
