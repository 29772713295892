import React, { useEffect, useRef, useState } from "react";
import { Color } from "#shared/theme";
import styled from "styled-components/macro";

export enum ButtonCircleSize {
  Default = "Default",
  Middle = "Middle",
  Large = "Large",
  ExtraLarge = "ExtraLarge",
}

export const buttonCircleSizeByType: { [i: string]: number } = {
  [ButtonCircleSize.Default]: 34,
  [ButtonCircleSize.Middle]: 44,
  [ButtonCircleSize.Large]: 58,
  [ButtonCircleSize.ExtraLarge]: 90,
};

interface BtnProps {
  size: ButtonCircleSize;
}

const Btn = styled.div<BtnProps>`
  border-radius: 50%;
  width: ${({ size }) => buttonCircleSizeByType[size]}px;
  height: ${({ size }) => buttonCircleSizeByType[size]}px;
  background-color: ${Color.Green};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  position: relative;
  cursor: pointer;
  color: ${Color.Black};
`;

interface BorderProps extends BtnProps {
  isAnimated: boolean;
}

const Border = styled.svg<BorderProps>`
  position: absolute;
  display: block;
  height: ${({ size }) => (size === ButtonCircleSize.ExtraLarge ? "calc(100% + 34px)" : "calc(100% + 16px)")};
  width: ${({ size }) => (size === ButtonCircleSize.ExtraLarge ? "calc(100% + 34px)" : "calc(100% + 16px)")};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export interface ButtonCircleProps extends Partial<BtnProps>, Partial<BorderProps> {
  onClick?: () => void;
  progress?: number;
}

export const ButtonCircle: React.FC<ButtonCircleProps> = ({
  size = ButtonCircleSize.Default,
  isAnimated = false,
  children,
  progress,
  ...props
}) => {
  const borderRef = useRef<SVGCircleElement>(null);
  const [circleSizes, setCircleSizes] = useState({ length: 0, currentLength: 0 });
  useEffect(() => {
    if (progress !== undefined && borderRef.current) {
      const length = borderRef.current?.getTotalLength();
      const currentLength = length - (length * progress) / 100;
      setCircleSizes({ length, currentLength });
    }
  }, [borderRef, progress]);
  return (
    <Btn size={size} {...props}>
      {children}
      <Border isAnimated={isAnimated} size={size} viewBox="0 0 50 50">
        <circle
          strokeDasharray={circleSizes.length}
          strokeDashoffset={circleSizes.currentLength}
          ref={borderRef}
          cx="25"
          cy="25"
          r="23"
          strokeWidth="4"
          fill="none"
          stroke="#000"
          vectorEffect="non-scaling-stroke"
        />
      </Border>
    </Btn>
  );
};
