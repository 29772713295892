import { useTranslation } from "#hooks";
import { TextAside } from "#ui";
import React from "react";
import htmr from "htmr";

export const Dummy: React.FC = () => {
  const { t: tHome } = useTranslation("home");
  const { copytext } = tHome("dummy");
  return <TextAside content={htmr(copytext)} />;
};
